import { dateIsToday } from "../../../utils/date-helpers/dateIsToday";
import { generateHoursInterval } from "../../../utils/date-helpers/generateHoursInterval";

export const useTimeContainer = (date) => {
  const startTime = dateIsToday(date)
    ? 60 * new Date().getHours() + 60
    : 60 * 6;
  const endTime = 60 * 24;
  const interval = 15;
  const timeOptions = generateHoursInterval(startTime, endTime, interval);

  const addTimeToDate = (time) => {
    time = time.split(":");
    const updatedDate = new Date(date).setHours(
      parseInt(time[0]),
      parseInt(time[1]),
      0
    );
    return new Date(updatedDate);
  };

  return { addTimeToDate, timeOptions };
};

import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";

export default function Loader({ loading }) {
  return (
    <Backdrop
      transitionDuration={{ exit: 1000 }}
      sx={{
        color: "#F4AF1B",
        background: "#141A46",
        zIndex: (theme) => theme.zIndex.drawer + 100000000,
      }}
      open={loading}
    >
      <CircularProgress
        style={{ fontSize: 100, width: 100, height: 100 }}
        color="inherit"
      />
    </Backdrop>
  );
}

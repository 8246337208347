import React, { useState } from "react";
import {
  Paper,
  Divider,
  Tooltip,
  styled,
  InputBase,
  Avatar,
  Typography,
  Box,
  Badge,
} from "@material-ui/core";
import {
  hubAddThreadMessage,
  hubLikeFeedThread,
} from "../../../components/common/ConnectionMiddleware";
import { Send, ThumbUpRounded, Close } from "@material-ui/icons";
import { getUserById } from "../../../store/hub/users";
import { makeStyles } from "@material-ui/core/styles";
import {
  convertDateTime,
  parseJsonMessageForDisplay,
} from "../../../components/common/Common";
import ThumbUpAltOutlinedIcon from "@material-ui/icons/ThumbUpAltOutlined";
import ChatBubbleOutlineOutlinedIcon from "@material-ui/icons/ChatBubbleOutlineOutlined";
import { useSelector } from "react-redux";
import {
  getUserNameFromId,
  loggedInUserId,
  addSnackbarIssue,
} from "../../../components/common/StoreCommon";
import {
  setSelectedFeedThreadIdInStore,
} from "../../../components/common/StoreFeedUi";
import { getFeedThreadMessages } from "../../../store/hub/boomFeed";
import { CircularProgress, IconButton } from "@mui/material";
import Attachments from "../../text-editor/components/Attachments";
import ThreadComment from "./ThreadComment";
import EmojiPicker from "emoji-picker-react";
import BuzzHubsIcon from "../../../assets/images/logo_small.png";
import { apiGetFeedThreadMessages } from "../../../components/common/ApiMiddleware";
import GroupUserImages from "../../user/components/GroupUserImages";
import UiService from "../../../services/UiService";

export default function FeedThread({ thread, expanded }) {
  const classes = useStyles();
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const getUserByIdSelector = useSelector(getUserById);
  const feedThreadMessagesSelector = useSelector(getFeedThreadMessages);

  let comments = [];
  if (expanded) {
    comments = feedThreadMessagesSelector();
  }

  const [emojiIsVisible, setEmojiIsVisible] = React.useState(false);
  const onEmojiClick = (event, emojiObject) => {
    setComment(comment + " " + emojiObject.emoji);
    setEmojiIsVisible(false);
  };

  return (
    <Paper
      className={classes.threadContainer}
      key={"FeedThreadContainer-" + thread.Id}
    >
      {getMessageStyle(classes, thread, getUserByIdSelector)}
      {footerButtons(classes, thread)}
      {ThreadMetrics(classes, thread.LikedUserIds, thread.Id)}
      {commentSection(
        classes,
        thread.LatestMessage,
        comment,
        setComment,
        comments,
        thread.PostMessage.Id,
        thread.Id,
        setEmojiIsVisible,
        emojiIsVisible,
        loading,
        setLoading
      )}

      {emojiIsVisible && (
        <EmojiPicker
          pickerStyle={{
            position: "absolute",
            bottom: "8%",
            left: 2,
            display: emojiIsVisible ? "block" : "none",
            zIndex: 2,
            overflow: "scroll",
          }}
          groupVisibility={{
            flags: false,
          }}
          onEmojiClick={onEmojiClick}
        />
      )}
    </Paper>
  );
}

const getMessageStyle = (classes, thread, getUserById) => {
  let style;
  let user = getUserById(thread.CreatedUserId);
  if (!user) user = { FirstName: "BuzzHubs", ImageSrc: BuzzHubsIcon };
  const attachments = thread.PostMessage.Attachments;

  switch (thread.PostMessage.TypeCode) {
    case "Recognition":
      let targetUser = getUserById(thread.TargetUserId);
      style = (
        <>
          <Box
            className={classes.feedHeader}
            key={"FeedThreadBox-" + thread.Id}
          >
            <Avatar src={user.ImageSrc} />
            <Box className={classes.feedHeaderText}>
              <Typography
                variant="body2"
                style={{ fontWeight: "bold", color: "rgb(100, 100, 100)" }}
              >
                {user ? user.FullName : "BuzzHubs"}{" "}
                {targetUser ? "> " + targetUser.FullName : null}
              </Typography>
              <Typography
                variant="caption"
                style={{ color: "rgb(100, 100, 100)" }}
              >
                {convertDateTime(thread.PostMessage.UtcCreatedDateTime)}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.feedTextContainer}>
            <Box className={classes.feedText}>
              {parseJsonMessageForDisplay(
                thread.PostMessage.JsonContent,
                null,
                true
              )}
            </Box>
          </Box>
          {
            <Box
              className={classes.userImageContainer}
              style={{
                background: targetUser.ColorHex,
              }}
            >
              <Avatar
                src={targetUser.ImageSrc}
                style={{
                  width: 100,
                  height: 100,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
              <Typography variant="h5" style={{ color: "white" }}>
                💥Well done {targetUser.FirstName}💥
              </Typography>
            </Box>
          }
        </>
      );
      break;
    default:
      style = (
        <>
          <Box
            className={classes.feedHeader}
            key={"FeedThreadBox-" + thread.Id}
          >
            <Avatar src={user.ImageSrc} />
            <Box className={classes.feedHeaderText}>
              <Typography
                variant="body2"
                style={{ fontWeight: "bold", color: "rgb(100, 100, 100)" }}
              >
                {user.FullName ? user.FullName : "BuzzHubs"}
              </Typography>
              <Typography
                variant="caption"
                style={{ color: "rgb(100, 100, 100)" }}
              >
                {convertDateTime(thread.PostMessage.UtcCreatedDateTime)}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.feedTextContainer}>
            <Box
              className={classes.feedText}
              style={{
                textAlign: thread.CreatedUserId === null ? "center" : "left",
              }}
            >
              {parseJsonMessageForDisplay(
                thread.PostMessage.JsonContent,
                null,
                true
              )}
            </Box>
          </Box>
          {attachments && attachments.length >= 1 ? (
            <Box style={{ margin: "0px 10px" }}>
              <Attachments
                files={attachments}
                dividerPlacement={"top"}
                align="center"
              />
            </Box>
          ) : null}
        </>
      );
      break;
  }
  return style;
};

function ThreadMetrics(classes, likedUserIds, threadId) {
  const likedUserSelector = useSelector(getUserById);
  let likedUser = likedUserSelector(likedUserIds[likedUserIds.length - 1]);

  if (likedUserIds.length >= 1) {
    likedUser = likedUserIds.includes(loggedInUserId())
      ? { FullName: "You" }
      : likedUser
      ? likedUser
      : { FullName: "BuzzHubs" };

    return (
      <Box className={classes.metricsBox} key={"FeedThreadMetrics-" + threadId}>
        <Box className={classes.metricsContainer}>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {likedUserIds.length >= 1 ? (
              <>
                <GroupUserImages userIds={likedUserIds} />
                <Typography variant="caption" style={{ marginRight: 5 }}>
                  Liked by
                </Typography>
                <Typography variant="caption" style={{ fontWeight: "bold" }}>
                  {likedUser["FullName"]}
                </Typography>
                {likedUserIds.length > 1 && (
                  <>
                    <Typography variant="caption" style={{ margin: "0px 5px" }}>
                      and
                    </Typography>
                    <Tooltip title={getLikeHtml(likedUserIds, threadId)}>
                      <Typography
                        variant="caption"
                        style={{ fontWeight: "bold" }}
                      >
                        {`${likedUserIds.length - 1} other${
                          likedUserIds.length > 2 ? "s" : ""
                        }`}
                      </Typography>
                    </Tooltip>
                  </>
                )}
              </>
            ) : null}
          </Box>
        </Box>
      </Box>
    );
  }
}

const getLikeHtml = (likedUserIds, threadId) => {
  let i = 0;
  return (
    <Box
      style={{ display: "flex", flexDirection: "column" }}
      key={"FeedThreadLikeContainer-" + threadId}
    >
      {likedUserIds.map((id) => {
        let userName = getUserNameFromId(id);
        i++;
        if (i <= 10)
          return (
            <Typography variant="caption" key={"FeedThreadLike-" + id}>
              {userName}
            </Typography>
          );
        else if (i === 10)
          return (
            <Typography variant="caption" key={"FeedThreadLike-" + id}>
              + {likedUserIds.length - 10} more
            </Typography>
          );
        else return null;
      })}
    </Box>
  );
};

const footerButtons = (classes, thread) => {
  const loggedInUserLiked = thread.LikedUserIds.includes(loggedInUserId());
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      background: "#306ead",
      color: "white",
      fontSize: 10,
    },
  }));

  const likeThread = (threadId) => {
    hubLikeFeedThread(threadId).catch(() => {
      addSnackbarIssue(
        "There was an error when liking this message, please try again!",
        "warning"
      );
    });
  };

  const closePost = (threadId) => {
    const uiService = new UiService();
    
    uiService.closeFeedThread(threadId)
      .catch(() => {
        addSnackbarIssue(
          "There was an error closing this post, please try again!",
          "warning"
        );
      });
  };

  const getMessages = (threadId) => {
    apiGetFeedThreadMessages(thread.Id)
      .then(() => {
        setSelectedFeedThreadIdInStore(thread.Id);
      })
      .catch(() => {
        addSnackbarIssue(
          "Unable to open the comments, please try again!",
          "warning"
        );
      });
  };

  return (
    <Box className={classes.feedFooter}>
      <IconButton
        onClick={() => likeThread(thread.Id)}
        disabled={loggedInUserLiked}
      >
        {loggedInUserLiked ? (
          <ThumbUpRounded
            style={{
              width: 20,
              height: 20,
              color: "#306ead",
            }}
          />
        ) : (
          <ThumbUpAltOutlinedIcon
            style={{
              width: 20,
              height: 20,
            }}
          />
        )}
      </IconButton>
      <Tooltip title={"See all comments"}>
        <IconButton onClick={() => getMessages(thread.Id)}>
          <StyledBadge badgeContent={thread.MessageCount} color="default">
            <ChatBubbleOutlineOutlinedIcon style={{ width: 20, height: 20 }} />
          </StyledBadge>
        </IconButton>
      </Tooltip>
      { thread.CanCloseFl === true && thread.ClosedFl === false ? (
      <Tooltip title={"Close post"}>
        <IconButton onClick={() => closePost(thread.Id)}>
          <Close
            style={{
              width: 20,
              height: 20,
            }}
          />
        </IconButton>
      </Tooltip>
      ) : null}
    </Box>
  );
};

const MessageTextBox = styled(InputBase)({
  "& .MuiInputBase-input": {
    padding: "5px 35px 5px 0px",
    border: "unset !important",
    fontSize: 13,
  },
});

const commentSection = (
  classes,
  latestMessage,
  comment,
  setComment,
  comments,
  originalCommentId,
  threadId,
  setEmojiIsVisible,
  emojiIsVisible,
  loading,
  setLoading
) => {
  return (
    <Box className={classes.commentsContainer}>
      {latestMessage ? (
        <Box>
          {comments && comments.length >= 1
            ? comments.map((comment) => {
                if (
                  comment.ThreadId === latestMessage.ThreadId &&
                  originalCommentId !== comment.Id
                )
                  return (
                    <Box
                      style={{ marginBottom: 10 }}
                      key={
                        "FeedThreadCommentContainer-" +
                        comment.Id +
                        comment.ThreadId
                      }
                    >
                      {getCommentHtml(comment)}
                    </Box>
                  );
                return null;
              })
            : getCommentHtml(latestMessage)}
        </Box>
      ) : null}
      <Box style={{ width: "100%" }}>
        <Divider className={classes.divider} />
        <Box style={{ position: "relative" }}>
          <MessageTextBox
            fullWidth
            size="small"
            variant="outlined"
            placeholder="Add a comment..."
            onChange={(e) => setComment(e.target.value)}
            value={comment}
            style={{
              width: "100%",
              paddingRight: 30,
              whiteSpace: "normal",
              lineBreak: "anywhere",
            }}
          />
          <IconButton
            onClick={() => setEmojiIsVisible(!emojiIsVisible)}
            style={{
              position: "absolute",
              right: 25,
              top: 0,
              height: 30,
              width: 30,
            }}
          >
            <Typography
              style={{
                height: 21,
                width: 20,
                color: "rgb(255 176 46)",
              }}
            >
              😀
            </Typography>
          </IconButton>

          {loading ? (
            <Box
              style={{
                position: "absolute",
                right: -10,
                top: 0,
                height: 30,
                width: 30,
              }}
            >
              <CircularProgress style={{ height: 30, width: 30 }} />
            </Box>
          ) : (
            <IconButton
              onClick={() => {
                setLoading(true);
                sendComment(comment, threadId, setComment, setLoading);
              }}
              style={{
                position: "absolute",
                right: -10,
                top: 0,
                height: 30,
                width: 30,
              }}
            >
              <Send
                style={{
                  height: 20,
                  width: 20,
                  color: "#3f90e2",
                }}
              />
            </IconButton>
          )}
        </Box>
      </Box>
    </Box>
  );
};

function getCommentHtml(comment) {
  return (
    <ThreadComment key={`ThreadComment-${comment.Id}`} comment={comment} />
  );
}

const sendComment = (comment, threadId, setComment, setLoading) => {
  if (!comment || !threadId) return setLoading(false);
  let parsedComment = { Blocks: [{ BlockType: "Text", BlockValue: comment }] };
  hubAddThreadMessage(threadId, JSON.stringify(parsedComment))
    .then(() => {
      setComment(""); // reset and empty text box.
    })
    .catch(() => {
      addSnackbarIssue("Unable to send message, please try again!", "warning");
    })
    .finally(() => {
      setLoading(false);
    });
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  threadContainer: {
    overflow: "hidden",
    marginBottom: 20,
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.only("xs")]: {
      marginLeft: 0,
      marginRight: 0,
      borderRadius: 0,
      boxShadow: "none",
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      borderRadius: "2px",
      boxShadow: "0 0 1px 2px #00000010",
    },
  },
  feedHeader: {
    height: 40,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  feedTextContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    height: "auto",
    minHeight: 50,
    color: "rgb(100, 100, 100)",
  },
  feedText: {
    whiteSpace: "break-spaces",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
  },
  feedHeaderText: {
    marginLeft: theme.spacing(2),
    width: "85%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  feedImageContainer: {
    width: "100%",
    height: 180,
  },
  feedImage: {
    height: "100%",
    transform: "scale(1,1)",
  },
  feedFooterText: {
    height: "auto",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  feedFooter: {
    width: "inherit",
    height: "auto",
    display: "flex",
    padding: "0px 10px",
    flexDirection: "row",
    justifyContent: "start",
  },
  divider: {
    margin: "5px -18px",
  },
  footerButtons: {
    display: "flex",
    flexDirection: "row",
    textTransform: "none",
  },
  footerButtonsBlue: {
    display: "flex",
    flexDirection: "row",
    textTransform: "none",
    background: "#3f90e2",
    color: "white !important",
    "&:hover, &:focus": {
      background: "#306ead",
    },
  },
  footerButtonsWhite: {
    display: "flex",
    flexDirection: "row",
    textTransform: "none",
    color: "#3f90e2 !important",
  },
  imageContainer: {
    height: 200,
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  image: {
    width: "auto",
    height: "auto",
    maxHeight: 200,
    maxWidth: "100%",
    display: "block",
  },
  metricsBox: {
    height: 20,
    position: "relative",
    width: "100%",
    marginTop: 10,
    padding: "0px 10px",
  },
  metricsContainer: {
    position: "absolute",
    bottom: 0,
    display: "flex",
    width: "-webkit-fill-available",
    justifyContent: "space-between",
    flexDirection: "row",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  likes: {
    marginLeft: 10,
    color: "#3f90e2",
    height: "0.7em",
    width: "0.7em",
    marginTop: "auto",
    marginBottom: "auto",
    marginRight: 3,
  },
  commentTextBox: {
    marginTop: theme.spacing(1),
    display: "flex",
    alignItems: "flex-end",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  commentsContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  userImageContainer: {
    height: 200,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
  },
}));

import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import memoize from "lodash.memoize";

const slice = createSlice({
  name: "selectedTeam",
  initialState: { id: null },
  reducers: {
    // actions => action handlers

    selectedTeamUpdated: (selectedTeam, action) => {
      // update existing selected team
      return { ...selectedTeam, ...action.payload };
    },
  },
});

export const { selectedTeamUpdated } = slice.actions;
export default slice.reducer;

export const getSelectedTeamId = createSelector(
  (state) => state.ui.selectedTeam,
  (selectedTeam) =>
    memoize(() => {
      return selectedTeam.id;
    })
);

// import { SpeedDial } from "@material-ui/lab";
import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
// import SpeedDialAction from "@material-ui/lab/SpeedDialAction";

export default function HeaderSpeedDial(props) {
  // const classes = useStyles();
  // const [open, setOpen] = React.useState(false);
  let actions = props.actions;

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  // const handleClick = () => {
  //   handleClose();
  // };

  // let selectedAction = actions.find((action) => action.selected);

  return (
    <>
      {actions.map((action) =>
        !action.selected ? (
          <Tooltip title={action.name} key={action.name}>
            <IconButton
              onClick={() => action.onClick()}
              style={{ background: "white" }}
            >
              {action.icon}
            </IconButton>
          </Tooltip>
        ) : null
      )}
    </>
  );

  // return (
  //   <SpeedDial
  //     ariaLabel="view-change"
  //     className={classes.speedDial}
  //     icon={selectedAction?.icon}
  //     key={selectedAction?.name}
  //     classes={{ fab: classes.button }}
  //     onClose={handleClose}
  //     onOpen={handleOpen}
  //     open={open}
  //     direction={"down"}
  //   >
  //     {actions.map((action) =>
  //       !action.selected ? (
  //         <SpeedDialAction
  //           key={action.name}
  //           icon={action.icon}
  //           tooltipTitle={action.name}
  //           onClick={() => handleClick(action.onClick())}
  //           classes={{ fab: classes.button }}
  //         />
  //       ) : null
  //     )}
  //   </SpeedDial>
  // );
}

// const useStyles = makeStyles((theme) => ({
//   speedDial: {
//     position: "absolute",
//     "&.MuiSpeedDial-directionDown": {
//       top: 12,
//       left: theme.spacing(1),
//     },
//   },
//   button: {
//     background: "white",
//     "&:hover": {
//       background: "rgba(256, 256, 256, 1)",
//     },
//     height: 40,
//     width: 40,
//   },
// }));

import React from "react";
import { useSelector } from "react-redux";
import { Box, Typography, Divider, Tooltip, Button, makeStyles } from "@material-ui/core";
import { MailOutline } from "@material-ui/icons";
import Icon from '@material-ui/core/Icon'

import { updateSelectedTeamState } from "../../../../components/common/StoreCommon";
import { messageHub } from "../../../../components/common/Common";
import { getColorStyle } from '../../../../components/common/Common';
import { getMetric1IdAndValue, getMetric2IdAndValue } from "../../../../store/hub/hubConversionData";
import { getSelectedTimePeriod } from "../../../../store/ui/conversion";
import { getMetric } from "../../../../store/hub/conversionMetrics";

export default function TeamHeader({selectedTeam, updateTeamView}) {

  const classes = useStyles();
  const team = selectedTeam;

  const selectedTimePeriod = useSelector((state) => getSelectedTimePeriod(state.ui.conversion));
  const metric1IdAndValue = useSelector((state) => getMetric1IdAndValue(state.entities.hubConversionData, selectedTeam.Id, selectedTimePeriod));
  const metric2IdAndValue = useSelector((state) => getMetric2IdAndValue(state.entities.hubConversionData, selectedTeam.Id, selectedTimePeriod));
  let metric1Id = null;
  let metric2Id = null;
  if (metric1IdAndValue) {
    metric1Id = metric1IdAndValue.id;
  }
  if (metric2IdAndValue) {
    metric2Id = metric2IdAndValue.id;
  }
  const metric1 = useSelector((state) => getMetric(state.entities.conversionMetrics, metric1Id));
  const metric2 = useSelector((state) => getMetric(state.entities.conversionMetrics, metric2Id));

  const selectTeam = (id, name) => {
    updateSelectedTeamState(id, name);
    updateTeamView("Hub Overview");
  };

  return (

    <Box className={classes.headerPaperCenter}>
      <Tooltip title={"Focus hub"}>
        <Button
          style={{ textTransform: "none" }}
          onClick={() => selectTeam(team.Id, team.Name)}
        >
          <Typography
            className={classes.teamNameText}
            variant="h5"
            gutterBottom
          >
            {team.Name}
          </Typography>
        </Button>
      </Tooltip>

      <Tooltip title="Message hub">
        <Button
          className={classes.mailButton}
          onClick={() => messageHub(team)}
        >
          <MailOutline className={classes.icon} />
        </Button>
      </Tooltip>

      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        {metric1 && (
          <>
            <Icon className={classes.icon} style={getColorStyle(metric1.color)}>{metric1.icon}</Icon>
            <Typography variant="body1" className={classes.conversionText}>{metric1IdAndValue.value}</Typography> 
          </>
        )}

        <Divider
          flexItem
          style={{ marginLeft: 10, marginRight: 10 }}
        />

        {metric2 && (
          <>
            <Icon className={classes.icon} style={getColorStyle(metric2.color)}>{metric2.icon}</Icon>
            <Typography variant="body1" className={classes.conversionText}>{metric2IdAndValue.value}</Typography> 
          </>
        )}

      </Box>
  </Box>

  );
}

const useStyles = makeStyles((theme) => ({
  headerPaperCenter: {
    textAlign: "left",
    width: "auto",
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
  },
  teamNameText: {
    [theme.breakpoints.up("lg")]: {
      fontSize: 19,
    },
    [theme.breakpoints.only("xl")]: {
      fontSize: 24,
    },
    color: "rgb(100, 100, 100)",
    marginTop: "auto",
    marginBottom: "auto",
  },
  icon: {
    [theme.breakpoints.up("lg")]: {
      fontSize: 19,
    },
    [theme.breakpoints.only("xl")]: {
      fontSize: 24,
    },
    marginRight: 5,
  },
  conversionText: {
    [theme.breakpoints.up("lg")]: {
      fontSize: 13,
    },
    [theme.breakpoints.only("xl")]: {
      fontSize: 16,
    },
  },
  mailButton: {
    [theme.breakpoints.up("lg")]: {
      width: 40,
      minWidth: 40,
    },
    [theme.breakpoints.only("xl")]: {
      width: 64,
    },
  },
}));

import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import memoize from "lodash.memoize";
import { createUuidv4 } from "../../components/common/Common";

const slice = createSlice({
  name: "snackbar",
  initialState: {
    issues: [],
  },
  reducers: {
    // actions => action handlers

    snackbarIssueAdded: (snackbar, action) => {
      // add a new snackbar issue
      addSnackbarIssueToStore(snackbar, action.payload.text, action.payload.severity, action.payload.duration, action.payload.large);
    },
    uniqueSnackbarIssueAdded: (snackbar, action) => {
      let index = snackbar.issues.findIndex(
        (issue) => issue.text === action.payload.text &&
                    issue.actioned === false
      );
      if (index >= 0) return;
      addSnackbarIssueToStore(snackbar, action.payload.text, action.payload.severity, action.payload.duration, action.payload.large);
    },
    snackbarIssueByTextActioned: (snackbar, action) => {
      let issues = [...snackbar.issues];
      for (let i = 0; i < issues.length; i++) {
        let issue = issues[i];
        if (issue.text === action.payload.text && issue.actioned === false)
        {
          markSnackbarIssueAsActioned(snackbar, issue.id);
        }
      }
    },
    snackbarIssueActioned: (snackbar, action) => {
      markSnackbarIssueAsActioned(snackbar, action.payload.id);
    },
  },
});

export const addSnackbarIssueToStore = (state, text, severity, duration, large) => {
  let autoHideDuration = 10000;
  if (duration > 0) {
    autoHideDuration = duration;
  }
  if (duration == 0) {
    autoHideDuration = null;
  }
  
  let isLarge = false;
  if (large && large === true) {
    isLarge = true;
  }

  state.issues.push({
    text: text,
    severity: severity,
    actioned: false,
    id: createUuidv4(),
    autoHideDuration: autoHideDuration,
    large: isLarge
  });
};

export const markSnackbarIssueAsActioned = (state, id) => {
  let index = state.issues.findIndex(
    (issue) => issue.id === id
  );

  state.issues.splice(index, 1, {
    ...state.issues[index],
    ...{ actioned: true },
  });
}

export const { snackbarIssueAdded, uniqueSnackbarIssueAdded, snackbarIssueActioned, snackbarIssueByTextActioned } = slice.actions;
export default slice.reducer;

export const getNewSnackbarIssues = createSelector(
  (state) => state.ui.snackbar,
  (snackbar) =>
    memoize(() => {
      if (snackbar.issues && snackbar.issues.length >= 0) {
        const newIssues = snackbar.issues.filter(
          (issue) => issue.actioned === false
        );
        return newIssues;
      }
    })
);

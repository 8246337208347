import React from "react";
import { useSelector } from "react-redux";
import { Grid, Box, makeStyles} from "@material-ui/core";

import HubWidget from "./HubWidget";
import ConversionWidget from "./ConversionWidget";
import { getMetric1IdAndValue, getMetric2IdAndValue } from "../../../../store/hub/hubConversionData";
import { getSelectedTimePeriod } from "../../../../store/ui/conversion";

export default function HeaderWidgets(props) {

  const classes = useStyles();
  const selectedTeam = props.selectedTeam;
  
  const selectedTimePeriod = useSelector((state) => getSelectedTimePeriod(state.ui.conversion));
  const metric1IdAndValue = useSelector((state) => getMetric1IdAndValue(state.entities.hubConversionData, selectedTeam.Id, selectedTimePeriod));
  const metric2IdAndValue = useSelector((state) => getMetric2IdAndValue(state.entities.hubConversionData, selectedTeam.Id, selectedTimePeriod));
  const showUserStats = (metric1IdAndValue != null || metric2IdAndValue != null);

  return (
    <Box className={classes.gridContainer}>
      <Grid container spacing={2} className={classes.paperContainer}>
        <HubWidget selectedTeam={selectedTeam}></HubWidget>
        {showUserStats && (   
          <ConversionWidget metric1IdAndValue={metric1IdAndValue} metric2IdAndValue={metric2IdAndValue}></ConversionWidget>
        )}
      </Grid>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    [theme.breakpoints.only("xs")]: {
      overflow: "hidden",
      marginRight: -15,
    },
    [theme.breakpoints.up("sm")]: {
      overflow: "inherit",
    },
  },
  paperContainer: {
    [theme.breakpoints.only("xs")]: {
      justifyContent: "flex-start",
      flexWrap: "nowrap",
      overflowX: "scroll",
    },
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
    },
  },
}));

import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  AppBar,
  makeStyles,
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  Box,
  LinearProgress,
} from "@material-ui/core";
import MessageIcon from "@material-ui/icons/Message";
import { Close } from "@material-ui/icons";
import { Button, ButtonGroup } from "@mui/material";

import ExpandedThreadOptions from "./ExpandedThreadOptions";
import ThreadAvatar from "./ThreadAvatar";
import MyEditor from "../../text-editor";
import MessageContent from "./MessageContent";
import UiService from "../../../services/UiService";

import {
  loggedInUserId,
  addSnackbarIssue,
  updateHelpRequestThreadId,
  updateSidebar
} from "../../../components/common/StoreCommon";
import { 
  getMessageThreadMessages } from "../../../store/hub/message";
import {
  apiGetDmThreadMessages,
  apiGetEarlierDmThreadMessages,
  apiGetAchievementText
} from "../../../components/common/ApiMiddleware";
import {
  hubAddThreadMessage,
} from "../../../components/common/ConnectionMiddleware";
import { 
  setNewFeedPostVisibilityInStore, 
  setNewFeedPostDefaultTextInStore,
  setNewFeedPostAchievementIdInStore,
  setNewFeedPostOriginatingThreadIdInStore,
} from "../../../components/common/StoreFeedUi"

export default function ExpandedThread({ thread, toolbarType }) {

  const classes = useStyles();
  const messages = useSelector(getMessageThreadMessages)(thread.Id);
  const endOfMessages = useRef(null);
  // bottom is set when we want to scroll to the bottom
  // this is set to false when a user scrolls up a message
  const [bottom, setBottom] = useState(true);
  const [showScrollToBottom, setShowScrollToBottom] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageSending, setMessageSending] = useState(false);

  const closeThread = () => {
    const uiService = new UiService();
    uiService.closeDmThread();
  };

  useEffect(() => {

    const getThreadMessages = () => {
      setLoading(true);
      apiGetDmThreadMessages(thread.Id)
        .then(() => {
          // create timer to allow images and other content to load
          const setupTimer = setTimeout(() => {
            // after 3 seconds, set loading to false so the user can see the content
            setLoading(false);
          }, 3000);

          // if loading is false & there is a setupTimer - clear the timer
          if (!loading && setupTimer) clearTimeout(setupTimer);
        })
        .catch(() => {
          closeThread();
          addSnackbarIssue(
            "Opening message failed - please try again.",
            "warning"
          );
        });
    };

    // if a user opens a new message box that contains messages from another thread
    // set loading to true & getThreadMessages
    if (
      messages.length >= 1 &&
      !loading &&
      messages[0]["ThreadId"] !== thread.Id
    ) {
      getThreadMessages();
    }

    // if there are no messages & loading is false get messages
    if (messages.length <= 0 && !loading) {
      getThreadMessages();
    }

    if (bottom) {
      scrollToBottom("auto", endOfMessages);
      setBottom(true);
    }

  }, [thread, messages, bottom, loading, setLoading]);

  const handleScroll = (e) => {
    // if the user is scrolled within 10% of the height and a new message is added,
    // setBottom to true. This scrolls the user to the new message automatically.
    setBottom(
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight * 1.1
    );
    if (!bottom) {
      setShowScrollToBottom(true);
    }
  };

  const scrollToBottom = (behavior, endOfMessages) => {
    endOfMessages.current.scrollIntoView({ behavior: behavior, block: "end" });
    setShowScrollToBottom(false);
  };

  const getEarlierThreadMessages = () => {
    apiGetEarlierDmThreadMessages(
      thread.Id,
      messages[0]["UtcCreatedDateTime"]
    );
  };

  const createAchievementPost = (achievementId) => {
    apiGetAchievementText(achievementId)
    .then((text) => {
      updateSidebar({ view: "Feed", open: true });
      setNewFeedPostDefaultTextInStore(text);
      setNewFeedPostAchievementIdInStore(achievementId);
      setNewFeedPostOriginatingThreadIdInStore(thread.Id);
      setNewFeedPostVisibilityInStore(true);
    });    
  }

  const getArchivedText = () => {
    if (thread.AchievementId)
      return "A post has been created for this achievement";
    return "You are the only user in this thread, sending messages has been disabled for this reason.";
  }
  // onSubmit must return a promise
  // If an error occurs we show the error and throw an Error so the promise is rejected
  const onSubmit = async (message, mentionedUsers, attachments) => {
    setMessageSending(true);
    try {
      try {
        return await hubAddThreadMessage(thread.Id, message, mentionedUsers, attachments);
      } catch {
        addSnackbarIssue(
          "Failed to add the message, please try again",
          "warning"
        );
        throw new Error("Failed to add message");
      }
    } finally {
      setMessageSending(false);
    }
  };

  return (
    <>
      {thread ? (
        <>
          {toolbarType && toolbarType === "HelpRequest" && (
            <HelpCentreToolbar thread={thread} />
          )}
          {!toolbarType && <MessageCentreToolbar thread={thread} onClose={closeThread} />}
          <Box className={classes.container} onScroll={handleScroll}>
            

            {thread.EarlierMessagesExist && (
              <ButtonGroup
                style={{
                  marginBottom: 20,
                  marginTop: 10,
                  width: "100%",
                }}
              >
                <Button
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                  variant="outlined"
                  onClick={() => getEarlierThreadMessages()}
                >
                  Load Previous Messages
                </Button>
              </ButtonGroup>
            )}

            {messages && messages.length >= 1 ? (
              messages.map((message, index) => {
                return (
                  <MessageContent
                    key={message.Id}
                    message={message}
                    index={index}
                    allMessages={messages}
                    thread={thread}
                  />
                );
              })
            ) : (
              <LinearProgress />
            )}

            {messages && showScrollToBottom ? (
              <IconButton
                className={classes.newMessageIcon}
                onClick={() =>
                  scrollToBottom("smooth", endOfMessages, messages)
                }
              >
                <MessageIcon />
              </IconButton>
            ) : null}

            <Box ref={endOfMessages} />
          </Box>
          
          {thread.AchievementId && thread.Display && (
            <ButtonGroup
            style={{
              marginBottom: 20,
              marginTop: 10,
              width: "100%",
            }}
          >
            <Button
              style={{ marginLeft: "auto", marginRight: "auto" }}
              variant="outlined"
              onClick={() => createAchievementPost(thread.AchievementId)}
            >
              Create Post
            </Button>
          </ButtonGroup>
          )}

          {(thread.UserIds.length < 1 && thread.TeamIds.length < 1) ||
          !thread.Display ? (
            <Box
              style={{
                width: "100%",
                marginBottom: 30,
                overflow: "hidden",
                whiteSpace: "pre-line",
                textAlign: "center",
                wordBreak: "break-word",
              }}
            >
              <Typography
                variant="body1"
                style={{ color: "rgb(50,50,50)", marginBottom: 30 }}
              > 
                { getArchivedText() }
              </Typography>
            </Box>
          ) : (
            <Box style={{ marginBottom: 10 }}>
              <MyEditor
                onSubmit={onSubmit}
                toolbarPosition="bottom"
                showInsertImage={true}
                loading={messageSending}
              />
            </Box>
          )}
        </>
      ) : (
        <Box ref={endOfMessages} />
      )}
    </>
  );
}

function MessageCentreToolbar({ thread, onClose }) {
  const classes = useStyles();
  const createdByLoggedInUser = thread.CreatedUserId === loggedInUserId();

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar>
        <ThreadAvatar thread={thread} />
        <Tooltip title={thread.DisplayName} placement="bottom-start">
          <Typography variant="h6" className={classes.title}>
            {thread.DisplayName ? thread.DisplayName : "Untitled Message"}
          </Typography>
        </Tooltip>
        <Box style={{ marginLeft: "auto" }}>
          <ExpandedThreadOptions
            threadId={thread.Id}
            isUserThread={thread.UserIds.length >= 1}
            createdByLoggedInUser={createdByLoggedInUser}
          />

          <IconButton
            size="small"
            onClick={() => onClose()}
          >
            <Close />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

function HelpCentreToolbar({ thread }) {
  const classes = useStyles();

  return (
    <Toolbar>
      <ThreadAvatar thread={thread} />
      <Tooltip title={thread.DisplayName} placement="bottom-start">
        <Typography variant="h6" className={classes.title}>
          {thread.DisplayName ? thread.DisplayName : "Untitled Message"}
        </Typography>
      </Tooltip>
      <Box style={{ marginLeft: "auto" }}>
        <IconButton
          size="small"
          onClick={() => {
            updateHelpRequestThreadId(false);
          }}
        >
          <Close />
        </IconButton>
      </Box>
    </Toolbar>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    maxWidth: "70%",
    width: "auto",
    overflow: "hidden",
    display: "block",
    marginLeft: theme.spacing(1),
    textOverflow: "ellipsis",
  },
  appBar: {
    background: "white",
    color: "black",
  },
  container: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    overflowY: "scroll",
    height: "100%",
    position: "relative",
  },
  newMessageIcon: {
    left: 220,
    width: 50,
    bottom: 90,
    height: 50,
    marginBottom: 20,
    position: "fixed",
    background: "rgb(236 236 236)",
  },
  message: {
    width: "fit-content",
    maxWidth: "75%",
    borderRadius: 12,
    marginTop: "auto",
    marginBottom: "auto",
    padding: 5,
    overflow: "hidden",
    height: "auto",
    whiteSpace: "pre-line",
    lineBreak: "normal",
  },
  messageContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  messageAvatar: {
    width: 30,
    height: 30,
    marginTop: "auto",
    marginRight: theme.spacing(1),
  },
  messageThreadImage: {
    marginTop: "auto",
    marginBottom: "auto",
    width: 50,
    height: 50,
  },
  smallMessageThreadImage: {
    width: 35,
    height: 35,
  },
  sentDateTime: {
    marginLeft: 40,
    display: "none",
  },
}));

import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import memoize from "lodash.memoize";

const slice = createSlice({
  name: "main",
  initialState: {
    view: "Team Overview",
  },
  reducers: {
    // actions => action handlers
    mainViewUpdated: (main, action) => {
      // update main view
      main.view = action.payload;
    },
  },
});

export const { mainViewUpdated } = slice.actions;
export default slice.reducer;

export const getMainView = createSelector(
  (state) => state.ui.main,
  (main) =>
    memoize(() => {
      return main.view;
    })
);

import { Typography, Box, Divider, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Ticker from "react-ticker";
import { getTickerEntries } from "../../../store/hub/ticker";

export default function TeamRoomTicker() {
  const tickerEntries = useSelector(getTickerEntries)();
  const [start, setStart] = React.useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (!start && tickerEntries) setStart(true);
  }, [start, tickerEntries]);

  return (
    <>
      {tickerEntries && tickerEntries.length >= 1 ? (
        <Box
          className={classes.tickerContainer}
          style={{ width: "100%" }}
        >
          <Box
            style={{
              background: "#F4AF1B",
              zIndex: 1,
              display: "flex",
              padding: "0px 10px",
            }}
          >
            <Box
              style={{
                display: "inherit",
                flexDirection: "row",
              }}
            >
              <Typography variant="h5" className={classes.breakingNewsText}>
                BREAKING NEWS
              </Typography>
            </Box>
          </Box>
          <Box
            className={classes.tickerTextContainer}
            style={{ width: "100%" }}
          >
            {start ? (
              <Ticker offset="run-in" speed={8}>
                {() => <GetTickerEntries tickerEntries={tickerEntries} />}
              </Ticker>
            ) : null}
          </Box>
        </Box>
      ) : null}
    </>
  );
}

const GetTickerEntries = ({ tickerEntries }) => {
  // A placeholder is needed, to tell react-ticker, that width and height might have changed
  // It uses MutationObserver internally
  const classes = useStyles();
  return tickerEntries ? (
    <Box className={classes.tickerEntries}>
      {tickerEntries.map((entry) => {
        return (
          <div key={entry.Id}>
          <Box style={{ display: "flex", flexDirection: "row" }}>
            <Typography variant="h5" className={classes.tickerText}>
              {entry.Message}
            </Typography>
            <Divider
              style={{ background: "white" }}
              orientation="vertical"
              variant="middle"
              flexItem
            />
          </Box>
          </div>
        );
      })}
    </Box>
  ) : (
    <Box className={classes.tickerEntries}>placeholder</Box>
  );
};

const useStyles = makeStyles((theme) => ({
  tickerContainer: {
    position: "fixed",
    bottom: 0
  },
  tickerTextContainer: {
    [theme.breakpoints.up("sm")]: {
      height: 80,
    },
    [theme.breakpoints.up("lg")]: {
      height: 80,
    },
    [theme.breakpoints.only("xl")]: {
      height: 100,
    },
    background: "#141A46",
    overflowY: "hidden",
  },
  breakingNewsText: {
    [theme.breakpoints.up("sm")]: {
      fontSize: 20,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 20,
    },
    [theme.breakpoints.only("xl")]: {
      fontSize: 25,
    },
    fontWeight: "bold",
    color: "#141A46",
  },
  tickerEntries: {
    [theme.breakpoints.up("sm")]: {
      height: 80,
    },
    [theme.breakpoints.up("lg")]: {
      height: 80,
    },
    [theme.breakpoints.only("xl")]: {
      height: 100,
    },
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
  },
  tickerText: {
    [theme.breakpoints.up("sm")]: {
      fontSize: 18,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 18,
    },
    [theme.breakpoints.only("xl")]: {
      fontSize: 22,
    },
    whiteSpace: "nowrap",
    color: "white",
  },
}));

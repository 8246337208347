import { getStore } from "../common/StoreCommon";
import { 
  userSettingsVisibilityUpdated,
  userSettingsUserIdUpdated,
  storageUrlUpdated,
  getStorageUrl,
}   
from "../../store/ui/settings";

export function updateUserSettingsVisibilityInStore(bool) {
  getStore().dispatch(userSettingsVisibilityUpdated(bool));
}

export function updateUserSettingsUserIdInStore(id) {
  getStore().dispatch(userSettingsUserIdUpdated(id));
}

export function updateStorageUrlInStore(url) {
  getStore().dispatch(storageUrlUpdated(url));
}

export function getStorageUrlFromStore() {
  return getStorageUrl(getStore().getState())();
}

/*
export function getUserSettingsVisibilityFromStore() {
  return getShowUserSettings(getStore().getState())();
}

export function getUserSettingsUserIdFromStore() {
  return getUserSettingsUserId(getStore().getState())();
}
*/
import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import memoize from "lodash.memoize";

const slice = createSlice({
  name: "report",
  initialState: {
    showReport: null,
    reportUserId: null,
    dateRange: false,
    toDate: null,
    fromDate: null,
    reportView: "Summary View",
  },
  reducers: {
    // actions => action handlers

    reportUpdated: (report, action) => {
      // update existing selected team Id
      return { ...report, ...action.payload };
    },
  },
});

export const { reportUpdated } = slice.actions;
export default slice.reducer;

export const getReportState = createSelector(
  (state) => state.ui.report,
  (report) =>
    memoize(() => {
      return report;
    })
);

export const getReportUserId = createSelector(
  (state) => state.ui.report,
  (report) =>
    memoize(() => {
      return report.reportUserId;
    })
);

import {
  IsJsonString,
} from "../components/common/Common";

export default class ThreadHelper 
{ 

  getPostPreviewText(jsonContent) {
    if (IsJsonString(jsonContent))
      return JSON.parse(jsonContent).Blocks[0]["BlockValue"];    
    return null;
  }

  isMessageContentEmpty(messageContent) {
    const parsedMessage = JSON.parse(messageContent);
    if (parsedMessage.Blocks.length > 1) {
      return false;
    }

    if (
      parsedMessage.Blocks[0]["BlockValue"] === "" ||
      parsedMessage.Blocks[0]["BlockValue"] === "--newline--"
    ) {
      return true;
    }

    return false;
  };
}
import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "hubMessages",
  initialState: [],
  reducers: {
    // actions => action handlers

    invokeAdded: (message, action) => {
      // adds a new hub method called object to existing list
      message.push(action.payload);
    },
    hubMessageAdded: (message, action) => {
      message.push(action.payload);
    },
    invokeFailed: (message, action) => {
      message.push(action.payload);
    },
  },
});

export const { invokeAdded, hubMessageAdded, invokeFailed } = slice.actions;
export default slice.reducer;

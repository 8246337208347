import { ErrorRounded } from "@material-ui/icons";
import { getStore } from "../components/common/StoreCommon";
import {
  feedThreadsAdded,
  feedThreadAdded,
  feedThreadLiked,
  feedThreadMessageAdded,
  feedThreadUpdated,
  feedThreadMessagesAdded,
  feedThreadsVisible,
  feedThreadClosed,
  getFeedThreadById,
  getFeedThreads,
  feedThreadExpired,
  feedThreadHidden,
} from "../store/hub/boomFeed";

export default class FeedService {

  addThreads(threads) {
    getStore().dispatch(feedThreadsAdded({ threads }));
  }

  addThread(thread, visibleInFeed) {
    getStore().dispatch(feedThreadAdded({ ...thread, ...{ VisibleInFeed: visibleInFeed } }));
  }

  closeThread(threadId) {
    getStore().dispatch(feedThreadClosed({ threadId }));
  }

  likeThread(hubMessage) {
    getStore().dispatch(feedThreadLiked(hubMessage));
  }

  threadHasExpired(threadId) {
    this.hideThread(threadId);
    getStore().dispatch(feedThreadExpired({ threadId }));
  }

  hideThread(threadId) {
    getStore().dispatch(feedThreadHidden({ threadId }));
  }

  getThreadById(threadId) {
    return getFeedThreadById(this._getState())(threadId);
  }

  getThreads() {
    return getFeedThreads(this._getState())();
  }

  _getState() {
    return getStore().getState();
  }
}
import React from "react";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getUserById } from "../../../store/hub/users";
import { getLoggedInUserId } from "../../../store/hub/loggedInUser";
import User from "../../../features/user";

export default function Team(props) {
  const userSelector = useSelector(getUserById);
  const userIds = props.selectedTeam.UserIds;
  const teamLeaderIds = props.selectedTeam.TeamLeaderUserIds;

  const loggedInUserIdSelector = useSelector(getLoggedInUserId);
  let loggedInUser = null;
  let loggedInUserTeamLeader = false;
  if (props.selectedTeam.MemberOf) {
    const loggedInUserId = loggedInUserIdSelector();
    loggedInUser = userSelector(loggedInUserId);
    loggedInUserTeamLeader = teamLeaderIds.some(
      (userId) => userId === loggedInUserId
    );
  }

  return (
    <Grid item xs={12}>
      <Grid container justifyContent={"center"} spacing={2}>
        {loggedInUser ? (
          <User
            user={loggedInUser}
            size={props.size}
            isTeamLeader={loggedInUserTeamLeader}
          />
        ) : null}
        {userIds.map((id) => {
          let user = userSelector(id);
          let isTeamLeader = teamLeaderIds.some((userId) => userId === id);
          if (user)
            return (
              <User
                user={user}
                isTeamLeader={isTeamLeader}
                key={"user-" + user.Id}
                size={props.size}
              />
            );
          return null;
        })}
      </Grid>
    </Grid>
  );
}

import {
  Avatar,
  Badge,
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@mui/system";
import React from "react";
import HomeIcon from "@material-ui/icons/Home";
import {
  loggedInUserAvatar,
  loggedInUserId,
  showReport,
  updateMainView,
  updateUserProfileVisibility,
} from "../../../components/common/StoreCommon";
import { getShowUserProfile } from "../../../store/ui/userProfile";
import { useSelector } from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

export default function SpeedDialSidebar({ sidebarEntries, toggleView, view }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const showUserProfile = useSelector(getShowUserProfile)();

  const [anchorUserMenuEl, setAnchorUserMenuEl] = React.useState(null);
  const open = Boolean(anchorUserMenuEl);

  const handleClick = (event) => {
    setAnchorUserMenuEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorUserMenuEl(null);
  };

  return (
    <Box className={classes.container}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(index, newValue) => setValue(newValue)}
        className={classes.navigationMenu}
      >
        <BottomNavigationAction
          label="Hub"
          className={classes.navigationButton}
          icon={<HomeIcon />}
          onClick={() => {
            updateMainView("Team Overview");
            toggleView(null);
          }}
        />
        <BottomNavigationAction
          label="User"
          className={classes.navigationButton}
          icon={
            <Avatar
              style={{ height: 30, width: 30 }}
              src={loggedInUserAvatar()}
              alt="user"
            />
          }
          onClick={handleClick}
        />
        {sidebarEntries.map((action) => {
          if (action.text === "Bench" || action.text === "Hubs") return null;
          return (
            <BottomNavigationAction
              key={action.text}
              label={action.text}
              icon={
                <Badge badgeContent={action.count} color="secondary">
                  {action.icon}
                </Badge>
              }
              onClick={() => toggleView(action.text)}
              className={classes.navigationButton}
            />
          );
        })}
      </BottomNavigation>

      <Menu
        id="expanded-user-menu"
        anchorEl={anchorUserMenuEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={(e) => {
            handleClose(e);
            showReport(loggedInUserId());
          }}
        >
          My Stats
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleClose(e);
            updateUserProfileVisibility(!showUserProfile);
          }}
        >
          My Profile
        </MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem>
      </Menu>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100vw",
    position: "fixed",
    bottom: 0,
    [theme.breakpoints.only("xs")]: {
      display: "block",
    },
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  navigationMenu: {
    height: 60,
    background: "rgb(54, 54, 54)",
  },
  navigationButton: {
    color: "rgba(245, 245, 245, 0.6)",
    "&.Mui-selected": {
      background: "#424242",
      borderRadius: 10,
      margin: "3px 0px",
      color: "orange",
    },
  },
}));

import firebase from "firebase/app";
import "firebase/auth";
import { addSnackbarIssue } from "./StoreCommon";

export function updateFirebaseUserPassword(password) {
  const auth = firebase.auth();

  auth.currentUser
    .updatePassword(password)
    .then((success) => {
      addSnackbarIssue("Password changed successfully", "success");
    })
    .catch((error) => {
      addSnackbarIssue(error.message, "warning");
    });
}

import { Box, Fade, Typography } from "@material-ui/core";
import React from "react";
import {
  convertDateTime,
  parseJsonMessageForDisplay,
} from "../../../components/common/Common";
import { getUserNameFromId } from "../../../components/common/StoreCommon";

export default function ThreadComment({ comment }) {
  const [commentDateTimeVisible, setCommentDateTimeVisible] =
    React.useState(false);

  return (
    <Box
      style={{ display: "flex", flexDirection: "row" }}
      onMouseEnter={() => setCommentDateTimeVisible(true)}
      onMouseLeave={() => setCommentDateTimeVisible(false)}
    >
      <Box
        style={{
          lineBreak: "auto",
          whiteSpace: "pre-wrap",
          wordBreak: "break-word",
          color: "rgb(100, 100, 100)",
        }}
      >
        <Typography
          variant="body2"
          style={{ fontWeight: "bold", fontSize: 12 }}
        >
          {getUserNameFromId(comment["UserId"])}
          {": "}
          {parseJsonMessageForDisplay(comment["JsonContent"], true)}
          {
            <Fade in={commentDateTimeVisible}>
              <Typography
                variant="caption"
                style={{ fontWeight: "normal", color: "rgb(110,110,110)" }}
              >
                {" - sent " + convertDateTime(comment.UtcCreatedDateTime)}
              </Typography>
            </Fade>
          }
        </Typography>
      </Box>
    </Box>
  );
}

import TokenHelper from "./../helpers/TokenHelper";
import RequestHelper from "./../helpers/RequestHelper";
import TokenService from "./../services/TokenService";
import {
  ExpiredRefreshTokenError,
} from "../core/CustomErrors";
import {
  removeAuthTokensAndRedirectToLogin,
  logRocketCatchError,
} from "../components/common/Common";
import { RepeatOneSharp } from "@material-ui/icons";

export default class ApiService {

  #_requestHelper = new RequestHelper();
  #_tokenHelper = new TokenHelper();
  #_tokenService = new TokenService();

  /*
    When calling the api the service will check the access token first.  If this has expired and
    the refresh token has expired a ExpiredRefreshTokenError is raised.  We cannot execute
    any more logic at this point.  This would just generate more errors so we direct the user back to the login page
    If the api request responds but there is an error we throw an Error so the calling promise fails and the UI
    can take the appropriate action
  */
  // TODO: Check when route does not exist or error occurs in call (500 returned)
  async getApiResponse(route, method, body) {
    console.log("ApiService.getApiResponse: " + route + " " + method);

    return await this.#_tokenService.checkAccessToken().then(() => {
      return this.#_requestHelper
        .getRequestResponse(
          route,
          this.#_tokenHelper.getAccessToken(),
          method,
          body
        )
        .then((response) => {
          console.log("ApiService " + route + " response: " + response.status);

          if (!response.ok) {
            throw new Error("Api call failed " + route);
          }

          return response.json()
            .catch(() => { 
              return response.body; 
            });

        
        });
    }).catch((err)=> {
      if (err instanceof ExpiredRefreshTokenError) {
        removeAuthTokensAndRedirectToLogin();
      } else {
        logRocketCatchError(err);
        throw err;
      }
    });
  }

  async closeFeedThread(threadId) {
    return this.getApiResponse(`/thread/closefeedthread/${threadId}`, "POST");
  }

  async markThreadAsRead(threadId) {
    return this.getApiResponse(`/thread/markthreadasread/${threadId}`, "POST");
  }
  
  async markThreadMessageAsRead(threadMessageId) {
    return this.getApiResponse(`/thread/markthreadmessageasread/${threadMessageId}`, "POST");
  }

}

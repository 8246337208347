import React, { useState } from "react";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSelector } from "react-redux";
import { updateNewMessageRecipients } from "../../../components/common/StoreCommon";
import { getCanMessageUsers } from "../../../store/hub/canMessageUsers";
import { getCanMessageTeams } from "../../../store/hub/canMessageTeams";

export default function FixedTags({ recipients }) {
  let canMessageUsers = useSelector(getCanMessageUsers)();
  let canMessageTeams = useSelector(getCanMessageTeams)();
  const [placeholder, setPlaceholder] = useState("To");
  const canMessageOptions = canMessageUsers.concat(canMessageTeams);
  const fixedOptions = [canMessageOptions];
  function setUsers(newValue) {
    updateNewMessageRecipients(newValue);
  }

  let defaultValue = [];
  if (recipients && recipients.length >= 1) {
    // this is only used if a user clicks to message a user from their portrait
    // and there is no default message thread already created
    defaultValue.push({
      Name: recipients[0].Name,
      Id: recipients[0].Id,
      Type: recipients[0].Type,
    });
    if (placeholder !== "") setPlaceholder("");
  }

  return (
    <Autocomplete
      multiple
      limitTags={2}
      disableCloseOnSelect={false}
      defaultValue={defaultValue}
      size="small"
      id="new-message-recipients"
      onChange={(event, newValue) => {
        setUsers(newValue);
        newValue.length >= 1 ? setPlaceholder("") : setPlaceholder("To");
      }}
      options={canMessageOptions}
      getOptionLabel={(option) => option.Name}
      getOptionSelected={(option, value) =>
        option.Id === value.Id || option.Name === value.Name
      }
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={option.Name}
            {...getTagProps({ index })}
            disabled={fixedOptions.indexOf(option) !== -1}
          />
        ))
      }
      groupBy={(option) => option.Type}
      style={{ width: "auto", borderRadius: "0" }}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" placeholder={placeholder} />
      )}
    />
  );
}

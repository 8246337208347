import React from "react";
import { EditorState, ContentState, convertToRaw, Modifier } from "draft-js";
import "draft-js/dist/Draft.css";
import { makeStyles } from "@material-ui/core";
import { Box, IconButton, Divider, Tooltip } from "@mui/material";
import Editor from "@draft-js-plugins/editor";
import "@draft-js-plugins/mention/lib/plugin.css";
import createMentionPlugin from "@draft-js-plugins/mention";
import createToolbarPlugin from "@draft-js-plugins/static-toolbar";
import Mentions from "./components/Mentions";
import { Cancel } from "@material-ui/icons";
import "@draft-js-plugins/static-toolbar/lib/plugin.css";
import Toolbar from "./components/Toolbar";
import UploadedFiles from "../file-upload/UploadedFiles";
import EmojiPicker from "emoji-picker-react";
import { addSnackbarIssue } from "../../components/common/StoreCommon";
import { getNewFeedPostDefaultTextFromStore } from "../../components/common/StoreFeedUi";
import MediaHelper from "../../helpers/MediaHelper";
import { getUrlExtension } from "../../components/common/Common";

export default function MyEditor({
  onSubmit,
  disableMentions,
  toolbarPosition,
  placeholder,
  resetError,
  sendButtonPosition,
  showInsertImage,
  loading,
  editorHeight,
  defaultText,
}) {
  const classes = useStyles();
  // we use a ref on the editor to be able to focus to it when a user click around the text area.
  const editorRef = React.createRef();

  var openingText = defaultText ? defaultText : "";
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createWithContent(ContentState.createFromText(openingText))
  );

  const [emojiIsVisible, setEmojiIsVisible] = React.useState(false);
  const onEmojiClick = (event, emojiObject) => {
    sendTextToEditor(emojiObject.emoji);
    setEmojiIsVisible(false);
  };

  const insertText = (text, editorValue) => {
    const currentContent = editorValue.getCurrentContent();
    const currentSelection = editorValue.getSelection();

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      text
    );

    const newEditorState = EditorState.push(
      editorValue,
      newContent,
      "insert-characters"
    );
    return EditorState.forceSelection(
      newEditorState,
      newContent.getSelectionAfter()
    );
  };

  const sendTextToEditor = (text) => {
    setEditorState(insertText(text, editorState));
  };

  // uploaded files
  const [files, setFiles] = React.useState([]);

  // cover image
  const [coverMediaUrl, setCoverMediaUrl] = React.useState(null);

  // plugins
  const [mentionPlugin] = React.useState(createMentionPlugin());
  const [toolbarPlugin] = React.useState(createToolbarPlugin());
  const plugins = [mentionPlugin, toolbarPlugin];

  // helpers
  const mediaHelper = new MediaHelper();

  // data extraction functions
  const extractData = () => {
    const contentState = editorState.getCurrentContent();
    let raw = convertToRaw(contentState);
    let data = { blocks: [] };

    // remove whitespace
    for (let x = 0; x < raw.blocks.length; x++) {
      if (raw["blocks"][x].text !== "") {
        data.blocks.push(raw["blocks"][x]);
      } else {
        // if current block is a new line
        if (raw["blocks"][x + 1] && raw["blocks"][x + 1].text !== "")
          if (data.blocks[0]) {
            data.blocks.push(raw["blocks"][x]);
          }
      }
    }
    // return raw
    return data;
  };

  // delete attachments
  const removeAttachment = (id, name) => {
    setFiles(files.filter((file) => file.id !== id && file.name !== name));
  };

  const extractMentions = () => {
    const contentState = editorState.getCurrentContent();
    const raw = convertToRaw(contentState);
    const mentionedUsers = [];
    for (let key in raw.entityMap) {
      const ent = raw.entityMap[key];
      if (ent.type === "mention") mentionedUsers.push(ent.data.mention.Id);
    }

    return mentionedUsers;
  };

  const sendData = () => {
    if (!areAttachmentsValid()) {
      return;
    }

    const data = extractData();
    const mentionedUsers = extractMentions();
    const attachments = extractAttachmentUris();

    // onSubmit should return a promise so the editor is only reset when successful
    onSubmit(parseDataForServer(data), mentionedUsers, attachments)
      .then(() => {
        console.log("onSubmit returned succesfully");
        setCoverMediaUrl(null);
        setFiles([]);
        setEditorState(() => EditorState.createEmpty());
      })
      .catch(() => {
        console.log("onSubmit failed");
        // do nothing - onSubmit should handle errors UI wise
      });
  };

  const areAttachmentsValid = () => {
    let error = false;
    files.forEach((file) => {
      // Only check the file if we have not already found an error so only one snackbar issue is raised
      if (error === false && file.progress !== 100) {
        if (file.isError === true) {
          addSnackbarIssue(
            "Attachment failed to upload, please remove and resend",
            "warning"
          );
        } else {
          addSnackbarIssue("Attachment upload in progress", "warning");
        }
        error = true;
      }
    });

    return !error;
  };

  const extractAttachmentUris = () => {
    const attachments = [];

    files.forEach((file) => {
      if (file.progress === 100) {
        attachments.push({ Uri: file.uri, FileName: file[0].name });
      }
    });

    return attachments;
  };

  const parseDataForServer = (data) => {
    const Blocks = [];
    data.blocks.forEach((block) => {
      let BlockValue = block.text;
      let BlockType = block.type === "unstyled" ? "Text" : block.type;
      if (BlockType === "header-one") BlockType = "Heading1";
      if (BlockValue === "") BlockValue = "--newline--";
      Blocks.push({ BlockValue, BlockType });
    });

    if (!Blocks[0]) {
      let BlockValue = "--newline--";
      let BlockType = "Text";
      Blocks.push({ BlockValue, BlockType });
    }

    if (coverMediaUrl) {
      let blockType = null;
      if (mediaHelper.isSupportedImageExtension(getUrlExtension(coverMediaUrl)))
        blockType = "Image";
      else if (
        mediaHelper.isSupportedVideoExtension(getUrlExtension(coverMediaUrl))
      )
        blockType = "Video";
      else throw new Error("Invalid cover media type: " + coverMediaUrl);
      Blocks.push({ BlockValue: coverMediaUrl, BlockType: blockType });
    }

    return JSON.stringify({ Blocks });
  };

  const toolbar = (
    <Toolbar
      toolbarPlugin={toolbarPlugin}
      files={files}
      setFiles={setFiles}
      setCoverMediaUrl={setCoverMediaUrl}
      showInsertImage={showInsertImage}
      sendData={sendData}
      setEmojiIsVisible={setEmojiIsVisible}
      emojiIsVisible={emojiIsVisible}
      loading={loading}
    />
  );

  return (
    <>
      {toolbarPosition === "top" ? toolbar : null}
      <Box style={{ height: "100%" }}>
        <Box
          className={classes.editorContainer}
          style={{
            height: sendButtonPosition === "bottom" ? "100%" : null,
          }}
        >
          <Box
            style={editorHeight && { height: editorHeight }}
            className={classes.editor}
            onClick={() => editorRef.current.focus()}
          >
            <Editor
              editorState={editorState}
              onChange={setEditorState}
              onFocus={() => (resetError ? resetError("") : null)}
              plugins={plugins}
              placeholder={placeholder ? placeholder : "Aa"}
              ref={editorRef}
              spellCheck={true}
              style={{ overflowY: "scroll" }}
            />
            {disableMentions ? null : (
              <Mentions mentionPlugin={mentionPlugin} />
            )}
            {coverMediaUrl ? (
              <Box
                style={{
                  textAlign: "center",
                  width: "100%",
                  height: 250,
                  marginTop: 10,
                  marginBottom: 10,
                  position: "relative",
                }}
                onMouseEnter={() =>
                  (document.getElementById(
                    "deleteCoverMediaBtn"
                  ).style.display = "block")
                }
                onMouseLeave={() =>
                  (document.getElementById(
                    "deleteCoverMediaBtn"
                  ).style.display = "none")
                }
              >
                <Divider style={{ marginBottom: 10 }} />
                <Tooltip title="Delete Cover Media">
                  <IconButton
                    style={{
                      color: "#cd0000",
                      position: "absolute",
                      top: 20,
                      right: 20,
                      display: "none",
                    }}
                    id="deleteCoverMediaBtn"
                    onClick={() => setCoverMediaUrl(null)}
                  >
                    <Cancel />
                  </IconButton>
                </Tooltip>

                <img
                  style={{
                    width: "auto",
                    height: "100%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  src={coverMediaUrl}
                  alt="coverimage"
                />
              </Box>
            ) : null}
          </Box>
        </Box>
        {toolbarPosition === "bottom" ? toolbar : null}

        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              overflowX: "scroll",
            }}
          >
            {files.map((file) => {
              return (
                <UploadedFiles
                  key={`uploadedFiles-${file.id}`}
                  id={file.id}
                  progress={file.progress}
                  name={file[0].name}
                  removeAttachment={removeAttachment}
                  errorUploading={file.isError}
                />
              );
            })}
          </Box>
        </Box>
      </Box>

      <EmojiPicker
        pickerStyle={{
          position: "absolute",
          bottom: "8%",
          right: "20px",
          display: emojiIsVisible ? "block" : "none",
          zIndex: 2,
          overflow: "scroll",
        }}
        groupVisibility={{
          flags: false,
        }}
        onEmojiClick={onEmojiClick}
      />
    </>
  );
}

const useStyles = makeStyles(() => ({
  editor: {
    borderRadius: 5,
    height: "auto",
    width: "100%",
    color: "black",
    padding: "15px 5px",
    background: "#eee",
    overflowY: "scroll",
    maxHeight: 400,
    fontFamily: "Open Sans",
  },
  editorContainer: {
    display: "flex",
    flexDirection: "row",
    margin: "8px 5px",
  },
  inlineStylesButton: {
    width: 20,
    height: 20,
  },
  inlineStylesButtonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    padding: "0px 10px",
    marginTop: 10,
  },
}));

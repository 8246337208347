import React from 'react'
import { useSelector } from "react-redux";
import { Divider, Box, Typography, makeStyles } from '@material-ui/core';
import Icon from '@material-ui/core/Icon'
import { getColorStyle } from '../../../components/common/Common';
import { getMetric } from "../../../store/hub/conversionMetrics";

export default function UserStats ({ userId, metric1IdAndValue, metric2IdAndValue }) {
  
  const classes = useStyles();

  const metric1 = useSelector((state) => getMetric(state.entities.conversionMetrics, metric1IdAndValue.id));
  let metric2Id = null;
  if (metric2IdAndValue) {
    metric2Id = metric2IdAndValue.id;
  }
  const metric2 = useSelector((state) => getMetric(state.entities.conversionMetrics, metric2Id));

  return (
    
    <>
     
      <Divider style={{ width: "80%", marginLeft: "10%" }} />
      <Box className={classes.userStats}>

        {metric1 && (
          <Box className={classes.stats} key={`user-metric-${userId}-${metric1.code}`}>
            <Icon className={classes.icon} style={getColorStyle(metric1.color)}>{metric1.icon}</Icon>
            <Typography variant="caption">{metric1IdAndValue.value}</Typography>
          </Box>
          )}

        {metric2 && (
          <Box className={classes.stats} key={`user-metric-${userId}-${metric2.code}`}>
            <Icon className={classes.icon} style={getColorStyle(metric2.color)}>{metric2.icon}</Icon>
            <Typography variant="caption">{metric2IdAndValue.value}</Typography>
          </Box>
        )}
      </Box>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  userStats: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  stats: {
    display: "inherit",
    flexDirection: "column",
    justifyContent: "space-evenly",
    textAlign: "center",
  },
  icon: {
    marginLeft: "auto",
    marginRight: "auto",
  },
}));
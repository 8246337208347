// creates a dropdown menu to select a user.
// requires the parent component to pass in an update function to
// send the selected user id back up.

import React from "react";
import { useSelectUser } from "../../hooks/useSelectUser";
import { TextField, FormControl } from "@mui/material";
import { Autocomplete } from "@material-ui/lab";

export const UserDropdown = ({ setSelectedUser }) => {
  const { users, handleSelectUser, selectedUser } = useSelectUser();

  React.useEffect(() => {
    setSelectedUser(selectedUser);
  }, [selectedUser, setSelectedUser]);

  if (users) {
    return (
      <FormControl fullWidth>
        <Autocomplete
          id={`userSelectAutocomplete`}
          options={users}
          getOptionLabel={(option) => option.FullName}
          onChange={(event, value) => handleSelectUser(value)}
          renderInput={(params) => (
            <TextField {...params} label="Select User" />
          )}
        />
      </FormControl>
    );
  }

  return null;
};

import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import { useSelector } from "react-redux";
import Thread from "./components/Thread";
import { getSelectedThreadId } from "../../store/ui/messageCentre";
import ExpandedThread from "./components/ExpandedThread";
import { updateNewMessageUi } from "../../components/common/StoreCommon";
import { getMessageThreads } from "../../store/hub/message";
import NewMessage from "./components/NewMessage";
import { getUsersNamesByIds } from "../../store/hub/users";
import { getTeamsNamesByIds } from "../../store/hub/teams";
import Header from "../sidebar/components/Header";

export default function MessageCentre() {

  MessageCentre.whyDidYouRender = {
    logOnDifferentValues: false,
    customName: 'MessageCentre'
  }

  let threads = useSelector(getMessageThreads)();
  const [searchParam, setSearchParam] = useState(null);
  const getUsersList = useSelector(getUsersNamesByIds);
  const getTeamsList = useSelector(getTeamsNamesByIds);

  const searchThreads = (queryText) => {
    setSearchParam(queryText);
  };

  let selectedThreadId = useSelector(getSelectedThreadId)();
  // When leaving a thread it is possible the thread is removed before the selectedThreadId is updated
  // To display a thread we check both the id and object is present
  let selectedThread = threads.filter((thread) => thread.Id === selectedThreadId)[0];

  return (
    <>
      {selectedThreadId && selectedThread ? (
        <ExpandedThread
          thread={selectedThread}
        />
      ) : (
        <>
          <NewMessage />
          <Header
            headerText="Inbox"
            includeIcon={true}
            iconTooltip="New Message"
            icon={<CreateIcon />}
            iconOnClick={() => updateNewMessageUi()}
            includeSearchBox={true}
            searchBoxFunction={(queryText) => searchThreads(queryText)}
          />
          <Grid
            container
            spacing={0}
            style={{ overflowY: "scroll", height: "auto" }}
          >
            {threads.length >= 1 &&
              threads
                .slice()
                .sort(
                  (a, b) =>
                    new Date(b.LatestMessage.UtcCreatedDateTime) -
                    new Date(a.LatestMessage.UtcCreatedDateTime)
                )
                .map((thread) => {
                  let userNameSearchParam = "";
                  if (thread.UserIds.length >= 1)
                    userNameSearchParam = getUsersList(thread.UserIds);
                  let teamNameSearchParam = "";
                  if (thread.TeamIds.length >= 1)
                    teamNameSearchParam = getTeamsList(thread.TeamIds);

                  if (
                    (searchParam &&
                      thread.DisplayName &&
                      thread.DisplayName.toLowerCase().includes(
                        searchParam.toLowerCase()
                      )) ||
                    (searchParam &&
                      userNameSearchParam &&
                      userNameSearchParam
                        .toLowerCase()
                        .includes(searchParam.toLowerCase())) ||
                    (searchParam &&
                      teamNameSearchParam &&
                      teamNameSearchParam
                        .toLowerCase()
                        .includes(searchParam.toLowerCase())) ||
                    !searchParam
                  )
                    if (thread.Display)
                      return (
                        <Thread
                          thread={thread}
                          key={"messageThread" + thread.Id}
                        />
                      );

                  return null;
                })}
          </Grid>
        </>
      )}
    </>
  );
}

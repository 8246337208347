import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Grid, makeStyles } from "@material-ui/core";
import BarChartIcon from "@material-ui/icons/BarChart";

import Header from "../header";
import ConversionWidgets from "./components/ConversionWidgets.js";
import { getReportUserId } from "../../store/ui/report";
import UserAutocomplete from "./components/UserAutocomplete";
import UserStatusReport from "./components/UserStatusReport";
import { getUserById } from "../../store/hub/users";
import DatePicker from "./components/DatePicker";
import {
  updateMainView
} from "../../components/common/StoreCommon";

const actions = [
  {
    icon: <BarChartIcon style={{ color: "rgb(185 181 181)" }} />,
    name: "User Stats",
    selected: false,
    onClick: () => updateMainView("Team Overview"),
  },
];

export default function Stats() {
  const classes = useStyles();
  const reportUserId = useSelector(getReportUserId)();
  const reportUser = useSelector(getUserById)(reportUserId);
  const [compareUserIds, setCompareUserIds] = useState([reportUser]);
  const [date, setDate] = useState([new Date(), null]);

  const additionalHeaderHtml = () => {
    return (
      <Box style={{ display: "flex", flexDirection: "row", marginLeft: 20 }}>
        <UserAutocomplete
          compareUserIds={compareUserIds}
          setCompareUserIds={setCompareUserIds}
        />
        <DatePicker value={date} setValue={setDate} />
      </Box>
    );
  };

  return (
    <>
      <Grid className={classes.header}>  
        <Header actions={actions} additional={additionalHeaderHtml} />
        <Grid container className={classes.root}>
          {compareUserIds && compareUserIds.length > 1 ? (
            compareUserIds.map((user) => (
              <UserStatusReport key={user.Id} user={user} date={date} />
            ))
          ) : (
            <ConversionWidgets
              user={
                compareUserIds && compareUserIds.length >= 1
                  ? compareUserIds[0]
                  : reportUser
              }
              date={date}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  header: {
    marginTop: 15,
  },
  root: {
    display: "flex",
    flexGrow: 1,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
    minHeight: "70vh",
  },
}));

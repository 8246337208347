import {
  Box,
  Divider,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ArrowDownward, FileCopy } from "@material-ui/icons";
import React from "react";

export default function Attachments({ files, dividerPlacement, align }) {
  const classes = useStyles();
  return (
    <>
      {dividerPlacement === "top" ? (
        <Divider className={classes.divider} />
      ) : null}

      <Box className={classes.container}>
        {files.map((file) => {
          return (
            <Box
              style={{
                justifyContent:
                  align && align === "right"
                    ? "end"
                    : align && align === "center"
                    ? "space-between"
                    : "start",
              }}
              className={classes.fileContainer}
              key={"attachment-" + file.Filename}
            >
              <Box style={{ display: "flex", alignItems: "center" }}>
                <FileCopy />
                <Tooltip title={file.Filename}>
                  <Typography variant="caption" className={classes.fileName}>
                    {file.Filename}
                  </Typography>
                </Tooltip>
              </Box>

              <Tooltip title="Download file">
                <IconButton
                  download
                  href={file.Uri}
                  className={classes.downloadButton}
                >
                  <ArrowDownward style={{ fontSize: 20 }} />
                </IconButton>
              </Tooltip>
            </Box>
          );
        })}
      </Box>
      {dividerPlacement === "bottom" ? (
        <Divider className={classes.divider} />
      ) : null}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    margin: "10px 0px",
  },
  fileContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%`",
    height: 30,
    padding: "0px 0",
    margin: "5px 0px",
  },
  divider: {
    margin: theme.spacing(1),
  },
  fileName: {
    marginTop: "auto",
    marginBottom: "auto",
    marginLeft: 5,
    marginRight: 10,
    overflow: "hidden",
    maxWidth: 230,
  },
  downloadButton: {
    width: 10,
    height: "auto",
    color: "#1976d2",
  },
}));

import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "userContacts",
  initialState: { users: [] },
  reducers: {
    
    // we do this all in one reducer to avoid state updates
    userContactsInitialised: (state, action) => {
      action.payload.contacts.forEach(contact => {
        
        let text = contact.ContactValue;
        if (contact.Label) {
          text += " (" + contact.Label + ")";
        }

        const user = state.users.find(o => o.id === contact.UserId);
        if (!user) {
          state.users.push( {id: contact.UserId, contacts: [ {text} ]} );
        }
        else {
          const existingText = user.contacts.find(o => o.text === text);
          if (!existingText) {
            user.contacts.push( {text: text} );
          }
        }

      });
    },

  },
});

export const { userContactsInitialised } = slice.actions;
export default slice.reducer;

export const getUserContacts = (state, userId) => {

  const user = state.users.find(user => user.id === userId);
  let contacts = [];

  if (!user) {
    contacts.push( {text: "No contact details available"} );
  }
  else {
    contacts = user.contacts;
  }
  
  return contacts;
  
}

import React from "react";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Collapse,
  Box,
  LinearProgress,
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { getStatusIcon } from "../../../components/common/Common";

export default function SummaryReport({ report, loaded }) {
  const [rows, setRows] = React.useState([]);
  const [storedReport, setStoredReport] = React.useState(null);

  React.useEffect(() => {
    if (report !== storedReport) {
      addRow(report);
      setStoredReport(report);
    }
  }, [report, storedReport, setStoredReport]);

  const addRow = (json) => {
    let rows = [];
    json.SummaryItems.forEach((item) => {
      rows.push(
        createData(item.Task, item.TaskIcon, item.Duration, item.Count, item)
      );
    });
    setRows(rows);
  };

  return (
    <>
      {loaded ? (
        <TableContainer component={Paper} style={{ maxHeight: 400 }}>
          <Table aria-label="Status Report">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Status</TableCell>
                <TableCell align="right">Duration</TableCell>
                <TableCell align="center">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice()
                .sort((a, b) => a.taskIcon - b.taskIcon)
                .map((row) => (
                  <Row key={row.taskIcon} row={row} />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <LinearProgress color="secondary" />
      )}
    </>
  );
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(row.startsExpanded);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          {row.expandedData ? (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {row.open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          ) : null}
        </TableCell>
        <TableCell component="th" scope="row">
          <Tooltip title={row.task.toString()}>
            {getStatusIcon(row.taskIcon)}
          </Tooltip>
        </TableCell>
        <TableCell align="right">{row.duration}</TableCell>
        <TableCell align="center">{row.total}</TableCell>
      </TableRow>
      {row.expandedData ? (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="expanded data">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right">Status</TableCell>
                      <TableCell align="right">Duration</TableCell>
                      <TableCell align="center">Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.expandedData.map((data) => {
                      return (
                        <TableRow key={data.level}>
                          <TableCell align="right" component="th" scope="row">
                            {getStatusIcon(row.taskIcon, data.level)}
                          </TableCell>
                          <TableCell align="right">{data.duration}</TableCell>
                          <TableCell align="center">{data.total}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </React.Fragment>
  );
}

function createData(task, taskIcon, duration, total, additionalInfo) {
  let expandedData = false;
  if (additionalInfo.AllowExpand) {
    expandedData = [
      {
        level: 0,
        duration: additionalInfo.GreenDuration,
        total: additionalInfo.GreenCount,
      },
      {
        level: 1,
        duration: additionalInfo.AmberDuration,
        total: additionalInfo.AmberCount,
      },
      {
        level: 2,
        duration: additionalInfo.RedDuration,
        total: additionalInfo.RedCount,
      },
    ];
  }
  let content = {
    task,
    taskIcon,
    duration,
    total,
    expandedData,
    startsExpanded: additionalInfo.AutoExpand,
  };
  return content;
}

import React from "react";
import { defaultSuggestionsFilter } from "@draft-js-plugins/mention";
import { useSelector } from "react-redux";
import { getUsersForMentions } from "../../../store/hub/users";
export default function Mentions({ mentionPlugin }) {
  const mentions = useSelector(getUsersForMentions)();
  const [suggestions, setSuggestions] = React.useState(mentions);
  const [open, setOpen] = React.useState(false);
  const { MentionSuggestions } = mentionPlugin;

  const onSearchChange = ({ value }) => {
    setSuggestions(defaultSuggestionsFilter(value, mentions));
  };

  const onOpenChange = (_open) => {
    setOpen(_open);
  };

  return (
    <MentionSuggestions
      onSearchChange={onSearchChange}
      suggestions={suggestions}
      open={open}
      onOpenChange={onOpenChange}
    />
  );
}

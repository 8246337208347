import { getStore } from "../common/StoreCommon";
import {
  allNotificationsSetToRead,
  clearNotifications,
  getUnreadNotifications,
  updateNotifications,
  notificationExists
} from "../../store/ui/notifications";


export function updateNotificationInStore(notification) {
  getStore().dispatch(updateNotifications(notification));

}

export function updateNotificationForThreadAsReadInStore(threadId) {
  const notificationId = `NewMessage-${threadId}`;
  if (getNotificationExistsFromStore(notificationId) === true) {
    updateNotificationInStore({ Id: notificationId, Read: true });
  }
}

export function updateAllNotificationsToReadInStore() {
  getStore().dispatch(allNotificationsSetToRead());
}

export function clearAllNotificationsInStore() {
  getStore().dispatch(clearNotifications());
}

//export function getNotificationNumberFromStore() {
//  const notifications =  getUnreadNotifications(getStore().getState())();
//  return notifications.length;
//}

export function getNotificationExistsFromStore(notificationId) {
  return notificationExists(getStore().getState())(notificationId);
}

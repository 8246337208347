import React from "react";

import UploadService from "./services/upload-files.service";
import { AttachFile } from "@material-ui/icons";
import { IconButton } from "@mui/material";

export default function UploadFiles({ files, setFiles }) {
  const chooseFile = (e) => {
    const chosenFiles = e.target.files;
    if (chosenFiles.length > 0) {
      setFiles([...files, { ...files, ...{ ...chosenFiles } }]);
      uploadFiles([...files, { ...files, ...{ ...chosenFiles } }]);
    }
  };

  const uploadFiles = (chosenFiles) => {
    // only upload the file if it hasn't been attempted yet.
    // progress would only exist if the file has started uploading.
    chosenFiles.forEach((file) => {
      if (!file.progress) {
        // add progress into a existing file array
        setFiles([...files, { ...file, ...{ progress: 0, id: Date.now() } }]);

        // upload File
        UploadService.upload(file[0], (event) => {
          // update progress
          if (file.progress !== 100)
            // check if file.progress = 100 if so the file has been uploaded.
            // no need to reset progress again.
            setFiles([
              ...files,
              {
                ...file,
                ...{ progress: Math.round((100 * event.loaded) / event.total) },
              },
            ]);
        })
          .then((response) => {
            // if successful update stored files array to display completion
            setFiles([
              ...files,
              {
                ...file,
                ...{
                  uri: response.data.Uri,
                  isError: false,
                  progress: 100,
                  id: Date.now(),
                },
              },
            ]);
          })
          .catch(() => {
            // on error, reset progress and set isError: true to display to
            // users
            setFiles([
              ...files,
              { ...file, ...{ progress: 0, isError: true, id: Date.now() } },
            ]);
          });
      }
    });
  };

  return (
    <label htmlFor="btn-upload">
      <input
        id="btn-upload"
        name="btn-upload"
        style={{ display: "none" }}
        type="file"
        onChange={chooseFile}
      />
      <IconButton
        style={{ width: 36, height: 34, verticalAlign: "unset" }}
        component="span"
      >
        <AttachFile style={{ color: "grey", fontSize: 22 }} />
      </IconButton>
    </label>
  );
}

import NotificationService from "../services/NotificationService";

export default class BrowserHelper {
  constructor() {
    this.state = {
      defaultTitle: "BuzzHubs",
      title: null,
      isFlashing: false,
    };
  }

  _constructTitle = () => {
    const title = this._getStoredTitle();
    const notifictionService = new NotificationService();
    const notificationNumber = notifictionService.notificationCount();

    // default the constructed title to the stored title
    let constructedTitle = title;

    // if there is a notification number then add it to the title
    if (notificationNumber > 0)
      constructedTitle = `(${notificationNumber}) ${title}`;

    // set the title
    this._setTitle(constructedTitle);
  };

  _setTitle = (title) => {
    document.title = title;
  };

  _getStoredTitle = () => {
    if (this.state.title) return this.state.title;
    else return this.state.defaultTitle;
  };

  _flashTitle = () => {
    const that = this;
    const isCurrentlyFlashing = this.state.isFlashing;
    const storedTitle = this.state.title;

    // if is currently flashing, return to stop flashing overlaps.
    if (isCurrentlyFlashing) return;

    // if there is no storedTitle then return - can't flash the same thing over again!
    if (!storedTitle) return;

    this._setFlashing(true);

    // start interval
    const interval = setInterval(function () {
      // if the title is null - set it to the storedTitle otherwise set it to null
      that.state.title = that.state.title ? null : storedTitle;

      // clear the interval if the document regains focus.
      if (document.hasFocus()) {
        clearInterval(interval);
        that._resetTitle();
      }

      // reconsctruct the title
      that._constructTitle();
    }, 1000);
  };

  _setFlashing = (isFlashing) => {
    this.state.isFlashing = isFlashing;
  };

  _resetTitle = () => {
    this.state.title = null;
    this.state.isFlashing = false;
  };

  updateBrowserTitle = (title, flashTitle) => {
    // the title can be null or undefined - calling updateBrowserTitle also updates
    // the notification number.
    if (title) this.state.title = title;

    if (flashTitle) if (!document.hasFocus()) this._flashTitle();

    this._constructTitle();
  };
}

import React from "react";

export const useMenu = () => {
  const [anchor, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return { anchor, handleClick, handleClose };
};

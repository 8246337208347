import React, { useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useSelector } from "react-redux";

import { 
  getCanMessageUsers 
} from "../../../../store/hub/canMessageUsers";
import {
  updateUserSettingsUserIdInStore,
}
from "../../../../components/common/StoreSettingsUi";
import {
  getUserSettingsUserId,
}
from "../../../../store/ui/settings";

export default function UserAutoComplete() {

  let canMessageUsers = useSelector(getCanMessageUsers)();
  let userId = useSelector(getUserSettingsUserId)();

  const userOptions = (canMessageUsers) => {
    let options = [];
    canMessageUsers.forEach(item => {
      if (item.Name != 'All') {
        options.push( { label: item.Name, id: item.Id } );    
      }
    });
    return options; 
  };

  function setUserId(newValue) {
    if (newValue === null) {
      updateUserSettingsUserIdInStore(null);
    }
    else {
      updateUserSettingsUserIdInStore(newValue.id);
    }
  }

  return (
    <Autocomplete
      id="user-combo-box"
      options={userOptions(canMessageUsers)}
      onChange={(event, newValue) => {
        setUserId(newValue);
      }}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(option, value) =>
        option.Id === value.Id
      }
      renderInput={(params) => (
        <TextField {...params} 
        variant="outlined" 
        placeholder="User"
        />
      )}
      size="small"
      style={{ width: "auto", borderRadius: "0" }}
    />    
  );
}
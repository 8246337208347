import { getStore } from "../components/common/StoreCommon";
import CookieHelper from "../core/CookieHelper";
import NotificationHelper from "../core/NotificationHelper";
import BrowserHelper from "../core/BrowserHelper";

import {
  allNotificationsSetToRead,
  clearNotifications,
  getUnreadNotifications,
  updateNotifications,
  notificationExists
} from "../store/ui/notifications";
import {
  snackbarIssueAdded,
  uniqueSnackbarIssueAdded,
  snackbarIssueByTextActioned
} from "../store/ui/snackbar";
import { 
  addMinutes,
} from "../components/common/Common";
import UserService from "./UserService";
import PermissionService from "./PermissionService";
import ThreadHelper from "../core/ThreadHelper";

export default class NotificationService {
  
  #cookieHelper;
  #notificationHelper;
  #browserHelper;
  #userService;
  #permissionService;
  #threadHelper;
  #updateStatusReminderText;
  
  constructor() {
    this.#cookieHelper = new CookieHelper();
    this.#notificationHelper = new NotificationHelper();
    this.#browserHelper = new BrowserHelper();
    this.#userService = new UserService();
    this.#permissionService = new PermissionService();
    this.#threadHelper = new ThreadHelper();
    this.#updateStatusReminderText = "Remember to update your status!";
  }

  markAllAsRead() {
    getStore().dispatch(allNotificationsSetToRead());
  }

  markAsRead(id) {
    getStore().dispatch(updateNotifications({ Id: id, Read: true }));
  }

  clearAll() {
    getStore().dispatch(clearNotifications());
  }

  mute(timeInMinutes) {
    this.#cookieHelper.setCookie("MN", true, {
      expires: addMinutes(new Date(), timeInMinutes),
    });
  }

  unmute() {
    this.#cookieHelper.removeCookie("MN");
  }

  isMuted() {
    return this.#cookieHelper.doesCookieExist("MN");
  }

  notificationCount() {
    const notifications =  getUnreadNotifications(getStore().getState())();
    return notifications.length;
  }

  createNotificationForNewFeedThread(feedThread, loggedInUserId, openFeedThread) {

    this._createNewNotification(
      feedThread.CreatedUserId,
      loggedInUserId,
      "FeedThread-" + feedThread.Id,
      this._getNewFeedThreadTitle(feedThread),
      this._getNewFeedThreadContent(feedThread),
      () => openFeedThread(feedThread.Id),
      feedThread.PostMessage.UtcCreatedDateTime
    );

    const playConversionSound = this.#permissionService.playConversionSound();

    if (playConversionSound === true && feedThread.PlaySound === true && feedThread.SoundFile) {
      this.#notificationHelper.playNotificationSound(feedThread.SoundFile);
    }
    
  }

  createNotificationForLikedFeedThread(feedThread, loggedInUserId, likedByUserId, tagged, openFeedThread) {

    this._createNewNotification(
      likedByUserId,
      loggedInUserId,
      "FeedThread-" + feedThread.Id,
      this._getLikedFeedThreadTitle(likedByUserId, tagged),
      this.#threadHelper.getPostPreviewText(feedThread.PostMessage.JsonContent),
      () => openFeedThread(feedThread.Id),
      feedThread.PostMessage.UtcCreatedDateTime
    );
 
  }
  
  createNotificationForFeedThreadComment(threadMessage, loggedInUserId,  action) {

    this._createNewNotification(
      threadMessage.UserId,
      loggedInUserId,
      "FeedThread-" + threadMessage.ThreadId,
      this._getFeedThreadCommentTitle(threadMessage.UserId),
      this.#threadHelper.getPostPreviewText(threadMessage.JsonContent),
      action,
      threadMessage.UtcCreatedDateTime
    );
 
  }

  createNotificationForNewDirectMessageThread(userId, loggedInUserId, threadId, threadContent, action, createdDateTime) {

    this._createNewNotification (
      userId,
      loggedInUserId,
      "NewMessage-" + threadId,
      this._getNewDirectMessageThreadTitle(userId),
      threadContent,
      action,
      createdDateTime
    );
  
  }

  showBrowserNotification(title, message) {
    this.#notificationHelper.showBrowserNotification(title, message);
  }

  addEnableNotificationsMeessage() {
    this._addNotificationToStore({
      Id: "browser-notifications-denied",
      UserId: null,
      Text: "Notifications are disabled. BuzzHubs works best with notifications enabled. Click here to find out how to enable them.",
      DateTimeReceived: new Date().toISOString(),
      Read: false,
      Action: () =>
        window.open(
          "https://www.google.com/search?q=enable+browser+notifications"
        ),
      FullHeight: true,
    });
  }

  showSnackbarIssue(text, severity, duration, large) {
    getStore().dispatch(snackbarIssueAdded({ text, severity, duration, large }));
  }

  showSnackbarIssueIfDoesNotExist(text, severity, duration, large) {
    getStore().dispatch(uniqueSnackbarIssueAdded({ text, severity, duration, large }));
  }

  showUpdateStatusReminder() {
    this.showSnackbarIssueIfDoesNotExist(this.#updateStatusReminderText, "warning", 0, true);
    this._handleBrowserNotification("Reminder", this.#updateStatusReminderText);
  }

  removeUpdateStatusReminder() {
    const text = this.#updateStatusReminderText;
    getStore().dispatch(snackbarIssueByTextActioned({ text }));
  }

  _createNewNotification(
    sentUserId,
    loggedInUserId,
    notificationId,
    title,
    content,
    action,
    createdDateTime
  ) {

    // Do not send notifications by the logged in user to themselves
    // If user level permission is set they do not get notifications at all
    if (sentUserId === loggedInUserId ||
      this.#permissionService.muteNotifications()) return;
    
    let notification = {
      Id: notificationId,
      UserId: sentUserId,
      Title: title,
      Content: content,
      DateTimeReceived: createdDateTime,
      Read: false,
      Action: action,
    };

    // Add the notification to the store
    this._addNotificationToStore(notification);

    // Handle the browser notification
    this._handleBrowserNotification(title, content);
    
  }

  _handleBrowserNotification(title, content) {

    // if isMuted is set the user gets the visible notification but not the sound
    if (this.isMuted() === true || this._browserNotificationsSupported() === false) return;
     
    // update browser title
    this.#browserHelper.updateBrowserTitle(title, true);

    // show browser notification
    this.#notificationHelper.showBrowserNotification(title, content);

  }

  _browserNotificationsSupported() {
    return ("Notification" in window);
  }

  _getNewFeedThreadTitle(feedThread) {
    if (feedThread.NotificationTitle) return feedThread.NotificationTitle;  
    const createdByUserName = this.#userService.getUserName(feedThread.CreatedUserId);
    return createdByUserName + " has added a new post";
  }

  _getNewFeedThreadContent(feedThread) {
    if (feedThread.NotificationContent) return feedThread.NotificationContent;  
    return this.#threadHelper.getPostPreviewText(feedThread.PostMessage.JsonContent);
  }

  _getLikedFeedThreadTitle(likedByUserId, tagged) {
    const likedByUserName = this.#userService.getUserName(likedByUserId);
    if (tagged)
      return likedByUserName + " has liked a post you are tagged in";
    return likedByUserName + " has liked your post";
  }

  _getFeedThreadCommentTitle(commentedUserId) {
    const userName = this.#userService.getUserName(commentedUserId);
    return userName + " has commented on a post you're in";
  }

  _getNewFeedThreadTitle(feedThread) {
    if (feedThread.NotificationTitle) return feedThread.NotificationTitle;  
    const createdByUserName = this.#userService.getUserName(feedThread.CreatedUserId);
    return createdByUserName + " has added a new post";
  }

  _getNewDirectMessageThreadTitle(sentByUserId) {
    const userName = this.#userService.getUserName(sentByUserId);
    return userName + " has sent you a new message";
  }

  _addNotificationToStore(notification) {
    getStore().dispatch(updateNotifications(notification));
  }

  _getState() {
    return getStore().getState();
  }

}

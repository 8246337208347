import React, { useState } from "react";
import { Avatar, Box, makeStyles, Tooltip } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getUserStatusById } from "../../store/hub/userStatus";
import { getStatusIcon } from "../../components/common/Common";
import StatusTooltip from "./components/StatusTooltip";

export default function StatusIcon(props) {
  const classes = useStyles();
  const [time, setTime] = useState();
  const [timerId, setTimerId] = useState();
  const [dailyTime, setDailyTime] = useState();
  const [dailyTimerId, setDailyTimerId] = useState();

  // get the user id from props. subscribe to the store to get the
  //users status using the id
  const userStatusSelector = useSelector(getUserStatusById);
  const userStatus = userStatusSelector(props.userId);

  return (
    <Box
      onMouseEnter={() => {
        primaryTimer(setTimerId, setTime, userStatus.StatusDateTimeUtc);
        if (userStatus.StatusSecondaryDateTimeUtc)
          dailyTimer(
            setDailyTimerId,
            setDailyTime,
            userStatus.StatusSecondaryDateTimeUtc,
            userStatus.StatusSecondarySeconds
          );
      }}
      onMouseLeave={() => {
        if (timerId) {
          clearInterval(timerId);
          if (time) setTime();
        }

        if (dailyTimerId) {
          clearInterval(dailyTimerId);
          setDailyTimerId();
          if (dailyTime) setDailyTime();
        }
      }}
      onClick={props.handleClick}
      className={classes.statusContainer}
    >
      <Tooltip
        title={
          <StatusTooltip
            status={userStatus}
            time={time}
            dailyTime={dailyTime}
            userId={props.userId}
          />
        }
      >
        <Avatar className={classes.status}>
          {getStatusIcon(
            userStatus.StatusIcon,
            userStatus.StatusLevel,
            props.className
          )}
        </Avatar>
      </Tooltip>
    </Box>
  );
}

const primaryTimer = (setTimerId, setTime, statusStartTime) => {
  // get the status start time
  let statusStartTimeUtc = statusStartTime;
  // convert the start time to users local time
  let statusStartTimeLocal = new Date(statusStartTimeUtc);
  // take the local start time and compare current local time to get the total
  // seconds on that status
  let totalSecondsOnStatus = Math.floor(
    (new Date().getTime() - statusStartTimeLocal.getTime()) / 1000
  );
  // create the timer function and set to state to be able to unset timer later.
  let minutes = 0;
  let hours = 0;
  let sec = 0;
  setTimerId(
    setInterval(function () {
      hours = Math.floor(totalSecondsOnStatus / 3600);
      minutes =
        Math.floor(totalSecondsOnStatus / 60) - Math.floor((hours * 3600) / 60);
      sec = totalSecondsOnStatus % 60;
      hours = hours > 0 ? hours + (hours < 2 ? "hr" : "hrs") : "";
      minutes = minutes > 0 ? minutes + "m" : "";
      sec = sec > 0 ? sec + "s" : "";
      setTime(hours + " " + minutes + " " + sec);
      totalSecondsOnStatus++;
    }, 1000)
  );
};

const useStyles = makeStyles((theme) => ({
  statusContainer: {
    padding: theme.spacing(1),
    cursor: "pointer",
  },
  status: {
    [theme.breakpoints.only("xs")]: {
      height: 29,
      width: 29,
    },
    [theme.breakpoints.up("sm")]: {
      height: 32,
      width: 32,
    },
    [theme.breakpoints.only("xl")]: {
      height: 40,
      width: 40,
    },
    marginLeft: "auto",
    background: "white",
    border: "solid 1px #eee",
  },
}));

const dailyTimer = (
  setTimerId,
  setTime,
  statusSecondaryTime,
  statusSecondarySeconds
) => {
  // get the status start time
  let statusStartTimeUtc = statusSecondaryTime;
  // convert the start time to users local time
  let statusStartTimeLocal = new Date(statusStartTimeUtc);
  // take the local start time and compare current local time to get the total
  // seconds on that status + add on current status secondary seconds.
  let totalSecondsOnStatus =
    Math.floor((new Date().getTime() - statusStartTimeLocal.getTime()) / 1000) +
    Math.floor(statusSecondarySeconds);
  // create the timer function and set to state to be able to unset timer later.
  let minutes = 0;
  let hours = 0;
  let sec = 0;
  setTimerId(
    setInterval(function () {
      hours = Math.floor(totalSecondsOnStatus / 3600);
      minutes =
        Math.floor(totalSecondsOnStatus / 60) - Math.floor((hours * 3600) / 60);
      sec = totalSecondsOnStatus % 60;
      hours = hours > 0 ? hours + (hours < 2 ? "hr" : "hrs") : "";
      minutes = minutes > 0 ? minutes + "m" : "";
      sec = sec > 0 ? sec + "s" : "";
      setTime("Total for day: " + hours + " " + minutes + " " + sec);
      totalSecondsOnStatus++;
    }, 1000)
  );
};

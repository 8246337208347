import React from "react";
import { Box, Grid, Tooltip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import {
  getUserNameFromId,
  setSelectedThreadId,
  updateMessageThreadRead,
} from "../../../components/common/StoreCommon";
import {
  updateNotificationForThreadAsReadInStore,
} from "../../../components/common/StoreNotificationsUi";
import { getLoggedInUserId } from "../../../store/hub/loggedInUser";
import {
  convertDateTime,
  IsJsonString,
  removeNewLineFromString,
} from "../../../components/common/Common";
import ThreadAvatar from "./ThreadAvatar";

export default function Thread({ thread }) {

  const classes = useStyles();
  const loggedInUserId = useSelector(getLoggedInUserId)();
  const sentByLoggedInUser = loggedInUserId === thread.LatestMessage.UserId;

  return (
    <Grid
      item
      xs={12}
      key={"messageThread-" + thread.Id}
      onClick={() => openThread(thread.Id)}
    >
      {thread
        ? messageHtml(classes, thread, sentByLoggedInUser)
        : messageSkeleton(classes)}
    </Grid>
  );
}

const openThread = (threadId) => {
  setSelectedThreadId(threadId);
  updateMessageThreadRead(threadId, false);
  updateNotificationForThreadAsReadInStore(threadId);
};

const messageSkeleton = (classes) => {
  return (
    <Box className={classes.messageThread}>
      <Skeleton
        animation="wave"
        variant="circle"
        width={40}
        height={40}
        className={classes.messageThreadImage}
      />
      <Box className={classes.messageThreadText}>
        <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
        <Skeleton animation="wave" height={10} width="80%" />
      </Box>
    </Box>
  );
};

const lastMessagePrefixText = (sentByLoggedInUser, userId) => {
  if (sentByLoggedInUser) return "You: ";
  const userName = getUserNameFromId(userId);
  if (userName) return userName + ": ";
  return null;
}

const messageHtml = (classes, thread, sentByLoggedInUser) => {
  const read = !thread.HasUnreadMessages;
  const latestMessageParsed = IsJsonString(thread.LatestMessage.JsonContent);
  return (
    <Box key={"messageThread-" + thread.Id} className={classes.messageThread}>
      <ThreadAvatar thread={thread} />
      <Box className={classes.messageThreadText}>
        <Box className={classes.threadTitleContainer}>
          <Tooltip title={thread.DisplayName} placement="top-start">
            <Typography
              className={classes.threadTitle}
              variant="body2"
              style={{
                fontWeight: read ? "normal" : "bold",
              }}
            >
              {thread.DisplayName ? thread.DisplayName : "Untitled Message"}
            </Typography>
          </Tooltip>

          <Typography variant="caption" style={{ fontSize: "10px" }}>
            {convertDateTime(thread.LatestMessage.UtcCreatedDateTime)}
          </Typography>
        </Box>

        <Typography
          variant="caption"
          style={{
            color: read ? "rgb(150, 150, 150)" : "rgb(87 177 255)",
          }}
        >
          { lastMessagePrefixText(sentByLoggedInUser, thread.LatestMessage.UserId) }
          {latestMessageParsed
            ? removeNewLineFromString(
                JSON.parse(thread.LatestMessage.JsonContent).Blocks[0]
                  .BlockValue
              )
            : "Unable to parse message"}
        </Typography>
      </Box>
      {!read ? (
        <Box className={classes.unreadIconContainer}>
          <FiberManualRecordIcon className={classes.UnreadIcon} />
        </Box>
      ) : null}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  messageThread: {
    height: "70px",
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "auto",
    },
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderRadius: 10,
    cursor: "pointer",
    "&:hover, &:focus": {
      background: "rgb(250,250,250)",
    },
    position: "relative",
  },
  messageThreadImage: {
    marginTop: "auto",
    marginBottom: "auto",
    width: 50,
    height: 50,
  },
  smallMessageThreadImage: {
    width: 35,
    height: 35,
  },
  messageThreadText: {
    width: "80%",
    paddingLeft: "10px",
    marginTop: "auto",
    marginBottom: "auto",
    overflow: "hidden",
  },
  threadTitle: {
    color: "rgb(85, 85, 85)",
    maxWidth: "70%",
    overflow: "hidden",
  },
  unreadIconContainer: {
    position: "absolute",
    right: 10,
    top: 25,
  },
  UnreadIcon: {
    color: "rgb(87 177 255)",
    height: 20,
    width: 20,
  },
  threadTitleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));

import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import memoize from "lodash.memoize";

const slice = createSlice({
  name: "statuses",
  initialState: [],
  reducers: {
    // actions => action handlers

    availableStatusesAdded: (statuses, action) => {
      // adds a userStatus to the existing status object.
      action.payload.statuses.forEach((statusObj) => {
        const index = statuses.findIndex(
          (status) => status.Code === statusObj.Code
        );
        if (index >= 0) {
          statuses.splice(index, 1, { ...statuses[index], ...statusObj });
        } else {
          statuses.push(statusObj);
        }
      });
    },
  },
});

export const { availableStatusesAdded } = slice.actions;
export default slice.reducer;

export const getStatuses = createSelector(
  (state) => state.entities.statuses,
  (statuses) =>
    memoize(() => {
      return statuses;
    })
);

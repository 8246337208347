import React from "react";
import { Box, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import SummaryReport from "./SummaryReport";
import LineItemReport from "./LineItemReport";

export default function Reports({ report, loaded }) {
  const classes = useStyles();

  return (
    <Grid container spacing={2} justifyContent="space-between">
      <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
        <Box>
          <Typography variant="subtitle1" className={classes.title}>
            Status Report
          </Typography>
          <Paper className={classes.summaryReportPaper}>
            <div style={{ width: "100%", height: "100%" }}>
              <SummaryReport report={report} loaded={loaded} />
            </div>
          </Paper>
        </Box>
      </Grid>
      <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
        <Box className={classes.table}>
          <Typography variant="subtitle1" className={classes.title}>
            Line Report
          </Typography>
          <Paper className={classes.summaryReportPaper}>
            <div style={{ width: "100%", height: "100%" }}>
              <LineItemReport report={report} loaded={loaded} />
            </div>
          </Paper>
        </Box>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  summaryReportPaper: {
    height: 400,
  },
  title: {
    color: "rgb(135, 131, 131)",
  },
}));

import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import memoize from "lodash.memoize";

const slice = createSlice({
  name: "boomFeed",
  initialState: {
    selectedThreadId: null,
    newFeedPostOpen: false,
    newFeedPostDefaultText: null,
    newFeedPostAchievementId: null,
    newFeedPostOriginatingThreadId: null
  },
  reducers: {

    selectedFeedThreadIdUpdated: (boomFeed, action) => {
      return { ...boomFeed, ...action.payload };
    },

    newFeedPostOpenUpdated: (boomFeed, action) => {
      boomFeed.newFeedPostOpen = action.payload.open;
    },

    newFeedPostDefaultTextUpdated: (boomFeed, action) => {
      boomFeed.newFeedPostDefaultText = action.payload.text;
    },

    newFeedPostAchievementIdUpdated: (boomFeed, action) => {
      boomFeed.newFeedPostAchievementId = action.payload.id;
    },

    newFeedPostOriginatingThreadIdUpdated: (boomFeed, action) => {
      boomFeed.newFeedPostOriginatingThreadId = action.payload.id;
    },
  },
});

export const { 
  selectedFeedThreadIdUpdated, 
  newFeedPostOpenUpdated, 
  newFeedPostDefaultTextUpdated, 
  newFeedPostAchievementIdUpdated,
  newFeedPostOriginatingThreadIdUpdated 
} = slice.actions;
export default slice.reducer;

export const getSelectedFeedThreadId = createSelector(
  (state) => state.ui.boomFeed,
  (boomFeed) =>
    memoize(() => {
      return boomFeed.selectedThreadId;
    })
);

export const getNewFeedPostOpen = createSelector(
  (state) => state.ui.boomFeed,
  (boomFeed) =>
    memoize(() => {
      return boomFeed.newFeedPostOpen;
    })
);

export const getNewFeedPostDefaultText = createSelector(
  (state) => state.ui.boomFeed,
  (boomFeed) =>
    memoize(() => {
      return boomFeed.newFeedPostDefaultText;
    })
);

export const getNewFeedPostAchievementId = createSelector(
  (state) => state.ui.boomFeed,
  (boomFeed) =>
    memoize(() => {
      return boomFeed.newFeedPostAchievementId;
    })
);

export const getNewFeedPostOriginatingThreadId = createSelector(
  (state) => state.ui.boomFeed,
  (boomFeed) =>
    memoize(() => {
      return boomFeed.newFeedPostOriginatingThreadId;
    })
);

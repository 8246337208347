import { getStore } from "../common/StoreCommon";
import {
  selectedFeedThreadIdUpdated,
  newFeedPostOpenUpdated,
  getSelectedFeedThreadId,
  getNewFeedPostDefaultText,
  getNewFeedPostAchievementId,
  getNewFeedPostOriginatingThreadId,
  newFeedPostDefaultTextUpdated,
  newFeedPostAchievementIdUpdated,
  newFeedPostOriginatingThreadIdUpdated,
} from "../../store/ui/boomFeed";


export function setNewFeedPostVisibilityInStore(open) {
  // called whenn the New Feed Post modal is opened or closed
  getStore().dispatch(newFeedPostOpenUpdated({ open }));
}

export function setSelectedFeedThreadIdInStore(id) {
  // called when a user opens a thread or closes a thread.
  getStore().dispatch(selectedFeedThreadIdUpdated({ selectedThreadId: id ? id : null }));
}

export function getSelectedFeedThreadIdFromStore() {
  return getSelectedFeedThreadId(getStore().getState())();
}

export function getNewFeedPostDefaultTextFromStore() {
  return getNewFeedPostDefaultText(getStore().getState())();
}

export function getNewFeedPostAchievementIdFromStore() {
  return getNewFeedPostAchievementId(getStore().getState())();
}

export function getNewFeedPostOriginatingThreadIdFromStore() {
  return getNewFeedPostOriginatingThreadId(getStore().getState())();
}

export function resetNewFeedPostDefaultTextInStore() {
  getStore().dispatch(newFeedPostDefaultTextUpdated({ text: null }));
}

export function setNewFeedPostDefaultTextInStore(text) {
  getStore().dispatch(newFeedPostDefaultTextUpdated({ text: text }));
}

export function setNewFeedPostAchievementIdInStore(id) {
  getStore().dispatch(newFeedPostAchievementIdUpdated({ id: id }));
}

export function resetNewFeedPostAchievementIdInStore() {
  getStore().dispatch(newFeedPostAchievementIdUpdated({ id: null }));
}

export function setNewFeedPostOriginatingThreadIdInStore(id) {
  getStore().dispatch(newFeedPostOriginatingThreadIdUpdated({ id: id }));
}

export function resetNewFeedPostOriginatingThreadIdInStore() {
  getStore().dispatch(newFeedPostOriginatingThreadIdUpdated({ id: null }));
}

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Avatar,
  Box,
  Collapse,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  Person,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@material-ui/icons";
import Icon from '@material-ui/core/Icon'
import { getStatusIcon, yyyymmdd, getColorStyle } from "../../../components/common/Common";
import { getUserActivityReport } from "../../../components/common/ApiMiddleware";
import { getMetric } from "../../../store/hub/conversionMetrics";

export default function UserStatusReport({ user, date }) {

  const classes = useStyles();
  const [report, setReport] = useState(null);
  const [rows, setRows] = useState([]);
  const [chosenDate, setChosenDate] = useState(date);
  const [loaded, setLoaded] = useState(false);
  const [conversionRows, setConversionRows] = useState([]);

  useEffect(() => {
    if (!report || date !== chosenDate) {
      if (loaded) setLoaded(false);
      getUserActivityReport(user.Id, yyyymmdd(date[0]), yyyymmdd(date[1]))
        .then((json) => {
          setReport(json);
          addRow(json);
          addConversionData(json);
          setLoaded(true);
        })
        .catch((error) => {
          setLoaded(true);
        });

      setChosenDate(date);
    }
   
  }, [setChosenDate, chosenDate, date, report, user, loaded, setLoaded]);

  const addRow = (json) => {
    let rows = [];
    json.SummaryItems.forEach((item) => {
      rows.push(
        createData(item.Task, item.TaskIcon, item.Duration, item.Count, item)
      );
    });
    setRows(rows);
  };

  const addConversionData = (json) => {
    let rows = [];
    json.ConversionMetrics.forEach((item) => {
      rows.push( { metricId: item.Id, value: item.Value } );
    });
    setConversionRows(rows);
  };

  return (
    <>
      <Grid item style={{ marginLeft: 10, marginRight: 10, marginBottom: 50 }}>
        <Grid key="user-paper" item className={classes.topPaper}>
          <Paper className={classes.userPaper}>
            <Box className={classes.paperHeader}>
              <Avatar
                alt={user.FullName}
                src={user.ImageSrc}
                className={classes.avatarLarge}
              />
              {report ? (
            
                <Box className={classes.headerPaperRight}>
                  {conversionRows
                    .map((row) => (
                      <ConversionRow key={row.metricId} metricId={row.metricId} value={row.value} classes={classes}/>
                    ))}
                  </Box>
              
              ) : null}
            </Box>
            <Divider />
            <Box className={classes.paperFooter}>
              <Person />
              <Typography variant="subtitle1" gutterBottom>
                {user.FullName}
              </Typography>
            </Box>
          </Paper>
        </Grid>
        {loaded ? (
          <TableContainer component={Paper} style={{ maxHeight: "50vh" }}>
            <Table aria-label="Status Report">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Status</TableCell>
                  <TableCell align="right">Duration</TableCell>
                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice()
                  .sort((a, b) => a.taskIcon - b.taskIcon)
                  .map((row) => (
                    <Row key={row.task} row={row} />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <LinearProgress color="secondary" />
        )}
      </Grid>
    </>
  );
}

function ConversionRow(props) {

  const metric = useSelector((state) => getMetric(state.entities.conversionMetrics, props.metricId));

  return (
    <Tooltip title={metric.tooltipText}>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Icon style={getColorStyle(metric.color)}>{metric.icon}</Icon>
        <Box style={{ marginLeft: 10 }}>
          <Typography variant="body1">
            {props.value}
          </Typography>
        </Box>
      </Box>
    </Tooltip>

  );
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(row.startsExpanded);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          {row.expandedData ? (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {row.open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          ) : null}
        </TableCell>
        <TableCell component="th" scope="row">
          <Tooltip title={row.task.toString()}>
            {getStatusIcon(row.taskIcon)}
          </Tooltip>
        </TableCell>
        <TableCell align="right">{row.duration}</TableCell>
        <TableCell align="center">{row.total}</TableCell>
      </TableRow>
      {row.expandedData ? (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="expanded data">
                  <TableHead>
                    <TableRow>
                      <TableCell>Status</TableCell>
                      <TableCell align="right">Duration</TableCell>
                      <TableCell align="right">Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.expandedData.map((data) => {
                      return (
                        <TableRow key={data.level}>
                          <TableCell component="th" scope="row">
                            {getStatusIcon(row.taskIcon, data.level)}
                          </TableCell>
                          <TableCell align="right">{data.duration}</TableCell>
                          <TableCell align="center">{data.total}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </React.Fragment>
  );
}

function createData(task, taskIcon, duration, total, additionalInfo) {
  let expandedData = false;
  if (additionalInfo.AllowExpand) {
    expandedData = [
      {
        level: 0,
        duration: additionalInfo.GreenDuration,
        total: additionalInfo.GreenCount,
      },
      {
        level: 1,
        duration: additionalInfo.AmberDuration,
        total: additionalInfo.AmberCount,
      },
      {
        level: 2,
        duration: additionalInfo.RedDuration,
        total: additionalInfo.RedCount,
      },
    ];
  }
  let content = {
    task,
    taskIcon,
    duration,
    total,
    expandedData,
    startsExpanded: additionalInfo.AutoExpand,
  };
  return content;
}

const useStyles = makeStyles((theme) => ({
  userPaper: {
    height: 100,
    width: "auto",
    padding: theme.spacing(2),
    color: "rgb(135, 131, 131)",
  },
  control: {
    padding: theme.spacing(2),
  },
  avatarLarge: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    display: "absolute",
    top: -40,
    boxShadow:
      "0 10px 30px -12px rgb(0 0 0 / 22%), 0 4px 25px 0px rgb(0 0 0 / 5%), 0 8px 10px -5px rgb(0 0 0 / 10%)",
  },
  paperHeader: {
    height: theme.spacing(9),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  paperFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    height: theme.spacing(4),
    paddingTop: theme.spacing(1),
    color: "rgb(135, 131, 131)",
  },
  topPaper: {
    marginBottom: theme.spacing(3),
  },
  headerPaperRight: {
    marginLeft: theme.spacing(2),
    textAlign: "right",
    width: "-webkit-fill-available",
  },
  headerPaperMainText: {
    display: "flex",
    flexDirection: "row",
  },
}));

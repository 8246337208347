import React from "react";
import { makeStyles } from "@material-ui/core";
import Sidebar from "../../features/sidebar/index";
import clsx from "clsx";
import TeamOverview from "../../features/team-overview";
import { useSelector } from "react-redux";
import {
  getSidebarView,
  getSidebarViewOpen,
} from "../../store/ui/sidebar";
import Stats from "../../features/stats";
import { getMainView } from "../../store/ui/main";
import Loader from "../../components/common/Loader";
import UserProfile from "../../features/user-profile";
import UserManagement from "../../features/settings/user-management";
import { getShowUserProfile } from "../../store/ui/userProfile";
import { getShowUserSettings } from "../../store/ui/settings";
import { apiGetUserView } from "../../components/common/ApiMiddleware";
import { checkForUserViewArray } from "../../components/common/StoreCommon";
import TokenHelper from "../../helpers/TokenHelper";
import DateTimeHelper from "../../core/DateTimeHelper";
import HelpCentre from "../../features/help-centre";

export default function Main() {
  const classes = useStyles();
  const mainView = useSelector(getMainView)();
  const view = useSelector(getSidebarView)();
  const viewOpen = useSelector(getSidebarViewOpen)();
  const showUserProfile = useSelector(getShowUserProfile)();
  const showUserSettings = useSelector(getShowUserSettings)();
  const [userToLogin, setUserToLogin] = React.useState(true);
  const [lockResolver, setLockResolver] = React.useState();
  const [loading, setLoading] = React.useState(true);
  
  React.useEffect(() => {
    const tokenHelper = new TokenHelper();
    const dateTimeHelper = new DateTimeHelper();

    // check if there are tokens stored
    if (tokenHelper.doAuthTokensExist()) {
      console.log(
        "Main: Auth token exist. Now = " +
          dateTimeHelper.nowAsDateTime().toUTCString() +
          ", Access token expiration = " +
          tokenHelper.getAccessTokenExpiration().toUTCString() +
          ", Refresh token expiration = " +
          tokenHelper.getRefreshTokenExpiration().toUTCString()
      );

      // if there are tokens, user has logged in previously.
      // check if they need to login again & check if we have UserView data
      if (userToLogin || !checkForUserViewArray())
        apiGetUserView(userToLogin, setUserToLogin, setLoading);
    } else {
      // if there are no tokens stored, send the user to login.
      console.log("Main: No Auth token exist, send user to login page");
      window.location.href = "/login";
    }

    // this keeps the users browser from throttling ram usage.
    if (!lockResolver)
      if (navigator && navigator.locks && navigator.locks.request) {
        const promise = new Promise((res) => {
          setLockResolver(res);
        });

        navigator.locks.request("buzzHubsLock", { mode: "shared" }, () => {
          return promise;
        });
      }
  }, [setUserToLogin, userToLogin, lockResolver, setLoading]);

  return (
    <>

      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />

      <div style={{ width: "100vw", minHeight: "100vh", background: "#eee" }}>
        <Loader loading={loading} />

        <main
          className={clsx(classes.content, {
            [classes.contentShift]: view,
            [classes.contentLeft]: true,
            [classes.contentShiftLeft]:
              viewOpen && view,
          })}
        >
          {mainView === "Stats" ? <Stats /> : null}
          {mainView === "Hub Overview" || mainView === "Team Overview" ? <TeamOverview /> : null}
        </main>

        <Sidebar />
        {showUserProfile && <UserProfile />}
        {showUserSettings && <UserManagement />}
        <HelpCentre />
      </div>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflow: "hidden",
    minHeight: "100vh",
    position: "relative",
  },
  contentLeft: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: 76,
    },
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  contentShiftLeft: {
    [theme.breakpoints.only("sm")]: {
      marginLeft: 76,
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: 76 + 340,
    },
    [theme.breakpoints.only("xl")]: {
      marginLeft: 76 + 380,
    },
  },
}));

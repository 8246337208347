import { Snackbar } from "@material-ui/core";
import React from "react";
import MuiAlert from "@material-ui/lab/Alert";
import { Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { getNewSnackbarIssues } from "../../store/ui/snackbar";
import { actionSnackbarIssue } from "../../components/common/StoreCommon";
import { makeStyles } from "@material-ui/core/styles";

export default function CustomizedSnackbar() {
  const issues = useSelector(getNewSnackbarIssues)();

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      {issues.map((issue, index) => {
        return <Alert key={`snackbarIssue-${index}`} issue={issue} />;
      })}
    </Stack>
  );
}

function Alert({ issue }) {
  const [open, setOpen] = React.useState(true);

  React.useEffect(() => {
    return () => {
      setOpen(false);
    };
  }, [setOpen]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    actionSnackbarIssue(issue.id);
    setOpen(false);
  };

  const useStyles = makeStyles({
    standardAlert: {
      "& .MuiAlert-message": {
        fontSize: 16
      },
      "& .MuiAlert-icon": {
        fontSize: 24
      },
    },
    largeAlert: {
      "& .MuiAlert-message": {
        fontSize: 30
      },
      "& .MuiAlert-icon": {
        fontSize: 42
      },
    }
  });

  const classes = useStyles();

  const alertClassName = (issue) => {
    if (issue.large === true) {
      return classes.largeAlert;
    }
    return classes.standardAlert;
  }

  return (
    <Snackbar
      key={issue.id}
      id={issue.id}
      open={open}
      autoHideDuration={issue.autoHideDuration}
      onClose={handleClose}
    >
      <MuiAlert
        className={alertClassName(issue)}
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity={issue.severity}
        sx={{ width: "100%" }}
      >
        {issue.text}
      </MuiAlert>
    </Snackbar>
  );
}

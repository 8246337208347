import Cookies from "universal-cookie";

const cookies = new Cookies();

export default class CookieHelper 
{
    
    getCookie(name) {
        return cookies.get(name);
    }

    setCookie(name, value, options) {
        cookies.set(name, value, options);
    }

    removeCookie(name) {

        if (this.doesCookieExist(name)) {
            cookies.remove(name);
        }
    
    }

    doesCookieExist(name) {

        if (this.getCookie(name)) return true;
        return false;

    }
}


import React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { LinearProgress } from "@material-ui/core";

export default function LineItemReport({ report, loaded }) {
  const [rows, setRows] = React.useState([]);
  const [storedReport, setStoredReport] = React.useState(null);

  React.useEffect(() => {
    if (report !== storedReport) {
      addRows(report);
      setStoredReport(report);
    }
  }, [report, storedReport, setStoredReport]);

  const addRows = (json) => {
    let rows = [];
    let x = 0;
    json.LineItems.forEach((item) => {
      rows.push({
        id: x,
        Actions: item.Task,
        TimeActioned: item.Time,
        Duration: item.Duration,
        PhoneNumber: item.PhoneNumber,
      });
      x++;
    });
    setRows(rows);
  };

  const columns = [
    { field: "Actions", headerName: "Actions", width: 150 },
    { field: "TimeActioned", headerName: "Time Actioned", width: 180 },
    { field: "Duration", headerName: "Duration", width: 150 },
    { field: "PhoneNumber", headerName: "Phone Number", width: 180 },
  ];

  return (
    <>
      {loaded ? (
        <DataGrid rows={rows} columns={columns} />
      ) : (
        <LinearProgress color="secondary" />
      )}
    </>
  );
}

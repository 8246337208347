import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import memoize from "lodash.memoize";

const slice = createSlice({
  name: "loggedInUser",
  initialState: { 
    id: null,
    browserPermissionsChecked: false
   },

  reducers: {
    loggedInUserIdUpdated: (loggedInUser, action) => {
      return { ...loggedInUser, ...action.payload };
    },
    browserPermissionsCheckedUpdated: (loggedInUser, action) => {
      return { ...loggedInUser, ...action.payload };
    },
  },
});

export const { loggedInUserIdUpdated, browserPermissionsCheckedUpdated } = slice.actions;
export default slice.reducer;

// Selector functions
// Memoization
// Store calls in cache to save resources when calling store.
export const getLoggedInUserId = createSelector(
  (state) => state.entities.loggedInUser,
  (loggedInUser) =>
    memoize(() => {
      return loggedInUser.id;
    })
);

export const getBrowserPermissionsChecked = createSelector(
  (state) => state.entities.loggedInUser,
  (loggedInUser) =>
    memoize(() => {
      return loggedInUser.browserPermissionsChecked;
    })
);
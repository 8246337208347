import React from "react";
import { Button, makeStyles, Tooltip } from "@material-ui/core";
import UploadFiles from "../../file-upload/UploadFiles";
import InsertCoverMedia from "./InsertCoverMedia";
import { Box } from "@mui/system";
import { EmojiEmotions, Send } from "@material-ui/icons";
import { CircularProgress, IconButton } from "@mui/material";

export default function Toolbar({
  toolbarPlugin,
  files,
  setFiles,
  setCoverMediaUrl,
  showInsertImage,
  sendData,
  setEmojiIsVisible,
  emojiIsVisible,
  loading,
}) {
  const { Toolbar } = toolbarPlugin;
  const classes = useStyles();

  const keydownHandler = (e) => {
    if (e.keyCode === 13 && e.ctrlKey) sendData();
  };

  React.useEffect(() => {
    document.addEventListener("keydown", keydownHandler);

    return function cleanup() {
      document.removeEventListener("keydown", keydownHandler);
    };
  });

  return (
    <Toolbar>
      {(externalProps) => (
        <React.Fragment>
          <Box className={classes.toolbarBox}>
            {/* <BoldButton {...externalProps} />
          <ItalicButton {...externalProps} />
          <UnderlineButton {...externalProps} />
          <CodeButton {...externalProps} />
          <Separator {...externalProps} /> */}
            {/* <HeadlineOneButton {...externalProps} /> */}
            <UploadFiles files={files} setFiles={setFiles} />
            {showInsertImage ? (
              <InsertCoverMedia setCoverMediaUrl={setCoverMediaUrl} />
            ) : null}
            <IconButton
              style={{ width: 36, height: 34, verticalAlign: "unset" }}
              component="span"
              onClick={() => setEmojiIsVisible(!emojiIsVisible)}
            >
              <EmojiEmotions />
            </IconButton>
            {/* <HeadlinesButton
            {...externalProps}
            {...externalProps}
            classes={classes}
          /> */}
            {/* <UnorderedListButton {...externalProps} />
          <OrderedListButton {...externalProps} />
          <BlockquoteButton {...externalProps} /> */}
            <Box className={classes.sendButtonContainer}>
              <Tooltip title="Ctrl+Enter">
                <Button
                  className={classes.sendButton}
                  onClick={() => sendData()}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress style={{ width: "1em", height: "1em" }} />
                  ) : (
                    <Send />
                  )}
                </Button>
              </Tooltip>
            </Box>
          </Box>
        </React.Fragment>
      )}
    </Toolbar>
  );
}

// class HeadlinesPicker extends Component {
//   componentDidMount() {
//     setTimeout(() => {
//       window.addEventListener("click", this.onWindowClick);
//     });
//   }

//   componentWillUnmount() {
//     window.removeEventListener("click", this.onWindowClick);
//   }

//   onWindowClick = () =>
//     // Call `onOverrideContent` again with `undefined`
//     // so the toolbar can show its regular content again.
//     this.props.onOverrideContent(undefined);

//   render() {
//     const buttons = [HeadlineOneButton, HeadlineTwoButton, HeadlineThreeButton];
//     // const buttons = [HeadlineOneButton];
//     return (
//       <div>
//         {buttons.map((Button, i) => (
//           // eslint-disable-next-line
//           <Button key={i} {...this.props} />
//         ))}
//       </div>
//     );
//   }
// }

// class HeadlinesButton extends Component {
//   onClick = () =>
//     // A button can call `onOverrideContent` to replace the content
//     // of the toolbar. This can be useful for displaying sub
//     // menus or requesting additional information from the user.
//     this.props.onOverrideContent(HeadlinesPicker);

//   render() {
//     return (
//       <div className={this.props.classes.headlineButtonWrapper}>
//         <button
//           onClick={this.onClick}
//           className={this.props.classes.headlineButton}
//         >
//           H
//         </button>
//       </div>
//     );
//   }
// }

const useStyles = makeStyles(() => ({
  headlineButtonWrapper: {
    display: "inline-block",
  },
  headlineButton: {
    background: "#fbfbfb",
    color: "#888",
    fontSize: 18,
    border: 0,
    paddingTop: 5,
    verticalAlign: "bottom",
    height: 34,
    width: 36,
  },
  toolbarBox: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
  },
  sendButtonContainer: {
    position: "absolute",
    right: 0,
  },
  sendButton: {
    color: "#1976d2",
  },
}));

import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import memoize from "lodash.memoize";

const slice = createSlice({
  name: "userProfile",
  initialState: {
    visible: false,
    userId: null,
  },
  reducers: {
    // actions => action handlers

    userProfileVisibilityUpdated: (userProfile, action) => {
      // add a new snackbar issue
      return { ...userProfile, ...{ visible: action.payload } };
    },
    userIdUpdated: (userProfile, action) => {
      // add a new snackbar issue
      return { ...userProfile, ...{ userId: action.payload } };
    },
  },
});

export const { userProfileVisibilityUpdated, userIdUpdated } = slice.actions;
export default slice.reducer;

export const getShowUserProfile = createSelector(
  (state) => state.ui.userProfile,
  (userProfile) =>
    memoize(() => {
      return userProfile.visible;
    })
);

export const getUserProfileId = createSelector(
  (state) => state.ui.userProfile,
  (userProfile) =>
    memoize(() => {
      return userProfile.userId;
    })
);

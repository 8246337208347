import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import reducer from "./reducer.js";
import LogRocket from "logrocket";

export default function configureReducerStore() {
  return configureStore({
    reducer,
    middleware: [
      ...getDefaultMiddleware({
        serializableCheck: false,
      }),
      LogRocket.reduxMiddleware(),
    ],
  });
}

import { makeStyles, 
  CircularProgress
  ,Box,
  Divider,
  Fade,
  IconButton,
  Modal,
  Paper,
  Typography,
  TextField,
  Button,
  Tooltip
} from "@material-ui/core";
import { Minimize, Close} from "@material-ui/icons";
import React from "react";
import { useSelector } from "react-redux";
import {
  getRequestedHelpGuid,
  getRequestingHelp,
  getRequestingHelpText,
  getShowRequestHelpModal,
} from "../../../store/ui/requestHelp";
import {
  loggedInUserId,
  updateRequestHelpVisiblity,
  updateRequestingHelp,
  updateRequestingHelpGuid,
  updateRequestingHelpText,
} from "../../../components/common/StoreCommon";
import {
  requestHelp,
  requestHelpCancelled,
} from "../../../components/common/ConnectionMiddleware";

export default function RequestHelpModal() {
  const showRequestHelpModal = useSelector(getShowRequestHelpModal)();
  const open = Boolean(showRequestHelpModal);
  const classes = useStyles();
  const requestingHelp = useSelector(getRequestingHelp)();

  return (
    <Modal
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      anchorEl={showRequestHelpModal}
      open={open}
      BackdropProps={{
        timeout: 500,
        style: { background: "rgba(50,50,50,0.5)" },
      }}
      aria-labelledby="Request Help"
      aria-describedby="Request immediate help from a selected group of users"
    >
      <Fade in={open}>
        <Paper className={classes.paper}>
          <Tooltip title={requestingHelp ? "Minimize" : "Close"}>
            {requestingHelp ? (
              <IconButton
                style={{
                  width: 30,
                  height: 30,
                  position: "absolute",
                  right: 5,
                  top: 12,
                }}
                onClick={() => {
                  updateRequestHelpVisiblity(false);
                }}
              >
                <Minimize />
              </IconButton>
            ) : (
              <IconButton
                style={{
                  width: 30,
                  height: 30,
                  position: "absolute",
                  right: 5,
                  top: 12,
                }}
                onClick={() => {
                  updateRequestingHelpText("");
                  updateRequestHelpVisiblity(false);
                }}
              >
                <Close />
              </IconButton>
            )}
          </Tooltip>
          <Request />
        </Paper>
      </Fade>
    </Modal>
  );
}

function Request() {
  const storedRequestMessage = useSelector(getRequestingHelpText)();
  const [error, setError] = React.useState("");
  const [requestMessage, setRequestMessage] =
    React.useState(storedRequestMessage);
  const requestingHelp = useSelector(getRequestingHelp)();
  const requestingHelpGuid = useSelector(getRequestedHelpGuid)();

  const handleRequestHelp = (requestMessage) => {
    if (!requestMessage || requestMessage.length < 1) {
      setError("Please enter a message");
      return;
    }

    updateRequestingHelpText(requestMessage);
    updateRequestingHelp(true);
    requestHelp(requestMessage);
  };

  return (
    <>
      <Box
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          width: 120,
          height: 120,
          borderRadius: "50%",
          backgroundColor: "red",
          color: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "bold",
        }}
      >
        <Typography variant="h3">SOS</Typography>
      </Box>

      <Divider style={{ marginTop: 10, marginBottom: 10 }} />

      {requestingHelp ? (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 16,
            alignItems: "center",
          }}
        >
          <Typography
            variant="body2"
            style={{
              textAlign: "center",
              fontStyle: "italic",
              background: "whitesmoke",
              color: "gray",
            }}
          >
            {requestMessage}
          </Typography>

          <Typography
            variant="body1"
            style={{ textAlign: "center", fontStyle: "italic" }}
          >
            Waiting for help...
          </Typography>

          <CircularProgress style={{ width: 60, height: 60 }} />

          <Button
            variant="contained"
            style={{ background: "red", color: "white" }}
            onClick={() => {
              requestHelpCancelled({
                GUID: requestingHelpGuid,
                Message: requestMessage,
                UserId: loggedInUserId(),
              });
              updateRequestingHelp(false);
              updateRequestingHelpGuid(null);
              updateRequestHelpVisiblity(false);
              updateRequestingHelpText("");
            }}
          >
            Cancel
          </Button>
        </Box>
      ) : (
        <Box style={{ display: "flex", flexDirection: "column", gap: 4 }}>
          <Typography
            variant="body2"
            style={{ textAlign: "center", fontStyle: "italic" }}
          >
            Very briefly, what do you need help with?
          </Typography>

          <TextField
            multiline
            inputProps={{
              style: {
                padding: "0 10px",
              },
            }}
            rows={15}
            value={requestMessage}
            variant="filled"
            onChange={(e) => {
              setRequestMessage(e.target.value);
              setError("");
            }}
            style={{ width: "100%", marginBottom: 10 }}
            error={error.length > 0}
            helperText={error}
          />

          <Button
            variant="contained"
            style={{ background: "rgb(30, 54, 83)", color: "white", fontWeight: "bold" }}
            onClick={() => handleRequestHelp(requestMessage)}
          >
            Request Help
          </Button>
        </Box>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    height: "auto",
    width: "500px",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    borderRadius: 10,
    overflow: "auto",
    position: "relative",
    maxHeight: "80vh",
  },
}));

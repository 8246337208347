export const dateIsToday = (date) => {
  const today = new Date();
  date = new Date(date);

  if (today.toDateString() === date.toDateString()) {
    return true;
  }

  return false;
};

import {
  Avatar,
  Box,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import {
  getTeamColorById,
  getTeamNameByTeamId,
} from "../../../store/hub/teams";
import { getUserImageById, getUserNameById } from "../../../store/hub/users";

export default function ThreadAvatar({ thread }) {
  const classes = useStyles();
  const isUserFl = thread.UserIds.length >= 1;
  const isTeamFl = thread.TeamIds.length >= 1;
  const isMultiImage = thread.UserIds.length > 1 || thread.TeamIds.length > 1;
  const getUserImageByIdSelector = useSelector(getUserImageById);
  const getTeamColorByIdSelector = useSelector(getTeamColorById);
  const getTeamNameByIdSelector = useSelector(getTeamNameByTeamId);
  const getUserNameByIdSelector = useSelector(getUserNameById);

  const ids = isUserFl ? thread.UserIds : thread.TeamIds;

  return (
    <>
      {isTeamFl ? (
        <Tooltip
          title={
            <Box>
              {ids.map((id, index) => {
                if (index === 10)
                  return (
                    <Typography
                      key={`threadAvatarTooltip${id}`}
                      variant="body2"
                    >
                      + {ids.length - 9} more hubs
                    </Typography>
                  );
                if (index > 10) return null;
                return (
                  <Typography key={`threadAvatarTooltip${id}`} variant="body2">
                    {getTeamNameByIdSelector(id)}
                  </Typography>
                );
              })}
            </Box>
          }
        >
          {isMultiImage
            ? multiTeamJsx(
                classes,
                getTeamColorByIdSelector,
                ids,
                getTeamNameByIdSelector
              )
            : singleTeamJsx(
                classes,
                getTeamColorByIdSelector,
                ids,
                getTeamNameByIdSelector
              )}
        </Tooltip>
      ) : isUserFl ? (
        <Tooltip
          title={
            <Box>
              {ids.map((id, index) => {
                if (index === 10)
                  return (
                    <Typography
                      key={`threadAvatarTooltip${id}`}
                      variant="body2"
                    >
                      + {ids.length - 9} more users
                    </Typography>
                  );
                if (index > 10) return null;
                return (
                  <Typography key={`threadAvatarTooltip${id}`} variant="body2">
                    {getUserNameByIdSelector(id)}
                  </Typography>
                );
              })}
            </Box>
          }
        >
          {isMultiImage
            ? multiUserJsx(
                classes,
                getUserImageByIdSelector,
                ids,
                getUserNameByIdSelector
              )
            : singleUserJsx(
                classes,
                getUserImageByIdSelector,
                ids,
                getUserNameByIdSelector
              )}
        </Tooltip>
      ) : null}

      {!isTeamFl && !isUserFl ? singleUnknownUserJsx(classes) : null}
    </>
  );
}

const singleUnknownUserJsx = (classes) => {
  if (!classes) return;

  return (
    <Avatar alt="thread-user" className={classes.messageThreadImage}></Avatar>
  );
};

const multiTeamJsx = (classes, getTeamColor, ids, getTeamName) => {
  if (!ids || !getTeamColor || !classes || !getTeamName) return;

  return (
    <Box className={classes.multiUserBox}>
      <Avatar
        alt="thread-user"
        className={classes.smallMessageThreadImage}
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          backgroundColor: `#${getTeamColor(ids[0])}`,
        }}
      >
        {getTeamName(ids[0]).charAt(0)}
      </Avatar>
      <Avatar
        alt="thread-user"
        className={classes.smallMessageThreadImage}
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          backgroundColor: `#${getTeamColor(ids[1])}`,
        }}
      >
        {getTeamName(ids[1]).charAt(0)}
      </Avatar>
    </Box>
  );
};

const singleTeamJsx = (classes, getTeamColor, ids, getTeamName) => {
  if (!ids || !getTeamColor || !classes || !getTeamName) return;

  return (
    <Avatar
      alt="thread-user"
      className={classes.messageThreadImage}
      style={{ backgroundColor: `#${getTeamColor(ids[0])}` }}
    >
      {getTeamName(ids[0]).charAt(0)}
    </Avatar>
  );
};

const multiUserJsx = (classes, getUserImage, ids, getUserName) => {
  if (!ids || !getUserImage || !classes || !getUserName) return;

  return (
    <Box className={classes.multiUserBox}>
      <Avatar
        alt="thread-user"
        src={getUserImage(ids[0])}
        className={classes.smallMessageThreadImage}
        style={{ position: "absolute", top: 0, right: 0 }}
      />
      <Avatar
        alt="thread-user"
        src={getUserImage(ids[1])}
        className={classes.smallMessageThreadImage}
        style={{ position: "absolute", bottom: 0, left: 0 }}
      />
    </Box>
  );
};

const singleUserJsx = (classes, getUserImage, ids) => {
  if (!ids || !getUserImage || !classes) return;

  return (
    <Avatar
      alt="thread-user"
      src={getUserImage(ids[0])}
      className={classes.messageThreadImage}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  multiUserBox: {
    position: "relative",
    width: "50px",
    height: "50px",
    marginTop: "auto",
    marginBottom: "auto",
  },
  messageThreadImage: {
    marginTop: "auto",
    marginBottom: "auto",
    width: 50,
    height: 50,
  },
  smallMessageThreadImage: {
    width: 35,
    height: 35,
  },
}));

import React from "react";
import { useSelector } from "react-redux";
import {
  getHelpRequestThreadId,
  getShowRequestHelpModal,
} from "../../store/ui/requestHelp";
import {
  updateRequestHelpVisiblity,
  updateRequestingHelp,
} from "../../components/common/StoreCommon";
import HelpThread from "./components/HelpThread";
import RequestHelpModal from "./components/RequestHelpModal";
import Requests from "./components/Requests";

export default function HelpCentre() {
  // help centre holds three main components:
  // 1. Request Help Modal - when a user requests help.
  // 2. Requests modal - to display all requests users make.
  // 3. Help Accepted modal - message container that displays when a user accepts a help request.
  const showRequestHelpModal = useSelector(getShowRequestHelpModal)();
  const showMessageModal = useSelector(getHelpRequestThreadId)();

  React.useEffect(() => {
    if (showMessageModal) {
      updateRequestingHelp(false);
      updateRequestHelpVisiblity(false);
    }
  }, [showMessageModal]);

  return (
    <>
      {/* Show request help modal if showRequestHelpModal is true - This is true when a user is looking for help. */}
      {showRequestHelpModal && !showMessageModal && <RequestHelpModal />}

      {/* Show requests modal if showRequestHelpModal is false 
            - A user should not be able to see requests if they are asking for help themselves*/}
      {!showRequestHelpModal && !showMessageModal && <Requests />}

      {/* Show message modal when showMessageModal is true - Only this should be shown if true */}
      {showMessageModal && <HelpThread threadId={showMessageModal} />}
    </>
  );
}

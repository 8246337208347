import { Divider, Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useSelector } from "react-redux";
import { convertDateTime } from "../../../components/common/Common";
import { getUserReturnDateTimeUtcByUserId } from "../../../store/hub/userAvailability";

const StatusTooltip = ({ status, time, dailyTime, userId }) => {
  const returnDateTimeUtc = useSelector(getUserReturnDateTimeUtcByUserId)(
    userId
  );

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Typography variant="body1">{status.StatusDisplayName}</Typography>
      <Divider />
      <Typography variant="caption">
        {time ? time : <Skeleton width="auto" height={13} />}
      </Typography>
      {dailyTime ? (
        <Typography variant="caption">{dailyTime}</Typography>
      ) : null}
      {status.LastActionText ? (
        <Typography variant="caption">{status.LastActionText}</Typography>
      ) : null}
      {returnDateTimeUtc && (
        <Typography variant="caption">{`Returning: ${convertDateTime(
          returnDateTimeUtc
        )}`}</Typography>
      )}
    </Box>
  );
};

export default StatusTooltip;

import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import memoize from "lodash.memoize";

const slice = createSlice({
  name: "userStatus",
  initialState: [],
  reducers: {
    // actions => action handlers
    usersStatusAdded: (userStatus, action) => {
      const statusArray = action.payload.usersStatus;
      statusArray.map((status) => {
        const index = userStatus.findIndex(
          (storedStatus) => storedStatus.Id === status.Id
        );
        if (index >= 0) {
          userStatus.splice(index, 1, { ...userStatus[index], ...status });
        } else {
          userStatus.push(status);
        }
        return null;
      });
    },
    userStatusAdded: (userStatus, action) => {
      // adds a userStatus to the existing status object.
      const index = userStatus.findIndex(
        (status) => status.Id === action.payload.userStatus.Id
      );
      if (index >= 0) {
        userStatus.splice(index, 1, {
          ...userStatus[index],
          ...action.payload.userStatus,
        });
      } else {
        userStatus.push(action.payload.userStatus);
      }
    },
    userStatusUpdated: (userStatus, action) => {
      // updates a userStatus object by comparing the Id of the payload userStatus
      // only updates the data that is different between stored userStatus and payload.
      const index = userStatus.findIndex(
        (status) => status.Id === action.payload.status.Id
      );
      userStatus.splice(index, 1, {
        ...userStatus[index],
        ...action.payload.status,
      });
    },
  },
});

export const { usersStatusAdded, userStatusAdded, userStatusUpdated } =
  slice.actions;
export default slice.reducer;

// Selector functions
// Memoization
// Store calls in cache to save resources when calling store.
export const getUserStatusById = createSelector(
  (state) => state.entities.userStatus,
  (userStatus) =>
    memoize((id) => {
      const status = userStatus.filter((status) => status.Id === id)[0];
      return status;
    })
);

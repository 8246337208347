import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import memoize from "lodash.memoize";

const slice = createSlice({
  name: "settings",
  initialState: {
    userSettingsVisible: false,
    userSettingsUserId: null,
    storageUrl: null
  },
  reducers: {
    userSettingsVisibilityUpdated: (settings, action) => {
      return { ...settings, ...{ userSettingsVisible: action.payload } };
    },
    userSettingsUserIdUpdated: (settings, action) => {
      return { ...settings, ...{ userId: action.payload } };
    },
    storageUrlUpdated: (settings, action) => {
      return { ...settings, ...{ storageUrl: action.payload } };
    }
  },
});

export const { userSettingsVisibilityUpdated, userSettingsUserIdUpdated, storageUrlUpdated } = slice.actions;
export default slice.reducer;

export const getShowUserSettings = createSelector(
  (state) => state.ui.settings,
  (settings) =>
    memoize(() => {
      return settings.userSettingsVisible;
    })
);

export const getUserSettingsUserId = createSelector(
  (state) => state.ui.settings,
  (settings) =>
    memoize(() => {
      return settings.userId;
    })
);

export const getStorageUrl = createSelector(
  (state) => state.ui.settings,
  (settings) =>
    memoize(() => {
      return settings.storageUrl;
    })
);

import {
  Avatar,
  Divider,
  Fade,
  List,
  ListItem,
  makeStyles,
  Modal,
  Paper,
  Tab,
} from "@material-ui/core";
import { Close, Visibility, VisibilityOff } from "@material-ui/icons";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useSelector } from "react-redux";
import {
  getShowUserProfile,
  getUserProfileId,
} from "../../store/ui/userProfile";
import {
  getUserProfile,
  getLoggedInUserProfile,
  updateUserProfile,
  updateCurrentUserProfile
} from "../../components/common/ApiMiddleware";
import {
  addSnackbarIssue,
  loggedInUserId,
  updateUserProfileVisibility,
} from "../../components/common/StoreCommon";
import { TabList, TabContext, TabPanel } from "@mui/lab";
import Hubs from "./components/Hubs";
import { updateFirebaseUserPassword } from "../../components/common/Firebase";
import TokenHelper from "../../helpers/TokenHelper";
import { isNullOrWhitespace } from "../../components/common/Common";
import { getUserImage } from "../../components/common/StoreCommon";

export default function UserProfile() {
  const classes = useStyles();
  const showUserProfile = useSelector(getShowUserProfile)();
  const userProfileId = useSelector(getUserProfileId)();
  const open = Boolean(showUserProfile);
  const [firstName, setFirstName] = React.useState(null);
  const [lastName, setLastName] = React.useState(null);
  const [userName, setUserName] = React.useState(null);
  const [timeZone, setTimeZone] = React.useState(null);
  const [admin, setAdmin] = React.useState(null);
  const [feedTickerMessageFl, setFeedTickerMessageFl] = React.useState(null);
  const [avatarSrc, setAvatarSrc] = React.useState(null);
  const [disabledFl, setDisabledFl] = React.useState(null);
  const [userTeams, setUserTeams] = React.useState(null);
  const [tabValue, setTabValue] = React.useState(1);
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [userLoaded, setUserLoaded] = React.useState(false);

  const tokenHelper = new TokenHelper();
  const isAdmin = tokenHelper.isAdminRoleSet();
  
  function setUser(user) {
    if (user.FirstName) {
      setFirstName(user.FirstName);
      setLastName(user.LastName);
      setUserName(user.UserName);
      setTimeZone(user.TimeZoneId);
      setAdmin(user.AdminFl);
      setFeedTickerMessageFl(user.FeedTickerMessageFl);
      setAvatarSrc(getUserImage(user.Id));
      setDisabledFl(user.DisabledFl);
      setUserTeams(user.UserTeams);
      setUserLoaded(true);
    }
  }

  function saveUserProfile() {
    
    if (userProfileId) {
      updateUserProfile(
        JSON.stringify({
          Id: userProfileId,
          FirstName: firstName,
          LastName: lastName,
          UserName: userName,
          AdminFl: admin,
          FeedTickerMessageFl: feedTickerMessageFl,
          //Avatar: avatarName,
          TimeZoneId: timeZone,
          DisabledFl: disabledFl,
          UserTeams: userTeams,
        })
      ).then(() => {
        addSnackbarIssue("Profile updated successfully", "success");
        updateUserProfileVisibility(false);
      }).catch(() => {
        addSnackbarIssue("An error occurred updating the profile please try again.","warning");
      }
      );

    }
    else {
      updateCurrentUserProfile(
        JSON.stringify({
          Id: loggedInUserId(),
          FirstName: firstName,
          LastName: lastName,
          UserName: userName,
        })
        ).then(() => {
          addSnackbarIssue("Profile updated successfully", "success");
          updateUserProfileVisibility(false);
        }).catch(() => {
          addSnackbarIssue("An error occurred updating the profile please try again.","warning");
        }
        );
    }
  }

  function handleTabChange(e, newValue) {
    setTabValue(newValue);
  }

  function updatePassword() {
    if (isNullOrWhitespace(password)) {
      addSnackbarIssue(
        "Please enter a new password",
        "warning"
      );
      return;
    }
    
    if (isNullOrWhitespace(confirmPassword)) {
      addSnackbarIssue(
        "Please confirm the new password",
        "warning"
      );
      return;
    }

    if (password !== confirmPassword) {
      addSnackbarIssue(
        "Passwords do not match! Please check and try again",
        "warning"
      );
      return;
    }
    updateFirebaseUserPassword(password);
  }

  React.useEffect(() => {
    if (!userLoaded) {

      if (userProfileId) {
        getUserProfile(userProfileId).then(
          (user) => {
            if (user["error"]) updateUserProfileVisibility(false);
            else setUser(user);
          }
        );
      }
      else {
        getLoggedInUserProfile().then(
          (user) => {
            if (user["error"]) updateUserProfileVisibility(false);
            else setUser(user);
          }
        );
      }
      
    }
  });

  return (
    <Modal
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      anchorEl={showUserProfile}
      open={open}
      BackdropProps={{
        timeout: 500,
        style: { background: "rgba(50,50,50,0.5)" },
      }}
      aria-labelledby="User Profile"
      aria-describedby="Update all aspects of a user profile here"
    >
      <Fade in={open}>
        <Paper className={classes.paper}>
          <IconButton
            style={{
              width: 30,
              height: 30,
              position: "absolute",
              right: 5,
              top: 12,
            }}
            onClick={() => updateUserProfileVisibility(false)}
          >
            <Close />
          </IconButton>
          <Typography variant="h5" style={{ textAlign: "center" }}>
            User Profile
          </Typography>
          <Divider />
          <List>
            <ListItem>
              <Avatar src={avatarSrc} className={classes.avatar} />
              {/* <Button variant="outlined">Change Avatar</Button> */}
            </ListItem>
            <Divider />

            <TabContext value={tabValue}>
              <TabList onChange={handleTabChange}>
                <Tab label={"User Details"} value={1} />
                {/*isAdmin && <Tab label={"Hubs"} value={2} />*/}
                {/*isAdmin && <Tab label={"Permissions"} value={3} />*/}
              </TabList>

              {/* user details tab panel */}
              <TabPanel value={1}>
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": { m: 1, width: "100%" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    type={"text"}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    label="First Name"
                    InputProps={{
                      className: classes.input,
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    type={"text"}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    label="Last Name"
                    InputProps={{
                      className: classes.input,
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    type={"text"}
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    label="username"
                    disabled={true}
                    InputProps={{
                      className: classes.input,
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    type={"text"}
                    value={timeZone}
                    label="Time Zone"
                    disabled={true}
                    InputProps={{
                      className: classes.input,
                    }}
                    InputLabelProps={{ shrink: true }}
                  />

                  <Typography variant="body1">Update password</Typography>
                  <Divider />
                  <TextField
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="New Password"
                    InputProps={{
                      className: classes.input,
                    }}
                    InputLabelProps={{ shrink: true }}
                  />

                  <TextField
                    type={showPassword ? "text" : "password"}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Confirm New Password"
                    InputProps={{
                      className: classes.input,
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                  <Button
                    style={{ width: "100%" }}
                    variant="outlined"
                    onClick={() => updatePassword()}
                  >
                    Change password
                  </Button>
                </Box>
              </TabPanel>

              {/* All panels need to be seperated out when admin functions are added back in */}

             
              {/* teams tab panel */}
              <TabPanel value={2}>
                <Hubs hubs={userTeams} setHubs={setUserTeams} />
              </TabPanel>

              {/* permissions tab panel */}
              <TabPanel value={3}>
                <ListItem>
                  <Box className={classes.textContainer}>
                    <Typography variant="h6">Permissions</Typography>
                  </Box>
                </ListItem>
                <ListItem>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={admin}
                          onChange={(e) => setAdmin(!admin)}
                        />
                      }
                      label="Administrator"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={feedTickerMessageFl}
                          onChange={(e) =>
                            setFeedTickerMessageFl(!feedTickerMessageFl)
                          }
                        />
                      }
                      label="Can add Breaking News"
                    />
                  </FormGroup>
                </ListItem>
              </TabPanel>
                
            </TabContext>
            <ListItem style={{ justifyContent: "end" }}>
              <Button onClick={() => saveUserProfile()} variant="contained">
                Save
              </Button>
            </ListItem>
          </List>
        </Paper>
      </Fade>
    </Modal>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    height: "auto",
    width: "500px",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    borderRadius: 10,
    overflow: "auto",
    position: "relative",
    maxHeight: "80vh",
    overflowY: "scroll",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  avatar: {
    height: 80,
    width: 80,
    marginRight: 10,
  },
  textContainer: {
    width: 120,
    textAlign: "right",
    paddingRight: 10,
  },
  input: {
    padding: 10,
  },
}));

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles, Grid, Box, Typography, Paper, Select, MenuItem } from "@material-ui/core";
import TodayIcon from "@material-ui/icons/Today";

import { getSelectedTimePeriodText, getTimePeriods } from "../../../../store/ui/conversion";
import { updateSelectedTimePeriod } from "../../../../components/common/StoreCommon";

export default function TimePeriodSelector () {
  
  const classes = useStyles();
  const timePeriods = useSelector((state) => getTimePeriods(state.ui.conversion));
  const selectedPeriodText = useSelector((state) => getSelectedTimePeriodText(state.ui.conversion));

  const handleTimePeriodChange = (event) => {
    updateSelectedTimePeriod(event.target.value);
  };

  return (
    <Grid key="timePeriodSeledtor" item className={classes.topPaper}>
      <Paper className={classes.widgetPaper}>
        <Box className={classes.paperHeader}>
          <TodayIcon className={classes.timePeriodIcon}/>
          {timePeriods.length > 1 && (
            <Select  className={classes.timePeriodSelect}
            id="time-period-select"
            value={selectedPeriodText}
            onChange={handleTimePeriodChange}
            autoWidth
          >
            {timePeriods.map((timePeriod) => (
            <MenuItem key={timePeriod} value={timePeriod}>
              {timePeriod}
            </MenuItem>
            ))}
            </Select>
          )}
          {timePeriods.length === 1 && (
            <Typography
            className={classes.todayText}
            variant="subtitle1"
            gutterBottom
          >
            {selectedPeriodText}
          </Typography>
          )}
        </Box>
      </Paper>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  widgetPaper: {
    [theme.breakpoints.only("xs")]: {
      height: 20,
      padding: theme.spacing(1),
      width: "auto",
      minWidth: "30vw",
    },
    [theme.breakpoints.up("sm")]: {
      height: 20,
      padding: theme.spacing(2),
      width: "auto",
    },
    [theme.breakpoints.only("xl")]: {
      height: 20,
      padding: theme.spacing(2),
      width: "auto",
    },

    color: "rgb(135, 131, 131)",
  },
  paperHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  timePeriodSelect: {
    padding: 0,
    marginTop: theme.spacing(-1),
  },
  topPaper: {
    [theme.breakpoints.only("xs")]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  timePeriodIcon: {
    marginRight: 5,
  },
}));

import React from "react";
import { useSelector } from "react-redux";
import { Grid, Paper, Typography, Box, Divider, Select,MenuItem, makeStyles} from "@material-ui/core";
import Icon from '@material-ui/core/Icon'
import TodayIcon from "@material-ui/icons/Today";

import { getColorStyle } from "../../../../components/common/Common";
import { getMetric } from "../../../../store/hub/conversionMetrics";
import { updateSelectedTimePeriod } from "../../../../components/common/StoreCommon";
import { getSelectedTimePeriodText, getTimePeriods } from "../../../../store/ui/conversion";

export default function ConversionWidget({ metric1IdAndValue, metric2IdAndValue }) {

  const classes = useStyles();

  const metric1 = useSelector((state) => getMetric(state.entities.conversionMetrics, metric1IdAndValue.id));
  let metric2Id = null;
  if (metric2IdAndValue) {
    metric2Id = metric2IdAndValue.id;
  }
  const metric2 = useSelector((state) => getMetric(state.entities.conversionMetrics, metric2Id));
  const selectedPeriodText = useSelector((state) => getSelectedTimePeriodText(state.ui.conversion));
  const timePeriods = useSelector((state) => getTimePeriods(state.ui.conversion));
  
  const handleTimePeriodChange = (event) => {
    updateSelectedTimePeriod(event.target.value);
  };

  return (
    <Grid key="conversions" item className={classes.topPaper}>
      <Paper className={classes.widgetPaper}>
        <Box className={classes.paperHeader}>
          <Box className={classes.headerPaperRight}>

            {metric1 && (
              <Box style={{ display: "flex", flexDirection: "row" }}>
                <Icon className={classes.icon} style={getColorStyle(metric1.color)}>{metric1.icon}</Icon>
                <Typography className={classes.conversionText} variant="h6" gutterBottom>{metric1IdAndValue.value}</Typography>
              </Box>
            )}
            
            {metric2 && (
              <Box style={{ display: "flex", flexDirection: "row" }}>
                <Icon className={classes.icon} style={getColorStyle(metric2.color)}>{metric2.icon}</Icon>
                <Typography className={classes.conversionText} variant="h6" gutterBottom>{metric2IdAndValue.value}</Typography>
              </Box>
            )}

          </Box>
        </Box>
        <Divider />
        <Box className={classes.paperFooter}>
          <TodayIcon className={classes.timePeriodIcon}/>
          {timePeriods.length > 1 && (
            <Select
            id="time-period-select"
            value={selectedPeriodText}
            onChange={handleTimePeriodChange}
            autoWidth
          >
            {timePeriods.map((timePeriod) => (
            <MenuItem key={timePeriod} value={timePeriod}>
              {timePeriod}
            </MenuItem>
            ))}
            </Select>
          )}
          {timePeriods.length === 1 && (
            <Typography
            className={classes.todayText}
            variant="subtitle1"
            gutterBottom
          >
            {selectedPeriodText}
          </Typography>
          )}
        </Box>
      </Paper>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  widgetPaper: {
    [theme.breakpoints.only("xs")]: {
      height: 90,
      padding: theme.spacing(1),
      width: "auto",
      minWidth: "30vw",
    },
    [theme.breakpoints.up("sm")]: {
      height: 80,
      padding: theme.spacing(2),
      width: "auto",
    },
    [theme.breakpoints.only("xl")]: {
      height: 100,
      padding: theme.spacing(2),
      width: "auto",
    },

    color: "rgb(135, 131, 131)",
  },
  conversionText: {
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 14,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 16,
    },
    [theme.breakpoints.only("xl")]: {
      fontSize: 20,
    },
    color: "rgb(100, 100, 100)",
  },
  todayText: {
    [theme.breakpoints.only("xs")]: {
      fontSize: 11,
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 11,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 13,
    },
    [theme.breakpoints.only("xl")]: {
      fontSize: 16,
    },
  },
  paperHeader: {
    [theme.breakpoints.only("xs")]: {
      height: theme.spacing(8),
    },
    [theme.breakpoints.up("sm")]: {
      height: theme.spacing(7),
    },
    [theme.breakpoints.only("xl")]: {
      height: theme.spacing(9),
    },
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  paperFooter: {
    [theme.breakpoints.only("xs")]: {
      height: theme.spacing(2),
    },
    [theme.breakpoints.up("sm")]: {
      height: theme.spacing(3),
    },
    [theme.breakpoints.only("xl")]: {
      height: theme.spacing(4),
    },
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingTop: theme.spacing(1),
    color: "rgb(135, 131, 131)",
  },
  topPaper: {
    [theme.breakpoints.only("xs")]: {
      marginBottom: theme.spacing(1),
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(3),
    },
  },
  headerPaperRight: {
    textAlign: "right",
    marginLeft: theme.spacing(1),
  },
  icon: {
    marginRight: 10,
  },
  timePeriodIcon: {
    marginRight: 5,
  },
}));

import { isValidDate, logRocketIdentifyUser, yyyymmdd } from "./Common";
import { logout } from "./ConnectionMiddleware";
import { checkConnected, callHub } from "./Connection";
import {
  addAvailableStatusToStore,
  addCanMessageTeams,
  addCanMessageUsers,
  addFeedThreads,
  updateExpiredThreads,
  initialiseHubConversionsInStore,
  addLoggedInUserIdToStore,
  addMessagesToFeedThreadInStore,
  addMessagesToThreadInStore,
  addMessageThreadsToStore,
  addSelectedTeamToStore,
  addSnackbarIssue,
  addTeamsToStore,
  addUserContactsToStore,
  addTickerMessagesToStore,
  addConversionMetricsToStore,
  initialiseUserConversionsInStore,
  addUsersAvailabilityToStore,
  addUsersStatusToStore,
  addUsersToStore,
  updateMessageThreadInStore,
  updateStorageUrl,
} from "./StoreCommon";
import ApiService from "../../services/ApiService";
import TokenHelper from "../../helpers/TokenHelper";
import PermissionService from "../../services/PermissionService";

export function apiGetUserView(userToLogin, setUserToLogin, setLoading) {
  const apiService = new ApiService();
  const permissionService = new PermissionService();
  
  apiService
    .getApiResponse("/boom/userView", "GET")
    .then((res) => {
      // returns a message with a UserView array.
      const userView = JSON.parse(res);

      console.log(userView);

      // add the logged in user id to the store.
      addLoggedInUserIdToStore(userView["LoggedInUserId"]);

      // add users to the store.
      // add user status to store.
      addUsersToStore(userView["Users"]);
      addUsersStatusToStore(userView["Users"]);

      addUsersAvailabilityToStore(userView["Users"]);

      // add teams to store
      addTeamsToStore(userView["UserTeams"]);

      // add contacts to store
      addUserContactsToStore(userView["Contacts"]);

      // add statuses to statuses store
      addAvailableStatusToStore(userView["Statuses"]);

      // add selectedTeam to store
      addSelectedTeamToStore(userView["DefaultTeamId"]);

      // add teams to can message teams
      addCanMessageTeams(userView["UserTeams"]);

      // add can message users
      addCanMessageUsers(userView["Users"], userView["LoggedInUserId"]);

      // add settings
      updateStorageUrl(userView["StorageUrl"]);

      // add permissions/settings
      permissionService.setAutoRefreshFeed(userView.AutoRefreshFeed);
      permissionService.setCanAddFeedTickerMessage(userView.CanAddFeedTickerMessage);
      permissionService.setMuteNotifications(userView.MuteNotifications);
      permissionService.setPlayConversionSound(userView.PlayConversionSoundFl);

      // get message center threads
      apiGetDmThreads().catch(() => {
        addSnackbarIssue("Unable to display the Message Centre", "warning");
      });

      // get feed threads
      apiGetFeedThreads().catch(() => {
        addSnackbarIssue("Unable to display the Feed", "warning");
      });

      // get ticker messages
      getTickerMessages().catch(() => {
        addSnackbarIssue("Unable to display Breaking News", "warning");
      });

      // get conversion metrics
      getConversionMetrics().catch(() => {
        addSnackbarIssue("Unable to retrieve conversion metrics", "warning");
      });

      // get user conversion data
      getUserConversionData().catch((error) => {
        addSnackbarIssue("Unable to display user conversion data", "warning");
      });

      getHubConversionData().catch(() => {
        addSnackbarIssue("Unable to display hub conversion data", "warning");
      });

      // identify the user in logrocket
      logRocketIdentifyUser(userView["Users"][0]);

      // Always check we are connected when getting the user view
      checkConnected().then(() => {
        if (userToLogin) {
          callHub("CheckUserIsLoggedIn")
            .then(() => {
              setUserToLogin(false);
              if (setLoading) setLoading(false);
            })
            .catch(() => {
              const tokenHelper = new TokenHelper();
              tokenHelper.removeAuthTokens();
              window.location.href = "/login";
            });
        }
      });
    })
    .catch((err) => {
      //TODO: This snackbar is not going to be displayed. We need a way to store messages so they can be displayed on
      // login screen or show an alert for a serious message and then return the user to the login screen
      addSnackbarIssue(
        "Unable to retreive your user view. Please try logging in again",
        "warning"
      );
      logout();
    });
}

export async function apiGetFeedThreads() {
  const apiService = new ApiService();
  return apiService
    .getApiResponse("/thread/feedthreads", "GET")
    .then((threadList) => {
      addFeedThreads(threadList);
    });
}

export async function apiGetFeedThreadMessages(threadId) {
  const apiService = new ApiService();

  return apiService
    .getApiResponse(
      `/thread/openthread/${threadId}?maxMessages=2147483647`,
      "GET"
    )
    .then((messageArray) => {
      addMessagesToFeedThreadInStore(messageArray.Messages);
    });
}

export async function apiGetDmThreads() {
  const apiService = new ApiService();
  return apiService
    .getApiResponse("/thread/directmessagethreads", "GET")
    .then((threadList) => {
      addMessageThreadsToStore(threadList);
    });
}

export async function apiGetDmThreadMessages(threadId) {
  const apiService = new ApiService();

  return apiService
    .getApiResponse(`/thread/openthread/${threadId}?maxMessages=20`, "GET")
    .then((messageArray) => {
      updateMessageThreadInStore({
        Id: messageArray.ThreadId,
        EarlierMessagesExist: messageArray.EarlierMessagesExist,
      });
      addMessagesToThreadInStore(messageArray.Messages);
    });
}

export async function apiGetEarlierDmThreadMessages(threadId, utcBefore) {
  const apiService = new ApiService();

  return apiService.getApiResponse(
      `/thread/threadmessagesbefore/${threadId}?utcBefore=${utcBefore}&maxMessages=20`,
      "GET"
    )
    .then((threadArray) => {
      updateMessageThreadInStore({
        Id: threadArray.ThreadId,
        EarlierMessagesExist: threadArray.EarlierMessagesExist,
      });
      addMessagesToThreadInStore(threadArray.Messages);
    });
}

export async function apiMarkDmThreadAsDisplayed(threadId) {
  const apiService = new ApiService();

  return apiService.getApiResponse(
      `/thread/markthreadasdisplayed/${threadId}`,
      "POST"
    );
}

export function apiGetEarlierFeedThreads(oldestStoredFeedDate) {
  let date = new Date();
  if (isValidDate(oldestStoredFeedDate)) date = new Date(oldestStoredFeedDate);

  const apiService = new ApiService();

  return apiService
    .getApiResponse(
      `/thread/feedthreadsbefore?utcBefore=${date.toISOString()}`,
      "GET"
    )
    .then((threadArray) => {
      addFeedThreads(threadArray);
    })
    .catch((err) => {
      addSnackbarIssue(
        "Loading earlier posts failed, please try again!",
        "warning"
      );
    });
}

export async function getUserActivityReport(userId, fromDate, toDate) {
  if (!userId) return;
  if (!fromDate) {
    fromDate = yyyymmdd();
  }
  let url = `/report/dailyactivity?userId=${userId}&&date=${fromDate}`;
  if (toDate && toDate !== fromDate) {
    // if the user selects a date range then get the range activity
    url = `/report/dailyactivityrange?userId=${userId}&&fromdate=${fromDate}&&todate=${toDate}`;
  }

  const apiService = new ApiService();

  return apiService.getApiResponse(url, "GET").catch((err) => {
    addSnackbarIssue("Retrieving reports failed, please try again!", "warning");
  });
}

export async function getTickerMessages() {
  const apiService = new ApiService();
  return apiService
    .getApiResponse("/ticker/getmessages", "GET")
    .then((json) => {
      addTickerMessagesToStore(json);
    });
}

export async function getConversionMetrics() {
  const apiService = new ApiService();
  return apiService
    .getApiResponse("/conversion/getmetrics", "GET")
    .then((json) => {
      addConversionMetricsToStore(json);
    });
}

export async function getUserConversionData() {
  const apiService = new ApiService();
  return apiService
    .getApiResponse("/conversion/getuserdata", "GET")
    .then((json) => {
      initialiseUserConversionsInStore(json);
    });
}

export async function getHubConversionData() {
  const apiService = new ApiService();
  return apiService
    .getApiResponse("/conversion/gethubdata", "GET")
    .then((json) => {
      initialiseHubConversionsInStore(json);
    });
}

export async function getLoggedInUserProfile() {
  const apiService = new ApiService();
  return await apiService
    .getApiResponse(`/useradmin/user`, "GET")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      addSnackbarIssue(
        "Error loading user profile. Please try again",
        "warning"
      );
      return { error: true };
    });
}

export async function apiGetAchievementText(id) {
  const apiService = new ApiService();
  return apiService
    .getApiResponse(`/conversion/getachievementtext?achievementId=${id}`, "GET")
    .then((text) => {
      return text;
    });
}

export async function getUserProfile(userId) {
  const apiService = new ApiService();
  return await apiService
    .getApiResponse(`/admin/user/${userId}`, "GET")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      addSnackbarIssue(
        "Error loading user profile. Please try again",
        "warning"
      );
      return { error: true };
    });
}

export async function updateUserProfile(user) {
  const apiService = new ApiService();
  return await apiService.getApiResponse(`/admin/user`, "POST", user);
}

export async function updateSimpleUserProfile(user) {
  const apiService = new ApiService();
  return await apiService.getApiResponse(`/admin/simpleuser`, "POST", user);
}

export async function updateCurrentUserProfile(user) {
  const apiService = new ApiService();

  return await apiService.getApiResponse(`/useradmin/user`, "POST", user);
    
}

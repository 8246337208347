import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import memoize from "lodash.memoize";

const slice = createSlice({
  name: "requestHelp",
  initialState: {
    visible: false,
    requests: [],
    requestingHelp: false,
    requestingHelpGuid: null,
    requestingHelpText: "",
    helpRequestThreadId: null,
  },
  reducers: {
    // actions => action handlers

    requestHelpModalVisiblityUpdated: (requestHelp, action) => {
      return { ...requestHelp, ...{ visible: action.payload } };
    },

    helpAcceptedUpdated: (requestHelp, action) => {
      return { ...requestHelp, ...{ helpAccepted: action.payload } };
    },

    requestingHelpUpdated: (requestHelp, action) => {
      return { ...requestHelp, ...{ requestingHelp: action.payload } };
    },

    requestingHelpTextUpdated: (requestHelp, action) => {
      return { ...requestHelp, ...{ requestingHelpText: action.payload } };
    },

    requestAdded: (requestHelp, action) => {
      const requestId = action.payload.GUID;
      const request = requestHelp.requests.find((r) => r.GUID === requestId);
      if (!request) requestHelp.requests.push(action.payload);
    },

    requestActioned: (requestHelp, action) => {
      const requestId = action.payload;
      const request = requestHelp.requests.find((r) => r.GUID === requestId);

      if (request) request.Actioned = true;
    },

    requestingHelpGuidUpdated: (requestHelp, action) => {
      return { ...requestHelp, ...{ requestingHelpGuid: action.payload } };
    },

    helpRequestThreadIdUpdated: (requestHelp, action) => {
      return { ...requestHelp, ...{ helpRequestThreadId: action.payload } };
    },
  },
});

export const {
  requestingHelpGuidUpdated,
  requestHelpModalVisiblityUpdated,
  helpAcceptedUpdated,
  requestingHelpUpdated,
  requestingHelpTextUpdated,
  requestAdded,
  requestActioned,
  helpRequestThreadIdUpdated,
} = slice.actions;
export default slice.reducer;

export const getShowRequestHelpModal = createSelector(
  (state) => state.ui.requestHelp,
  (requestHelp) =>
    memoize(() => {
      return requestHelp.visible;
    })
);

export const getRequestingHelp = createSelector(
  (state) => state.ui.requestHelp,
  (requestHelp) =>
    memoize(() => {
      return requestHelp.requestingHelp;
    })
);

export const getRequests = createSelector(
  (state) => state.ui.requestHelp,
  (requestHelp) =>
    memoize(() => {
      return requestHelp.requests;
    })
);

export const getRequestedHelpGuid = createSelector(
  (state) => state.ui.requestHelp,
  (requestHelp) =>
    memoize(() => {
      return requestHelp.requestingHelpGuid;
    })
);

export const getHelpRequestThreadId = createSelector(
  (state) => state.ui.requestHelp,
  (requestHelp) =>
    memoize(() => {
      return requestHelp.helpRequestThreadId;
    })
);

export const getRequestingHelpText = createSelector(
  (state) => state.ui.requestHelp,
  (requestHelp) =>
    memoize(() => {
      return requestHelp.requestingHelpText;
    })
);

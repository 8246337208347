import { createSelector, createSlice } from "@reduxjs/toolkit";
import memoize from "lodash.memoize";

const slice = createSlice({
  name: "permissions",
  initialState: {},
  reducers: {
    // actions => action handlers
    permissionAdded: (permissions, action) => {
      // adds a user to the existing user object.
      return { ...permissions, ...action.payload };
    },
  },
});

export const { permissionAdded } = slice.actions;
export default slice.reducer;

export const getPermission = createSelector(
  (state) => state.entities.permissions,
  (permissions) =>
    memoize((name) => {
      return permissions[name];
    })
);

import React from "react";
import {
  Box,
  Toolbar,
  Typography,
  AppBar,
  makeStyles,
  Tooltip,
  Button,
} from "@material-ui/core";
import HeaderSpeedDial from "./components/HeaderSpeedDial";
import Notifications from "../notifications";
import {
  updateRequestHelpVisiblity,
} from "../../components/common/StoreCommon";

export default function Header(props) {
  const classes = useStyles();
  
  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar>
        <Box>
          <HeaderSpeedDial actions={props.actions} />
        </Box>
        <Typography variant="subtitle1" className={classes.title}>
          {props.actions.map((action) => (action.selected ? action.name : ""))}
        </Typography>
        <Box style={{ marginLeft: 20 }}>{props.additional}</Box>
        <Box
          style={{ marginLeft: "auto", display: "flex", flexDirection: "row" }}
        >
          <Tooltip title="New SOS message">
            <Button
              onClick={() => updateRequestHelpVisiblity(true)}
              style={{
                borderRadius: 10,
              }}
            >
              <Typography variant="h6" style={{ color: "red" }}>
                SOS
              </Typography>
            </Button>
          </Tooltip>
          <Notifications />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: "transparent",
    boxShadow: "unset",
    maxHeight: 64,
  },
  title: {
    color: "rgb(135, 131, 131)",
    marginLeft: theme.spacing(3),
  },
  flipHorizontal: {
    transform: "scaleX(-1)",
  },
  sidebarPositionIcon: {
    [theme.breakpoints.only("xs")]: {
      display: "none",
    },
  },
}));

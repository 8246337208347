import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Paper, Box, Avatar, Typography, Divider, IconButton, Tooltip, Fade, makeStyles } from "@material-ui/core";

import StatusIcon from "../user-status";
import StatusSelectModal from "../user-status/components/StatusSelectModal";
import { useMenu } from "../../hooks/useMenu";
import { getLoggedInUserId } from "../../store/hub/loggedInUser";
import { getUserAvailabilityByUserId } from "../../store/hub/userAvailability";
import { getMetric1IdAndValue, getMetric2IdAndValue } from "../../store/hub/userConversionData";
import { getSelectedTimePeriod } from "../../store/ui/conversion";
import UserStats from "./components/UserStats";
import UserOverlay from "./components/UserOverlay";

export default function User({ user, isTeamLeader, size }) {

  const classes = useStyles();
  const [showOverlay, setShowOverlay] = useState(false);
  const loggedInUserId = useSelector(getLoggedInUserId)();
  const { anchor, handleClick, handleClose } = useMenu();
  const userAvailability = useSelector(getUserAvailabilityByUserId)(user.Id);

  const selectedTimePeriod = useSelector((state) => getSelectedTimePeriod(state.ui.conversion));
  const metric1IdAndValue = useSelector((state) => getMetric1IdAndValue(state.entities.userConversionData, user.Id, selectedTimePeriod));
  const metric2IdAndValue = useSelector((state) => getMetric2IdAndValue(state.entities.userConversionData, user.Id, selectedTimePeriod));

  let pluggedIn = false;
  if (userAvailability) {
    pluggedIn = userAvailability.PluggedIn;
  }

  // We use the smaller style card if size is set or if the user does not have any stats configured
  let standardSize = true;
  if (size) {
    standardSize = false;
  } else if (metric1IdAndValue == null) {
    standardSize = false;
  }

  // show the stats if only if the standardSize is being used and metrics have been set
  const showUserStats = standardSize && (metric1IdAndValue != null || metric2IdAndValue != null);
  
  const updateOverlayState = (bool) => {
    setShowOverlay(bool);
  };

  return (
    <Grid key={"userCard-" + user.FullName} item className={classes.userGrid}>
      <Paper className={standardSize ? classes.userPaper : classes.smallerUserPaper}>
        {isTeamLeader ? (
          <Box className={standardSize ? classes.overlay : classes.overlaySmall}>
            <Typography
              className={
                standardSize
                  ? classes.teamManagerBanner
                  : classes.teamManagerBannerSmall
              }
              variant={"subtitle2"}
            >
              Team Manager
            </Typography>
          </Box>
        ) : null}
        <StatusIcon
          handleClick={handleClick}
          userId={user.Id}
          className={classes.statusIcon}
          style={{ zIndex: 100 }}
          isLoggedInUser={user.Id === loggedInUserId}
        />
        <Box
          className={classes.userContainer}
          onMouseEnter={() => updateOverlayState(true)}
          onMouseLeave={() => updateOverlayState(false)}
        >
          <Avatar
            className={standardSize ? classes.user : classes.smallerUser}
            src={user.ImageSrc}
            style={{ filter: !pluggedIn && "grayscale(100%)" }}
          />
          {showOverlay && loggedInUserId !== user.Id
            ? <UserOverlay user={user} standardSize={standardSize} classes={classes} updateOverlayState={updateOverlayState}></UserOverlay>
            : null}
        </Box>
        <Box className={classes.userInfo}>
          <Typography
            variant={standardSize ? "h6" : "body1"}
            className={classes.userName}
          >
            {user.FirstName}
          </Typography>

          {showUserStats && (            
            <UserStats userId={user.Id} metric1IdAndValue={metric1IdAndValue} metric2IdAndValue={metric2IdAndValue}></UserStats>      
          )}

        </Box>
      </Paper>

      {user.Id === loggedInUserId && anchor && (
        <StatusSelectModal anchor={anchor} handleClose={handleClose} />
      )}
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  userContainer: {
    position: "relative",
    width: "fit-content",
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: 5,
    paddingRight: 5,
  },
  userGrid: {
    marginBottom: theme.spacing(3),
  },
  userName: {
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 16,
    },
    [theme.breakpoints.only("xl")]: {
      fontSize: 20,
    },
  },
  teamManagerBanner: {
    [theme.breakpoints.only("xs")]: {
      fontSize: 10,
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 11,
    },
    [theme.breakpoints.only("xl")]: {
      fontSize: 14,
    },
  },
  teamManagerBannerSmall: {
    [theme.breakpoints.only("xs")]: {
      fontSize: 7,
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 8,
    },
    [theme.breakpoints.only("xl")]: {
      fontSize: 10,
    },
  },
  userPaper: {
    [theme.breakpoints.only("xs")]: {
      height: 195,
    },
    [theme.breakpoints.up("sm")]: {
      height: 216,
    },
    [theme.breakpoints.only("xl")]: {
      height: 255,
    },
    width: "auto",
    position: "relative",
    overflow: "hidden",
  },
  smallerUserPaper: {
    [theme.breakpoints.only("xs")]: {
      height: 135,
    },
    [theme.breakpoints.up("sm")]: {
      height: 150,
    },
    [theme.breakpoints.only("xl")]: {
      height: 180,
    },
    width: "auto",
    position: "relative",
    overflow: "hidden",
    boxShadow: "none",
  },
  userPaperHorizontal: {
    width: "auto",
    height: "auto",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  columnSmall: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    [theme.breakpoints.only("xs")]: {
      width: 65,
      height: 65,
    },
    [theme.breakpoints.up("sm")]: {
      width: 72,
      height: 72,
    },
    [theme.breakpoints.only("xl")]: {
      width: 90,
      height: 90,
    },
  },
  column: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    [theme.breakpoints.only("xs")]: {
      width: 83,
      height: 83,
    },
    [theme.breakpoints.up("sm")]: {
      width: 96,
      height: 96,
    },
    [theme.breakpoints.only("xl")]: {
      width: 120,
      height: 120,
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  smallerUser: {
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.only("xs")]: {
      width: 70,
      height: 70,
    },
    [theme.breakpoints.up("sm")]: {
      width: 77,
      height: 77,
    },
    [theme.breakpoints.only("xl")]: {
      width: 90,
      height: 90,
    },
  },
  user: {
    [theme.breakpoints.only("xs")]: {
      height: 85,
      width: 85,
    },
    [theme.breakpoints.up("sm")]: {
      height: 96,
      width: 96,
    },
    [theme.breakpoints.only("xl")]: {
      width: 120,
      height: 120,
    },
    marginLeft: "auto",
    marginRight: "auto",
  },
  userInfo: {
    textAlign: "center",
    color: "rgb(100, 100, 100)",
    display: "flex",
    flexDirection: "column",
  },
  userHover: {
    overflow: "hidden",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: "absolute",
    borderRadius: "50%",
    background: "rgba(20,21,48,.8)",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.only("xs")]: {
      height: 85,
      width: 85,
    },
    [theme.breakpoints.up("sm")]: {
      height: 96,
      width: 96,
    },
    [theme.breakpoints.only("xl")]: {
      width: 120,
      height: 120,
    },
  },
  userHoverSmall: {
    overflow: "hidden",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: "absolute",
    borderRadius: "50%",
    background: "rgba(20,21,48,.8)",
    boxShadow: "inset 0 0 16px 9px rgb(0 0 0 / 25%)",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.only("xs")]: {
      width: 70,
      height: 70,
    },
    [theme.breakpoints.up("sm")]: {
      width: 77,
      height: 77,
    },
    [theme.breakpoints.only("xl")]: {
      width: 90,
      height: 90,
    },
  },
  headerPaperRight: {
    textAlign: "right",
    marginLeft: theme.spacing(1),
    width: "57%",
    color: "rgb(135, 131, 131)",
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  helpIcon: {
    [theme.breakpoints.only("xs")]: {
      height: 13,
      width: 13,
    },
    [theme.breakpoints.up("sm")]: {
      height: 14,
      width: 14,
    },
    [theme.breakpoints.only("xl")]: {
      height: 17,
      width: 17,
    },
    cursor: "pointer",
  },
  overlaySmall: {
    background: "orange",
    color: "white",
    position: "absolute",
    transform: "rotate(315deg)",
    textAlign: "center",
    [theme.breakpoints.only("xs")]: {
      width: 80,
      top: 13,
      left: -19,
    },
    [theme.breakpoints.up("sm")]: {
      width: 88,
      top: 14,
      left: -21,
    },
    [theme.breakpoints.only("xl")]: {
      width: 110,
      top: 17,
      left: -26,
    },
  },
  overlay: {
    background: "orange",
    color: "white",
    position: "absolute",
    transform: "rotate(315deg)",
    textAlign: "center",
    [theme.breakpoints.only("xs")]: {
      width: 108,
      top: 22,
      left: -26,
    },
    [theme.breakpoints.up("sm")]: {
      width: 120,
      top: 24,
      left: -28,
    },
    [theme.breakpoints.only("xl")]: {
      width: 150,
      top: 30,
      left: -35,
    },
  },
  statusIcon: {
    [theme.breakpoints.only("xs")]: {
      fontSize: 22,
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 24,
    },
    [theme.breakpoints.only("xl")]: {
      fontSize: 30,
    },
  },
}));

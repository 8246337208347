import { Grid } from "@material-ui/core";
import { ChevronLeftSharp } from "@material-ui/icons";
import React from "react";
import { useSelector } from "react-redux";
import { getFeedThreadById } from "../../../store/hub/boomFeed";
import {
  getUserNameFromId
} from "../../../components/common/StoreCommon";
import {
  setSelectedFeedThreadIdInStore,
} from "../../../components/common/StoreFeedUi";
import Header from "../../sidebar/components/Header";
import FeedThread from "./FeedThread";

export default function ExpandedFeedThread({ threadId }) {
  const thread = useSelector(getFeedThreadById)(threadId);
  let title = "Feed Post";
  if (thread.CreatedUserId)
    title = `${getUserNameFromId(thread.CreatedUserId).split(" ")[0]}'s Post`;

  return (
    <>
      <Header
        headerText={title}
        includeIcon={true}
        iconTooltip="Back"
        icon={<ChevronLeftSharp />}
        iconOnClick={() => setSelectedFeedThreadIdInStore(null)}
      />
      <Grid
        container
        spacing={0}
        style={{
          overflowY: "scroll",
          height: "auto",
          minHeight: "60vh",
          padding: 10,
        }}
      >
        <Grid item xs={12}>
          {thread ? <FeedThread thread={thread} expanded={true} /> : null}
        </Grid>
      </Grid>
    </>
  );
}

import React from "react";
import { IconButton, Menu } from "@material-ui/core";
import { AccessTime, CalendarToday } from "@material-ui/icons";
import { useMenu } from "../../hooks/useMenu";
import { TimeContainer } from "./time-container/TimeContainer";
import { useDatePicker } from "./useDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider, StaticDatePicker } from "@mui/lab";

export const DatePicker = ({ date, setDate }) => {
  const { showDate, showTime, setShowDate, setShowTime } = useDatePicker();
  const { anchor, handleClick, handleClose } = useMenu();

  return (
    <div style={{ height: 30, width: "inherit", position: "relative" }}>
      <input
        style={{
          width: "-webkit-fill-available",
          height: 30,
          paddingRight: 70,
        }}
        type="text"
        value={new Date(date).toLocaleString()}
        onChange={() => null}
      />
      <div style={{ position: "absolute", right: 5, top: 3 }}>
        <IconButton
          size="small"
          onClick={(e) => {
            handleClick(e);
            setShowTime(false);
            setShowDate(true);
          }}
        >
          <CalendarToday style={{ color: "grey" }} />
        </IconButton>
        <IconButton
          size="small"
          onClick={(e) => {
            handleClick(e);
            setShowTime(true);
            setShowDate(false);
          }}
        >
          <AccessTime style={{ color: "grey" }} />
        </IconButton>
      </div>

      <Menu
        anchorEl={anchor}
        keepMounted
        // anchorOrigin={{ vertical: "bottom" }}
        // transformOrigin={{ vertical: "bottom" }}
        open={Boolean(anchor)}
        onClose={handleClose}
        style={{ width: "auto" }}
      >
        {showTime && (
          <TimeContainer
            handleClose={handleClose}
            setDate={setDate}
            date={date}
          />
        )}
        {showDate && (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StaticDatePicker
              value={date}
              onChange={(value) => {
                setDate(value);
                handleClose();
              }}
              disablePast
              renderInput={() => null}
            />
          </LocalizationProvider>
        )}
      </Menu>
    </div>
  );
};

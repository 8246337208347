import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Paper, Typography, Box, Divider, makeStyles, Avatar } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { Skeleton } from "@material-ui/lab";
import Icon from '@material-ui/core/Icon'

import { yyyymmdd, getBackgroundColorStyle} from "../../../components/common/Common";
import Reports from "./Reports";
import { getUserActivityReport } from "../../../components/common/ApiMiddleware";
import { getUserAvailabilityByUserId } from "../../../store/hub/userAvailability";
import { getMetric } from "../../../store/hub/conversionMetrics";

export default function ConversionWidgets({ user, date }) {

  const classes = useStyles();
  const [report, setReport] = useState(null);
  const [chosenDate, setChosenDate] = useState(date);
  const [loaded, setLoaded] = useState(false);
  const [storedUser, setStoredUser] = useState(user);
  const [conversionRows, setConversionRows] = useState([]);
  const userAvailability = useSelector(getUserAvailabilityByUserId)(user.Id);
  
  useEffect(() => {
    if (!report || date !== chosenDate || user !== storedUser) {
      if (loaded) setLoaded(false);
      getUserActivityReport(user.Id, yyyymmdd(date[0]), yyyymmdd(date[1]))
        .then((json) => {
          setReport(json);
          addConversionData(json);
          setLoaded(true);
        })
        .catch((error) => {
          setLoaded(true);
        });

      if (user !== storedUser) setStoredUser(user);
      setChosenDate(date);
    }
  }, [
    setChosenDate,
    chosenDate,
    date,
    report,
    user,
    loaded,
    setLoaded,
    storedUser,
    setStoredUser,
  ]);

  const addConversionData = (json) => {
    let rows = [];
    json.ConversionMetrics.forEach((item) => {
      rows.push( { metricId: item.Id, value: item.Value } );
    });
    setConversionRows(rows);
  };

  return (
    <Grid item xs={12}>
      <Grid container spacing={2} className={classes.paperContainer}>
        <Grid key="user-paper" item className={classes.topPaper}>
          <Paper className={classes.userPaper}>
            <Box className={classes.paperHeader}>
              <Avatar
                alt={user.FullName}
                src={user.ImageSrc}
                className={classes.avatarLarge}
              />
              <Box className={classes.headerPaperRight}>
                <Typography
                  style={{ marginBottom: 0 }}
                  variant="subtitle2"
                  gutterBottom
                >
                  Status
                </Typography>
                <Box className={classes.headerPaperMainText}>
                  <FiberManualRecordIcon
                    style={{
                      marginTop: "auto",
                      marginBottom: "auto",
                      color: userAvailability.PluggedIn ? "limegreen" : "red",
                    }}
                  />
                  <Typography
                    style={{
                      color: "rgb(100, 100, 100)",
                      marginTop: "auto",
                      marginBottom: "auto",
                    }}
                    variant="h5"
                    gutterBottom
                  >
                    {userAvailability.PluggedIn ? "Online" : "Offline"}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Divider />
            <Box className={classes.paperFooter}>
              <PersonIcon />
              <Typography variant="subtitle1" gutterBottom>
                {user.FullName}
              </Typography>
            </Box>
          </Paper>
        </Grid>
        {report ? (
          <>
            {conversionRows
              .map((row) => (
                <ConversionRow key={row.metricId} metricId={row.metricId} value={row.value} classes={classes} loaded={loaded}/>
              ))}
          </>
        ) : null}

      </Grid>
      <Reports report={report} loaded={loaded} />
    </Grid>
  );
}

function ConversionRow(props) {

  const metric = useSelector((state) => getMetric(state.entities.conversionMetrics, props.metricId));

  return (
    <Grid key={`user-paper-${props.metricId}`} item className={props.classes.topPaper}>
      <Paper className={props.classes.userPaper}>
        <Box className={props.classes.paperHeader}>

        <Avatar className={props.classes.avatarLarge}
          style={getBackgroundColorStyle(metric.color)}
        >
          <Icon>{metric.icon}</Icon>
        </Avatar>
        <Box className={props.classes.headerPaperRight}>
          <Typography
            style={{ marginBottom: 0 }}
            variant="subtitle2"
            gutterBottom
          >
            {metric.tooltipText}
          </Typography>
          <Box>
            <Typography
              style={{
                color: "rgb(100, 100, 100)",
              }}
              variant="h5"
              gutterBottom
            >
              {props.loaded ? (
                 props.value
              ) : (
                <Skeleton variant="text" width={100} />
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    [theme.breakpoints.up("xs")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
    },
  },
  userPaper: {
    height: 100,
    padding: theme.spacing(2),
    color: "rgb(135, 131, 131)",
    [theme.breakpoints.up("xs")]: {
      // width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
  avatarLarge: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    display: "absolute",
    top: -40,
    marginRight: 10,
    boxShadow:
      "0 10px 30px -12px rgb(0 0 0 / 22%), 0 4px 25px 0px rgb(0 0 0 / 5%), 0 8px 10px -5px rgb(0 0 0 / 10%)",
  },
  paperHeader: {
    height: theme.spacing(9),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  paperFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    height: theme.spacing(4),
    paddingTop: theme.spacing(1),
    color: "rgb(135, 131, 131)",
  },
  topPaper: {
    marginBottom: theme.spacing(3),
  },
  headerPaperRight: {
    textAlign: "right",
  },
  headerPaperMainText: {
    display: "flex",
    flexDirection: "row",
  },
}));

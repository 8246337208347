import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "conversionMetrics",
  initialState: [],
  reducers: {
    
    metricsInitialised: (state, action) => {

      action.payload.data.forEach(metric => {

        const existingMetric = state.find(o => o.id === metric.Id);
        if (!existingMetric) {

          state.push( { 
            id: metric.Id,
            name: metric.Name,
            code: metric.Code,
            tooltipText: metric.TooltipText,
            icon: metric.Icon,
            color: metric.Color,
            dailyFl: metric.DailyFl,
            dailyIsDefaultFl: metric.DailyIsDefaultFl,
            weeklyFl: metric.WeeklyFl,
            weeklyIsDefaultFl: metric.WeeklyIsDefaultFl,
            monthlyFl: metric.MonthlyFl,
            monthlyIsDefaultFl: metric.MonthlyIsDefaultFl,
          } );
        }
      });
      
    },

  }
});

export const { metricsInitialised } = slice.actions;
export default slice.reducer;

export const getMetric = (state, id) => 
{
  const metric = state.find(cm => cm.id === id);
  if (!metric) return null;
  return metric;
}

import { makeStyles } from "@material-ui/core";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Fade,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { acceptHelpRequest } from "../../../components/common/ConnectionMiddleware";
import NotificationHelper from "../../../core/NotificationHelper";
import { getRequests } from "../../../store/ui/requestHelp";
import {
  actionRequest,
  getUserImage,
  getUserNameFromId,
} from "../../../components/common/StoreCommon";

export default function Requests() {
  const requests = useSelector(getRequests)();
  const open = Boolean(requests.filter((r) => r.Actioned === false).length > 0);

  return (
    <Modal
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      anchorEl={requests.filter((r) => r.Actioned === false).length > 0}
      open={open}
      BackdropProps={{
        timeout: 500,
        style: { background: "rgba(50,50,50,0.5)" },
      }}
      aria-labelledby="Help Requests"
      aria-describedby="All available requests for help are shown here"
    >
      <Fade in={open}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 4,
            flexWrap: "wrap",
          }}
        >
          {open &&
            requests.map((request) => {
              if (!request.Actioned)
                return <Request key={request.GUID} request={request} />;
              return null;
            })}
        </div>
      </Fade>
    </Modal>
  );
}

function Request({ request }) {
  const classes = useStyles();
  const [seconds, setSeconds] = React.useState(0);

  // get notification system helper to stop playing the notification sound upon
  // accept or decline
  const notificationHelper = new NotificationHelper();

  // play the notification sound. As seconds are updated each second the notification
  // sound will continue to play until it is actioned.
  notificationHelper.playSOSNotificationSound();

  React.useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(seconds + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds]);

  return (
    <Paper className={classes.paper}>
      <Avatar
        src={getUserImage(request.UserId)}
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          width: 120,
          height: 120,
        }}
      />
      <Typography variant="h5" style={{ textAlign: "center" }}>
        {getUserNameFromId(request.UserId, true)} needs help!
      </Typography>

      <Divider />
      <Box
        style={{
          textAlign: "center",
          background: "whitesmoke",
          color: "gray",
          minHeight: 200,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">{request.Message}</Typography>
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress style={{ width: 60, height: 60 }} />
        <Typography variant="h6">Waiting for: {seconds}s</Typography>
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 4,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Button
          variant="contained"
          style={{ background: "red", color: "white" }}
          onClick={() => {
            actionRequest(request.GUID);
            notificationHelper.stopSOSNotificationSound();
          }}
        >
          Decline
        </Button>
        <Button
          variant="contained"
          style={{ background: "green", color: "white" }}
          onClick={() => {
            acceptHelpRequest(request);
            notificationHelper.stopSOSNotificationSound();
          }}
        >
          Accept
        </Button>
      </Box>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    height: "auto",
    width: "500px",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    borderRadius: 10,
    overflow: "auto",
    position: "relative",
    maxHeight: "80vh",
    gap: 16,
  },
}));

import {
  TextField,
  Typography,
  makeStyles,
  Box,
  Modal,
  Fade,
  IconButton,
} from "@material-ui/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  getNewMessageBool,
  getNewMessageRecipients,
} from "../../../store/ui/messageCentre";
import {
  addSnackbarIssue,
  closeNewMessageBox,
  updateNewMessageUi,
} from "../../../components/common/StoreCommon";
import RecipientAutocomplete from "./RecipientAutocomplete";
import { getMessageThreads } from "../../../store/hub/message";
import MyEditor from "../../text-editor";
import { Close } from "@material-ui/icons";
import {
  hubCreateDmThreadForAllUsers,
  hubCreateDmThreadForUsers,
  hubCreateDmThreadForTeams,
} from "../../../components/common/ConnectionMiddleware";

export default function NewMessage() {
  const classes = useStyles();
  const [subject, setSubject] = useState("");
  const newMessage = useSelector(getNewMessageBool)();
  const open = Boolean(newMessage);
  const recipients = useSelector(getNewMessageRecipients)();
  const [error, setError] = useState("");
  const messageThreads = useSelector(getMessageThreads)();

  const messageContentIsValid = (messageContent) => {
    const parsedMessage = JSON.parse(messageContent);

    if (parsedMessage.Blocks.length > 1) {
      return true;
    }

    if (
      parsedMessage.Blocks[0]["BlockValue"] === "" ||
      parsedMessage.Blocks[0]["BlockValue"] === "--newline--"
    ) {
      return false;
    }

    return true;
  };

  const messageIsValid = async (messageContent) => {
    if (!messageContentIsValid(messageContent)) {
      setError("Please enter a message");
      throw new Error("Invalid message");
    }

    if (recipients.length < 1) {
      setError("Please choose a recipient");
      throw new Error("No recipients");
    }
  };

  const getRecipientType = () => {
    let other = false;
    let hubs = false;
    let users = false;

    for (let i = 0; i < recipients.length; i++) {
      if (recipients[i].Type === "Hubs") {
        hubs = true;
      } else if (recipients[i].Type === "Users") {
        users = true;
      } else if (recipients[i].Type === "Other") {
        other = true;
      } else {
        throw new Error("Invalid recipent type");
      }
    }

    if (other) return "Other";
    if (hubs) return "Hubs";
    if (users) return "Users";
    throw new Error("Invalid recipent type");
  };

  const callHubToSendMessage = async (
    messageContent,
    mentionedUsers,
    attachments
  ) => {
    
    const recipentType = getRecipientType();
    if (recipentType === "Other") {
      return hubCreateDmThreadForAllUsers(
        subject,
        messageContent,
        attachments
      );
    }

    if (recipentType === "Users") {
      return hubCreateDmThreadForUsers(
        getRecipientIds(),
        subject,
        messageContent,
        attachments
      );
    }

    if (recipentType === "Hubs") {
      return hubCreateDmThreadForTeams(
        getRecipientIds(),
        subject,
        messageContent,
        attachments
      );
    }

    throw new Error("Invalid operation");
  };

  const getRecipientIds = () => {
    const recipentType = getRecipientType();
    if (recipentType === "Other") throw new Error("Invalid operation");

    let recipientIds = [];

    for (let i = 0; i < recipients.length; i++) {
      if (recipients[i].Type === recipentType) {
        recipientIds.push(parseInt(recipients[i].Id));
      }
    }

    return recipientIds;
  };

  // onSubmit must return a promise
  // If an error occurs we show the error and throw an Error so the promise is rejected
  const onSubmit = (messageContent, mentionedUsers, attachments) => {
    return messageIsValid(messageContent)
      .then(() => {
        return callHubToSendMessage(messageContent, mentionedUsers, attachments)
          .then(() => {
            setSubject("");
            closeNewMessageBox();
          })
          .catch(() => {
            addSnackbarIssue(
              "Unable to add the message, please try again",
              "warning"
            );
            throw new Error("Failed to add message");
          });
      })
      .catch(() => {
        throw new Error("Invalid message");
      });
  };

  const inputProps = {
    style: {
      height: 40,
    },
  };

  return (
    <>
      <Modal
        anchorEl={newMessage}
        open={open}
        style={{
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
        BackdropProps={{
          timeout: 500,
          style: { background: "rgba(50,50,50,0.5)" },
        }}
      >
        <Fade in={open}>
          <Box className={classes.paper}>
            <Box className={classes.row}>
              <Typography variant="body1">New Message</Typography>
              <IconButton
                style={{ width: 30, height: 30 }}
                onClick={() => updateNewMessageUi()}
              >
                <Close />
              </IconButton>
            </Box>

            <Box style={{ margin: "10px 0" }}>
              <RecipientAutocomplete recipients={recipients} />
            </Box>
            <Box style={{ margin: "10px 0" }}>
              <TextField
                InputProps={inputProps}
                value={subject}
                variant="outlined"
                onChange={(e) => setSubject(e.target.value)}
                style={{ width: "100%" }}
                placeholder="Subject"
              />
            </Box>
            <Box
              style={{
                margin: "10px 0",
                height: "auto",
              }}
            >
              <MyEditor
                onSubmit={onSubmit}
                toolbarPosition={"bottom"}
                resetError={setError}
                disableMentions={true}
                sendButtonPosition={"bottom"}
                showInsertImage={true}
                editorHeight={350}
              />
            </Box>
            {error !== "" ? (
              <Typography variant="caption" style={{ color: "red" }}>
                {error}
              </Typography>
            ) : null}
          </Box>
        </Fade>
      </Modal>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    background: "white",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    [theme.breakpoints.only("xs")]: {
      width: "100vw",
      height: "100vh",
    },
    [theme.breakpoints.up("sm")]: {
      borderRadius: 10,
      width: "500px",
      height: "auto",
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));

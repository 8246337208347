import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import memoize from "lodash.memoize";

const slice = createSlice({
  name: "canMessageTeams",
  initialState: [],
  reducers: {
    // actions => action handlers

    canMessageTeamsAdded: (teams, action) => {
      // adds a user to the existing canMessageTeams object.
      action.payload.teams.forEach((team) => {
        let canMessageTeam = {
          Id: team.Id,
          Name: team.Name,
          Type: "Hubs",
        };

        const index = teams.findIndex(
          (storedTeam) => storedTeam.Id === team.Id
        );
        if (index >= 0) {
          teams.splice(index, 1, { ...teams[index], ...canMessageTeam });
        } else {
          teams.push(canMessageTeam);
        }
      });

      teams.sort((a, b) => (a.Name > b.Name ? 1 : -1));
    },
  },
});

export const { canMessageTeamsAdded } = slice.actions;
export default slice.reducer;

export const getCanMessageTeams = createSelector(
  (state) => state.entities.canMessageTeams,
  (canMessageTeams) =>
    memoize(() => {
      // returns all teams that the logged in user can message
      return canMessageTeams;
    })
);

export const getCanMessageTeamById = createSelector(
  (state) => state.entities.canMessageTeams,
  (canMessageTeams) =>
    memoize((id) => {
      // returns all teams that the logged in user can message
      return canMessageTeams.filter((team) => team.Id === id)[0];
    })
);

import {
  createNewNotification,
  IsJsonString,
  openFeedThread,
  openMessageThread,
  removeNewLineFromString,
  uuidv4,
  removeAuthTokensAndRedirectToLogin,
} from "./Common";
import { callHub } from "./Connection";
import NotificationService from "../../services/NotificationService";
import {
  actionRequest,
  addMessageToFeedThreadInStore,
  addMessageToThreadInStore,
  addSnackbarIssue,
  getFeedThreadByIdFromStore,
  loggedInUserId,
  updateFeedThreadInStore,
  updateMessageThreadInStore,
  storeLogout,
  updateRequestingHelpGuid,
} from "./StoreCommon";
import {
  getSelectedFeedThreadIdFromStore,
} from "./StoreFeedUi";
import UiService from "../../services/UiService";
import ApiService from "../../services/ApiService";
import ThreadHelper from "../../core/ThreadHelper";

export function logout(returnDateTime) {

  console.log("Hub logged user out");
  // // send off action to store.
  storeLogout();
  // send log off status update
  hubUpdateStatus("LoggedOff", returnDateTime).then((res) => {
    addSnackbarIssue(
      `Logged out successfully${
        returnDateTime && ` returning at: ${returnDateTime}`
      }`,
      "success"
    );
  });
  removeAuthTokensAndRedirectToLogin();
}
export async function hubUpdateStatus(statusCode, DateTime) {
  return callHub("UpdateStatus", statusCode, DateTime);
}

export async function hubAddThreadMessage(
  threadId,
  message,
  mentionedUsers,
  attachments
) {
  return callHub(
    "AddThreadMessage",
    threadId,
    message,
    mentionedUsers,
    attachments
  );
}

export async function hubCreateFeedThread(
  message,
  mentionedUsers,
  attachments,
  tickerMessage,
  achievementId
) {
  return callHub(
    "CreateFeedThread",
    message,
    mentionedUsers,
    attachments,
    tickerMessage,
    achievementId
  );
}

export async function hubLikeFeedThread(id) {
  return callHub("LikeFeedThread", id);
}

export async function hubCreateDmThreadForAllUsers(
  title,
  message,
  attachments
) {
  return callHub(
    "CreateDirectMessageThreadForAllUsers",
    title,
    message,
    attachments
  );
}

export async function hubCreateDmThreadForUsers(
  userIds,
  title,
  message,
  attachments
) {
  return callHub(
    "CreateDirectMessageThreadForUsers",
    userIds,
    title,
    message,
    attachments
  );
}

export async function hubCreateDmThreadForTeams(
  teamIds,
  title,
  message,
  attachments
) {
  return callHub(
    "CreateDirectMessageThreadForTeams",
    teamIds,
    title,
    message,
    attachments
  );
}

export async function hubArchiveDmThread(threadId) {
  return callHub("ArchiveDirectMessageThread", threadId);
}

export async function hubLeaveDmThread(threadId) {
  return callHub("LeaveDirectMessageThread", threadId);
}

// This function gets calls when the hub receieves a NewThreadMessage hub message
export function updateFeedThread(message) {
  
  // TODO: What should we do if the message is not valod
  // We seem to carry on anyway
  let threadContent = null;
  let latestMessage = message.JsonContent;
  // check if valid Json String
  if (IsJsonString(latestMessage))
    threadContent = JSON.parse(latestMessage).Blocks[0]["BlockValue"];

  if (getSelectedFeedThreadIdFromStore() === message.ThreadId) {
    // If the message belongs to the thread which is currently open we call addMessageToFeedThreadInStore
    // which simplies adds the message to the list being displayed
    addMessageToFeedThreadInStore(message);
  } else {

    // get the thread from the store
    const thread = getFeedThreadByIdFromStore(message.ThreadId);

    // we may not have the thread in which case ignore
    if (thread) {

      const createdByLoggedInUser = thread.CreatedUserId === loggedInUserId();
      const isTargetUser = thread.TargetUserId === loggedInUserId();

      // If the logged in user created the the thread or is the target of the thread then add  notification
      if (createdByLoggedInUser || isTargetUser) {
      
        const notificationService = new NotificationService();

        notificationService.createNotificationForFeedThreadComment(
          message,
          loggedInUserId(),
          () => openFeedThread(message.ThreadId)
        )
          
      }

      // if the user adding the message is the logged in user and the thread can be closed then automatically close
      if (message.UserId === loggedInUserId() &&
      thread.CanCloseFl === true &&
      thread.ClosedFl === false) {
        const uiService = new UiService();
        uiService.closeFeedThread(thread.Id);
      }

    }
  }

  // Update the latest message and message count for the thread in store
  updateFeedThreadInStore(message);

}

export function updateMessageThread(message) {
  
  const uiService = new UiService();
  const apiService = new ApiService();
  const threadHelper = new ThreadHelper();

  // If this is a message for the current help request (which would be on screen)
  // or for the current thread open and visible in the message centre:
  // add and mark as read
  if (uiService.isThreadIdCurrentHelpRequest(message.ThreadId) ||
      uiService.isThreadIdOpenInMessageCentreAndVisisble(message.ThreadId)) 
  {  
    // The message for the user who sent the message will have Unread set to true
    // so we can just add to the store
    // If we are a recipient we have to modify the message and call the server
      // to state it has been read
    if (message.Read === false) {
      
      message.Read = true;
      apiService.markThreadMessageAsRead(message.Id)
        .catch((err) => {
          addSnackbarIssue(
            "There was an issue updating the message",
            "warning")
          });
    }
    
    addMessageToThreadInStore(message);
    updateMessageThreadInStore({
      Id: message.ThreadId,
      LatestMessage: message,
      HasUnreadMessages: false,
    });
    
    return;
  }

  // If here then this is a thread message (not a help request)
  // If this is the current thread it is open but not visible
  // We close the message so the notifications and unread counts act the 
  // same as for other threads

  if (uiService.isThreadIdOpenInMessageCentre(message.ThreadId) === true) {
    // close thread
    uiService.closeDmThread();
  }

  const notificationService = new NotificationService();
  notificationService.createNotificationForNewDirectMessageThread(
    message.UserId,
    loggedInUserId(),
    message.ThreadId,
    threadHelper.getPostPreviewText(message.JsonContent),
    () => openMessageThread(message.ThreadId),
    message.UtcCreatedDateTime
  );

  updateMessageThreadInStore({
    Id: message.ThreadId,
    LatestMessage: message,
    HasUnreadMessages: true,
  });
    
}

export function requestHelp(message) {
  // create guid
  const guid = uuidv4();

  callHub("RequestHelp", guid, message).then((res) => {
    // store guid for requesting user to use later
    // guid is stored on success of hub call so it is not stored
    // and deleted upon failure
    updateRequestingHelpGuid(guid);
  });
}

export function acceptHelpRequest(message) {
  actionRequest(message.GUID);
  message = JSON.stringify(message);
  callHub("RequestHelpAccepted", message);
}

export function requestHelpCancelled(message) {
  message = JSON.stringify(message);
  callHub("RequestHelpCancelled", message);
}

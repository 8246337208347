import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  IconButton,
  Badge,
  Menu,
  MenuItem,
  Box,
  Avatar,
  makeStyles,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import { addSnackbarIssue, getStore } from "../../components/common/StoreCommon";
import { getUserNameById, getUserImageById } from "../../store/hub/users";
import { getNotifications, getUnreadNotificationsCount } from "../../store/ui/notifications";
import { convertDateTime } from "../../components/common/Common";
import BuzzHubsIcon from "../../assets/images/logo_small.png";
import BrowserHelper from "../../core/BrowserHelper";
import NotificationService from "../../services/NotificationService";
import UiService from "../../services/UiService";

export default function Notifications() {

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [subMenu, setSubMenu] = useState(null);
  const [muteMenu, setMuteMenu] = useState(false);
  const notifications = useSelector(getNotifications)();
  const unreadCount = useSelector(getUnreadNotificationsCount)();
  
  const browserHelper = new BrowserHelper();
  const notificationService = new NotificationService();
  const uiService = new UiService();

  const handleClick = (event) => {
    markAllAsRead();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubMenuClick = (event) => {
    setSubMenu(event.currentTarget);
  };

  const handleSubMenuClose = () => {
    setSubMenu(null);
  };

  const markAllAsRead = () => {
    // close the menu
    handleSubMenuClose();

    // update notifications to read
    notificationService.markAllAsRead();
  };

  const clearNotifications = () => {
    // close the menu
    handleSubMenuClose();

    // clear all notifications
    notificationService.clearAll();
  };

  const toggleMuteMenu = () => {
    setMuteMenu(!muteMenu);
  }

  const muteNotifications = (timeInMinutes) => {
    // close the menu
    handleSubMenuClose();
    setMuteMenu(false);
    notificationService.mute(timeInMinutes);
  };

  const handleNotificationClick = (notification, action) => {
    handleClose();
    notificationService.markAsRead(notification.Id);
    var result = action();
    // if result == false then the thread no longer exists (e.g. expired thread)
    if (result === false) {
      addSnackbarIssue("The thread no longer exists", "warning");
    }
  };

  React.useEffect(() => {

    if (!uiService.loggedInUserId()) return;

    // check browser notifications are enabled (once)
    if (window.Notification && uiService.browserPermissionsChecked() === false) {

      // if browser notifications are not enabled, ask the user to enable them
      if (window.Notification.permission === "default") {
        window.Notification.requestPermission();
      }

      // if browser notifications are denied, ask the user to enable them
      if (window.Notification.permission === "denied") {
        // add a new notification asking the user to enable browser notifications
        notificationService.addEnableNotificationsMeessage();
      }

      uiService.updateBrowserPermissionsChecked(true);
    }

    // update browser title to include notification count at the start of the title removing any existing count from the title
    browserHelper.updateBrowserTitle();
  });

  return (
    <>
      <IconButton
        aria-controls="notifications-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Badge badgeContent={unreadCount} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.menu}
      >
        <Box className={classes.menuHeader}>
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            Notifications
            {notificationService.isMuted() === true ? (
              <Tooltip title="Unmute Notifications" style={{ marginLeft: 10 }}>
                <IconButton
                  size="small"
                  onClick={() => {
                    handleClose();
                    notificationService.unmute();
                  }}
                >
                  <NotificationsOffIcon style={{ color: "rgb(255,165,0)" }} />
                </IconButton>
              </Tooltip>
            ) : null}
          </Typography>
          <IconButton size="small" onClick={handleSubMenuClick}>
            <MoreHorizIcon />
          </IconButton>
        </Box>
        <Box className={classes.menuItemsContainer}>
          {notifications.length >= 1 ? (
            notifications.map((notification) => {

              let userImage = getUserImageById(getStore().getState())(notification.UserId);
              if (userImage === "BuzzHubs") userImage = BuzzHubsIcon;   

              return (
                <MenuItem
                  key={"notification-" + notification.Id}
                  onClick={() => handleNotificationClick(notification, notification.Action)}
                >
                  <Box xs="12" className={classes.menuItem}>

                  <Box className={classes.menuItemAvatar}>
                      <Avatar
                        src={userImage}
                        style={{ width: 60, height: 60 }}
                      />
                    </Box>

                    <Box className={classes.menuTextContainer}>
                      <Typography
                          variant="body1"
                          className={classes.menuItemText}
                          style={{
                            color: notification.Read
                              ? "rgb(100,100,100)"
                              : "black",
                            maxHeight: notification.FullHeight
                              ? "none"
                              : "3em",
                          }}
                        >
                          {notification.Title + ": " + notification.Content}
                      </Typography>
                      <Typography
                        variant="caption"
                        className={
                          notification.Read
                            ? classes.dateRead
                            : classes.dateUnread
                        }
                      >
                        {convertDateTime(notification.DateTimeReceived)}
                      </Typography>
                    </Box>

                    {notification.Read ? null : (
                      <FiberManualRecordIcon className={classes.unreadIcon} />
                    )}  

              
                  </Box>
                </MenuItem>
              )
            })
          )
          : (
            <Typography variant="body1" className={classes.noNotifications}>
              No new notifications
            </Typography>
          )}
        </Box>
      </Menu>
      <Menu
        anchorEl={subMenu}
        keepMounted
        open={Boolean(subMenu)}
        onClose={handleSubMenuClose}
        className={classes.subMenu}
      >
        <MenuItem onClick={clearNotifications} className={classes.subMenuItem}>
          <Clear style={{ marginRight: 10 }} />
          <Typography variant="body1">Clear all notifications</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => toggleMuteMenu()}
          className={classes.subMenuItem}
        >
          <NotificationsOffIcon style={{ marginRight: 10 }} />
          <Typography variant="body1">Mute notifications</Typography>
        </MenuItem>
        {muteMenu ? (
          <>
            <MenuItem
              onClick={() => muteNotifications(30)}
              className={classes.subMenuItem}
            >
              <Typography variant="body2">30 Minutes</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => muteNotifications(60)}
              className={classes.subMenuItem}
            >
              <Typography variant="body2">1 Hour</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => muteNotifications(240)}
              className={classes.subMenuItem}
            >
              <Typography variant="body2">4 Hours</Typography>
            </MenuItem>
            <MenuItem
              onClick={() =>
                muteNotifications(
                  1440 - (new Date().getHours() * 60 + new Date().getMinutes())
                )
              }
              className={classes.subMenuItem}
            >
              <Typography variant="body2">Remainder of today</Typography>
            </MenuItem>
          </>
        ) : null}
      </Menu>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  menu: {
    whiteSpace: "normal",
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(4),
      marginLeft: -40,
    },
  },
  subMenu: {
    whiteSpace: "normal",
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(6),
      marginLeft: -50,
    },
  },
  menuHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0 15px",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.only("xs")]: {
      width: "70vw",
    },
    [theme.breakpoints.up("sm")]: {
      width: "350px",
    },
  },
  menuItemsContainer: {
    [theme.breakpoints.only("xs")]: {
      maxHeight: "50vh",
    },
    [theme.breakpoints.up("sm")]: {
      maxHeight: "60vh",
    },
    overflowY: "scroll",
  },
  menuItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    width: "100%",
    [theme.breakpoints.only("xs")]: {
      maxWidth: "70vw",
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "350px",
    },
  },
  subMenuItem: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "10px",
    marginRight: "10px",
    borderRadius: "3px",
    [theme.breakpoints.only("xs")]: {
      width: "65vw",
    },
    [theme.breakpoints.up("sm")]: {
      width: "300px",
    },
  },
  menuTextContainer: {
    display: "flex",
    flexDirection: "column",
    width: "-webkit-fill-available",
  },
  menuItemText: {
    whiteSpace: "normal",
    width: "250px",
    marginRight: theme.spacing(2),
    marginTop: "auto",
    marginBottom: "auto",
    display: "block",
    textOverflow: "ellipsis",
    wordWrap: "break-word",
    overflow: "hidden",
    lineHeight: "1.5em",
  },
  menuItemAvatar: {
    marginRight: theme.spacing(2),
  },
  noNotifications: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  unreadIcon: {
    fontSize: 17,
    color: "#14a2e6",
    marginTop: "auto",
    marginBottom: "auto",
  },
  dateRead: {
    fontWeight: "normal",
    color: "inherit",
  },
  dateUnread: {
    fontWeight: "bold",
    color: "#14a2e6",
  },
}));

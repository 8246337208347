import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import memoize from "lodash.memoize";

const slice = createSlice({
  name: "activityReports",
  initialState: {
    users: [],
    statuses: [],
  },
  reducers: {
    // actions => action handlers
    activityReportsUserAdded: (activityReports, action) => {
      // adds the activity reports to the existing activityReports object.
      const index = activityReports.users.findIndex(
        (user) => user.userId === action.payload.userId
      );
      if (index >= 0) activityReports.users.splice(index, 1, action.payload);
      else activityReports.users.push(action.payload);
    },
    activityReportStatusesAdded: (activityReports, action) => {
      // adds the activity reports to the existing activityReports object.
      return { ...activityReports, statuses: action.payload.statuses };
    },
    activityReportReset: () => {
      // resets activity report to initial state.
      return { users: [], statuses: [] };
    },
  },
});

export const {
  activityReportsUserAdded,
  activityReportStatusesAdded,
  activityReportReset,
} = slice.actions;
export default slice.reducer;

export const getActivityReportsById = createSelector(
  (state) => state.entities.activityReports,
  (activityReports) =>
    memoize((id) => {
      return activityReports.users.filter((report) => report.Id === id)[0];
    })
);

export const getActivityReportsUsers = createSelector(
  (state) => state.entities.activityReports.users,
  (users) =>
    memoize(() => {
      return users;
    })
);

export const getActivityReportStatuses = createSelector(
  (state) => state.entities.activityReports,
  (activityReports) =>
    memoize(() => {
      return activityReports.statuses;
    })
);

import { makeStyles } from "@material-ui/core";
import { Modal, Fade, Paper } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { getHelpThreadByGUID } from "../../../store/hub/message";
import ExpandedThread from "../../message-centre/components/ExpandedThread";

export default function HelpThread({ threadId }) {
  const thread = useSelector(getHelpThreadByGUID)(threadId);
  const open = Boolean(thread);
  const classes = useStyles();

  return (
    <Modal
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      anchorel={thread}
      open={open}
      BackdropProps={{
        timeout: 500,
        style: { background: "rgba(50,50,50,0.5)" },
      }}
      aria-labelledby="Request Help"
      aria-describedby="Request immediate help from a selected group of users"
    >
      <Fade in={open}>
        <Paper className={classes.paper}>
          {thread && (
            <ExpandedThread thread={thread} toolbarType="HelpRequest" />
          )}
        </Paper>
      </Fade>
    </Modal>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "500px",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    borderRadius: 10,
    overflow: "auto",
    position: "relative",
    height: "80vh",
  },
}));

import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import memoize from "lodash.memoize";

const slice = createSlice({
  name: "userAvailability",
  initialState: [],
  reducers: {
    // actions => action handlers
    userAvailabilitiesAdded: (userAvailability, action) => {
      // adds all user availabilities
      action.payload.map((availability) => {
        userAvailability.push(availability);
        return null;
      });
    },
    userAvailabilityUpdated: (userAvailability, action) => {
      // updates user availability.
      let index = userAvailability.findIndex(
        (user) => user.Id === action.payload.Id
      );
      userAvailability.splice(index, 1, {
        ...userAvailability[index],
        ...action.payload,
      });
    },
  },
});

export const { userAvailabilitiesAdded, userAvailabilityUpdated } =
  slice.actions;
export default slice.reducer;

export const getUserAvailabilityByUserId = createSelector(
  (state) => state.entities.userAvailability,
  (userAvailability) =>
    memoize((userId) => {
      // gets all ticker entries
      const availability = userAvailability.find((a) => a.Id === userId);

      if (availability) return availability;

      return null;
    })
);

export const getUserReturnDateTimeUtcByUserId = createSelector(
  (state) => state.entities.userAvailability,
  (userAvailability) =>
    memoize((userId) => {
      // gets all ticker entries
      const availability = userAvailability.find((a) => a.Id === userId);

      if (availability) return availability["ReturnDateTimeUtc"];

      return null;
    })
);

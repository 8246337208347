import {
    getUrlExtension,
} from "../components/common/Common";

const videoEnabled = process.env.REACT_APP_VIDEO_ENABLED;

export default class MediaHelper {

    isValidMediaUrl(url) {
        if (this.isSupportedImageExtension(getUrlExtension(url))) return true;
        if (this.isVideoEnabled() && this.isSupportedVideoExtension(getUrlExtension(url))) return true;
        return false;
    }

    isSupportedImageExtension(extension) {
        for (var i = 0; i < this.supportedImageExtensions().length; i++) {
            if (extension.toUpperCase() === this.supportedImageExtensions()[i].toUpperCase()) {
                return true;      
            }
        }
        return false;
    }

    isSupportedVideoExtension(extension) {
        for (var i = 0; i < this.supportedVideoExtensions().length; i++) {
            if (extension.toUpperCase() === this.supportedVideoExtensions()[i].toUpperCase()) {
                return true;      
            }
        }
        return false;
    }

    // inputAcceptedMedia returns the value used by input elements so only
    // supported extensions are displayed
    inputAcceptedMedia() {
        var accept = "";
        for (var i = 0; i < this.supportedImageExtensions().length; i++) {
            accept = accept += "." + this.supportedImageExtensions()[i] + ",";     
        }
        
        if (this.isVideoEnabled()) {
            for (var i = 0; i < this.supportedVideoExtensions().length; i++) {
                accept = accept += "." + this.supportedVideoExtensions()[i] + ",";     
            }
        }
        return accept;
      }

      supportedImageExtensions() {
        return process.env.REACT_APP_SUPPORTED_IMAGE_EXTENSIONS.split("|");
      }

      supportedVideoExtensions() {
        return process.env.REACT_APP_SUPPORTED_VIDEO_EXTENSIONS.split("|");
      }

      isVideoEnabled() {
        return videoEnabled === "true";
      }

}
import {
  Avatar,
  Box,
  Button,
  IconButton,
  makeStyles,
  Menu,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";
import {
  getFutureDateTime,
  getStatusIcon,
} from "../../../components/common/Common";
import { logout } from "../../../components/common/ConnectionMiddleware";
import { DatePicker } from "../../../components/date_picker/DatePicker";

export const LogoutQuestion = ({ handleClose, anchor }) => {
  const classes = useStyles();
  const [date, setDate] = React.useState(new Date());

  return (
    <Menu
      anchorEl={anchor}
      keepMounted
      open={Boolean(anchor)}
      onClose={handleClose}
      style={{ marginLeft: "7%" }}
    >
      <Box style={{ maxWidth: 300, padding: "0px 10px", position: "relative" }}>
        <IconButton
          style={{ position: "absolute", top: -5, right: 5 }}
          onClick={handleClose}
        >
          <Close />
        </IconButton>

        <Box style={{ width: "100%" }}>
          <Box className={classes.menuHeader}>
            <Avatar
              style={{
                background: "transparent",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              {getStatusIcon("logout")}
            </Avatar>
            <Typography variant="h6">Logout</Typography>
          </Box>
          <Box style={{ textAlign: "center", width: "100%" }}>
            <Typography variant="body1">
              When do you expect to be back?
            </Typography>
            <Button
              style={{
                width: "100%",
                background: "whitesmoke",
                margin: "5px 0px",
              }}
              onClick={() => logout()}
            >
              Unknown
            </Button>
            <Button
              style={{
                width: "100%",
                background: "whitesmoke",
                margin: "5px 0px",
              }}
              onClick={() => logout(getFutureDateTime(8))}
            >
              8am
            </Button>
            <Button
              style={{
                width: "100%",
                background: "whitesmoke",
                margin: "5px 0px",
              }}
              onClick={() => logout(getFutureDateTime(9))}
            >
              9am
            </Button>
            <Button
              style={{
                width: "100%",
                background: "whitesmoke",
                margin: "5px 0px",
              }}
              onClick={() => logout(getFutureDateTime(10))}
            >
              10am
            </Button>

            <Box style={{ margin: "10px 5px" }}>
              <Typography variant="body1">I will return at:</Typography>
              <DatePicker date={date} setDate={setDate} />
            </Box>
            <Button
              onClick={() => logout(date)}
              style={{
                width: "100%",
                background: "whitesmoke",
                margin: "5px 0px",
              }}
            >
              Confirm Logout
            </Button>
          </Box>
        </Box>
      </Box>
    </Menu>
  );
};

const useStyles = makeStyles((theme) => ({
  menuHeader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "0 15px",
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  dateTimeInput: {
    padding: 5,
  },
}));

import React from "react";
import {
  AppBar,
  IconButton,
  InputBase,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { Box } from "@mui/system";
import { updateSidebar } from "../../../components/common/StoreCommon";
import { Close, SearchOff } from "@mui/icons-material";

export default function Header(props) {
  const classes = useStyles();

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar style={{ paddingRight: 15 }}>
        <Typography variant="h6" className={classes.title}>
          {props.headerText}
        </Typography>
        <Box style={{ marginLeft: "auto", width: "auto" }}>
          {props.includeIcon ? (
            <Tooltip placement="bottom-end" title={props.iconTooltip}>
              <IconButton color="inherit" onClick={() => props.iconOnClick()}>
                {props.icon}
              </IconButton>
            </Tooltip>
          ) : null}
          <Tooltip placement="bottom-end" title={"close sidebar"}>
            <IconButton
              color="inherit"
              onClick={() => updateSidebar({ open: false })}
            >
              <Close />
            </IconButton>
          </Tooltip>
        </Box>
      </Toolbar>
      {props.includeSearchBox ? (
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            id="headerSearchBox"
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            onChange={(e) => props.searchBoxFunction(e.target.value)}
            inputProps={{ "aria-label": "search" }}
          />
          {
            <div className={classes.clearIcon}>
              <IconButton
                size="small"
                onClick={() => {
                  document.getElementById("headerSearchBox").value = "";
                  props.searchBoxFunction("");
                }}
              >
                <SearchOff />
              </IconButton>
            </div>
          }
        </div>
      ) : null}
    </AppBar>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#eee",
    "&:hover": {
      backgroundColor: "#e2e2e2",
    },
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "auto",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  clearIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    right: 0,
    top: 0,
  },
  inputRoot: {
    color: "inherit",
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    paddingRight: 40,
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    width: "100%",

    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
  },
  appBar: {
    background: "white",
    boxShadow: "unset",
    color: "black",
  },
}));

import { getStore } from "../components/common/StoreCommon";

import { permissionAdded, getPermission } from "../store/hub/permissions";

export default class PermissionService {

  autoRefreshFeed() {
    return getPermission(getStore().getState())("AutoRefreshFeed");
  }

  setAutoRefreshFeed(value) {
    getStore().dispatch(permissionAdded( {AutoRefreshFeed: value} ));
  }

  canAddFeedTickerMessage() {
    return getPermission(getStore().getState())("CanAddFeedTickerMessage");
  }

  setCanAddFeedTickerMessage(value) {
    getStore().dispatch(permissionAdded( {CanAddFeedTickerMessage: value} ));
  }

  muteNotifications() {
    return getPermission(getStore().getState())("MuteNotifications");
  }

  setMuteNotifications(value) {
    getStore().dispatch(permissionAdded( {MuteNotifications: value} ));
  }
 
  playConversionSound() {
    return getPermission(getStore().getState())("PlayConversionSound");
  }

  setPlayConversionSound(value) {
    getStore().dispatch(permissionAdded( {PlayConversionSound: value} ));
  }
}

import * as React from "react";
import TextField from "@mui/material/TextField";
import DateRangePicker from "@mui/lab/DateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

/*
**  DatePicker values are dates in the user's locale
**  Values selected are a pair
*/

export default function DatePicker({ value, setValue }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateRangePicker
        disableFuture
        startText="Select Date"
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        renderInput={(startProps, endProps) => (
          <React.Fragment>
            <TextField
              {...startProps}
              size="small"
              style={{
                marginLeft: 20,
                marginRight: 10,
                marginTop: "auto",
                marginBottom: "auto",
              }}
            />
            {endProps.inputProps.value ? (
              <TextField
                {...endProps}
                disabled="true"
                size="small"
                style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              />
            ) : null}
          </React.Fragment>
        )}
      />
    </LocalizationProvider>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import memoize from "lodash.memoize";

const slice = createSlice({
  name: "sidebar",
  initialState: { view: "Feed", open: true },
  reducers: {
    // actions => action handlers

    sidebarViewUpdated: (sidebar, action) => {
      // update existing selected team Id
      return { ...sidebar, ...action.payload };
    },
  },
});

export const { sidebarViewUpdated } = slice.actions;
export default slice.reducer;

export const getSidebarView = createSelector(
  (state) => state.ui.sidebar,
  (sidebar) =>
    memoize(() => {
      return sidebar.view;
    })
);

export const getSidebarViewOpen = createSelector(
  (state) => state.ui.sidebar,
  (sidebar) =>
    memoize(() => {
      return sidebar.open;
    })
);

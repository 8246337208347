import React from "react";
import { Box, TextField, Chip } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { sortByFullName } from "../../../components/common/Common";
import { getUsersFromStore } from "../../../components/common/StoreCommon";

export default function UserAutocomplete({
  compareUserIds,
  setCompareUserIds,
}) {
  const users = sortByFullName(getUsersFromStore());
  const fixedOptions = [users];
  return (
    <Box>
      <Autocomplete
        multiple
        limitTags={1}
        id="New user stats"
        defaultValue={compareUserIds}
        size="small"
        onChange={(event, newValue) => {
          setCompareUserIds(newValue);
        }}
        options={users}
        getOptionLabel={(option) => option.FullName}
        getOptionSelected={(option, value) =>
          option.Id === value.Id || option.FullName === value.FullName
        }
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={option.FullName}
              {...getTagProps({ index })}
              disabled={fixedOptions.indexOf(option) !== -1}
            />
          ))
        }
        style={{
          width: "auto",
          minWidth: 200,
          maxWidth: 400,
          borderRadius: "0",
          maxHeight: 50,
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Select Users"
            style={{ zIndex: 10, background: "rgb(238, 238, 238)" }}
          />
        )}
      />
    </Box>
  );
}

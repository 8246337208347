import * as signalR from "@microsoft/signalr";
import { getTickerMessages, apiGetUserView } from "./ApiMiddleware";
import {
  IsJsonString,
  openFeedThread,
  openMessageThread,
  sendNotification,
  removeAuthTokensAndRedirectToLogin,
  logRocketCatchError,
} from "./Common";
import {
  logout,
  updateFeedThread,
  updateMessageThread,
} from "./ConnectionMiddleware";
import {
  apiMarkDmThreadAsDisplayed
} from "./ApiMiddleware";
import {
  addLikeToFeedThread,
  addMessageThreadToStore,
  addRequestHelpAlert,
  addSnackbarIssue,
  addTickerMessagesToStore,
  addUserLeftThreadToStore,
  addUserToThreadInStore,
  getFeedThreadByIdFromStore,
  getSelectedThreadIdFromStore,
  getUserNameFromId,
  loggedInUserId,
  removeMessageThread,
  removeRequestHelpAlert,
  updateHelpRequestThreadId,
  updateMessageThreadInStore,
  updateMessageThreadRead,
  updateRequestingHelp,
  updateRequestingHelpGuid,
  updateRequestingHelpText,
  updateUserPluggedInStatus,
  updateUserState,
  updateUserStatus,
  processUserConversionData,
  processHubConversionData,
  getUserByIdFromStore,
  getUserStatusByIdFromStore
} from "./StoreCommon";
import {
  updateNotificationForThreadAsReadInStore,
} from "./StoreNotificationsUi";
import TokenService from "../../services/TokenService";
import NotificationService from "../../services/NotificationService";
import TokenHelper from "../../helpers/TokenHelper";
import NotificationHelper from "../../core/NotificationHelper";
import { ExpiredRefreshTokenError } from "../../core/CustomErrors";
import UiService from "../../services/UiService";
import ThreadHelper from "../../core/ThreadHelper";

export const hubUrl = process.env.REACT_APP_HUB;

const tokenHelper = new TokenHelper();
const notificationService = new NotificationService();
const uiService = new UiService();
const threadHelper = new ThreadHelper();

console.log("Connection.js: Building hub connection");

export const connection = new signalR.HubConnectionBuilder()
  .withUrl(hubUrl, {
    skipNegotiation: true,
    transport: signalR.HttpTransportType.WebSockets,
    keepAliveIntervalInMilliseconds: 5000,
    accessTokenFactory: () => tokenHelper.getAccessToken(),
  })
  .withAutomaticReconnect()
  .configureLogging(signalR.LogLevel.Information)
  .build();

function getConnectionDetails() {
  // ConnectionId is no longer available (see docs).  We could get it back from the client when
  // connected if we need to start logging it
  return "State:" + connection.state;
}

export function getConnection() {
  console.log("Connection.js|getConnection|" + getConnectionDetails());
  return connection;
}

export async function checkConnected() {
  console.log("Connection.js|checkConnected|" + getConnectionDetails());

  if (isConnected()) {
    console.log("Connection.js|Connection already connected");
    return connection;
  }

  console.log("Connection.js|Starting connection");

  await connection.start().then(() => {
    console.log("Connection.js|Connection started");
    return connection;
  });
}

function isConnected() {
  return connection.state === signalR.HubConnectionState.Connected;
}

connection.onclose(async () => {
  console.log("Connection.js|onclose|" + getConnectionDetails());
  addSnackbarIssue(
    "You have disconnected from the hub, we will attempt to reconnect you...",
    "info"
  );
  apiGetUserView();
});

connection.onreconnecting((error) => {
  console.log(
    "Connection.js|onreconnecting|" + error + "|" + getConnectionDetails()
  );
  addSnackbarIssue("Attempting to reconnect...", "info");
});

connection.onreconnected((connectionId) => {
  console.log("Connection.js|onreconnected|" + getConnectionDetails());
  // getUserView will make sure the connection is connected
  apiGetUserView();
  addSnackbarIssue("Reconnected successfully!", "info");
});

/*
    When calling the hub we check the access token first.  If this has expired and
    the refresh token has expired a ExpiredRefreshTokenError is raised.  We cannot execute
    any more logic at this point.  This would just generate more errors so we direct the user back to the login page
  */
  export async function callHub(methodName, ...params) {
    console.log(
      "Connection.js|callHub|" + methodName + "|" + getConnectionDetails()
    );
    const tokenService = new TokenService();
  
    return await tokenService
      .checkAccessToken()
      .then(() => {
        return checkConnected().then(() => {
          console.log("Connection.js|Hub connected");
          if (params.length === 0) {
            return connection.invoke(methodName);
          } else if (params.length === 1) {
            return connection.invoke(methodName, params[0]);
          } else if (params.length === 2) {
            return connection.invoke(methodName, params[0], params[1]);
          } else if (params.length === 3) {
            return connection.invoke(methodName, params[0], params[1], params[2]);
          } else if (params.length === 4) {
            return connection.invoke(
              methodName,
              params[0],
              params[1],
              params[2],
              params[3]
            );
          } else if (params.length === 5) {
            return connection.invoke(
              methodName,
              params[0],
              params[1],
              params[2],
              params[3],
              params[4]
            );
          } else {
            throw new Error("Invalid number of parameters passed to callHub");
          }
        });
      })
      .catch((err) => {
        if (err instanceof ExpiredRefreshTokenError) {
          removeAuthTokensAndRedirectToLogin();
        } else {
          logRocketCatchError(err);
          throw err;
        }
      });
  }

// A FeedThread object is received
connection.on("NewFeedThread", (feedThreadJson) => {
  
  let threadId = 0;
  try
  {
    let feedThread = JSON.parse(feedThreadJson);
    console.log("NewFeedThread", feedThread);
    threadId = feedThread.Id;

    uiService.addFeedThread(feedThread, openFeedThread);

  }
  catch (err) {
    logRocketCatchError(err, null, {event: "NewFeedThread", threadId: threadId});
  }

});

connection.on("FeedThreadLiked", (message) => {

  let threadId = 0;

  try
  {
    message = JSON.parse(message);
    console.log("FeedThreadLiked", message);
    threadId = message.ThreadId;
    
    uiService.likeFeedThread(message, openFeedThread);

  }
  catch (err) {
    logRocketCatchError(err, null, {event: "FeedThreadLiked", threadId: threadId});
  }
});

connection.on("ArchiveDmThread", (threadId) => {
  console.log("ArchiveDmThread", threadId);

  try 
  {
    // Mark as read before archiving
    updateMessageThreadRead(threadId, false);
    updateMessageThreadInStore({ Id: threadId, Display: false });
    updateNotificationForThreadAsReadInStore(threadId);
  }
  catch (err) {
    logRocketCatchError(err, null, {event: "ArchiveDmThread", threadId: threadId});
  }
});

connection.on("UserLeftDmThread", (message) => {
  message = JSON.parse(message);
  console.log("UserLeftDmThread", message);

  if (message.UserId === loggedInUserId()) {
    try
    {
      //logged in user has left a thread - remove it.
      removeMessageThread(message.ThreadId);
    }
    catch (err) {
      logRocketCatchError(err, null, {event: "UserLeftDmThread", isLoggedInUser: true, userId: message.UserId, threadId: message.ThreadId});
    }
  } else {
    try
    {
      addUserLeftThreadToStore({
        Id: message.ThreadId,
        LeftUsers: {
          UserId: message.UserId,
          UtcLeftDateTime: message.UtcLeftDateTime,
        },
      });
    }
    catch (err) {
      logRocketCatchError(err, null, {event: "UserLeftDmThread", isLoggedInUser: false, userId: message.UserId, threadId: message.ThreadId});
    }
  }
});

connection.on("RefreshUserConversionMessage", (message) => {
  try
  {
    message = JSON.parse(message);
    console.log("RefreshUserConversionMessage", message);
    processUserConversionData(message);
  }
  catch (err) {
    logRocketCatchError(err, null, {event: "RefreshUserConversionMessage", id: message.Id});
  }
});

connection.on("RefreshHubConversionMessage", (message) => {
  try
  {
    message = JSON.parse(message);
    console.log("RefreshHubConversionMessage", message);
    processHubConversionData(message);

  }
  catch (err) {
    logRocketCatchError(err, null, {event: "RefreshHubConversionMessage", id: message.Id});
  }
});

connection.on("StatusUpdatedMessage", (message) => {

  try
  {
    // update existing userStatus in store
    const userMessage = JSON.parse(message);
    // log that a message has been received
    console.log("StatusUpdatedMessage", userMessage);

    // ignore any status updates for users we do not have access to
    let user = getUserByIdFromStore(userMessage.UserId);
    if (!user) return;

    // get the current status
    let currentStatus = getUserStatusByIdFromStore(userMessage.UserId);
    // determine if the status has changed and not just the level
    let statusChanged = currentStatus.StatusCode != userMessage.StatusCode;

    updateUserStatus(userMessage);

    if (
      (userMessage.UserId && userMessage.PluggedIn === true) ||
      userMessage.PluggedIn === false
    )
      updateUserPluggedInStatus(userMessage);

    updateUserState({
      Id: userMessage.UserId,
      PluggedIn: userMessage.PluggedIn,
      ReturnDateTimeUtc: userMessage.ReturnDateTimeUtc,
    });

    // If the update is for the logged in user and the status has changed (not just the level) then remove any reminder
    if (userMessage.UserId === loggedInUserId() && statusChanged === true)
    {
      notificationService.removeUpdateStatusReminder();
    }
    
  }
  catch (err) {
    logRocketCatchError(err, null, {event: "StatusUpdatedMessage"});
  }
});

connection.on("LogoffMessage", (message) => {
  try
  {
    console.log("LogoffMessage");
    logout();
  }
  catch (err) {
    logRocketCatchError(err, null, {event: "LogoffMessage"});
  }
});

connection.on("NewDmThread", (message) => {

  let threadId = 0;
  try
  {
    message = JSON.parse(message);
    console.log("NewDmThread", message);
    threadId = message.Id;

    addMessageThreadToStore(message);
  
    notificationService.createNotificationForNewDirectMessageThread(
      message.LatestMessage.UserId,
      loggedInUserId(),
      message.Id,
      threadHelper.getPostPreviewText(message.LatestMessage.JsonContent),
      () => openMessageThread(message.Id),
      message.LatestMessage.UtcCreatedDateTime
    );
  
    // help request thread id is checked and added to the help centre
    // this is only used when requesting help.
    const helpRequestId = message.HelpRequestId;
    if (helpRequestId) {
      // todo - compare HelpRequestId with store Guid
      updateHelpRequestThreadId(message.Id);
      updateRequestingHelpText("");
    }

    apiMarkDmThreadAsDisplayed(threadId);
    
  }
  catch (err) {
    logRocketCatchError(err, null, {event: "NewDmThread", threadId: threadId});
  }
  
});

connection.on("TickerMessages", (message) => {
  try
  {
    console.log("TickerMessages", JSON.parse(message));
    addTickerMessagesToStore(JSON.parse(message));
  }
  catch (err) {
    logRocketCatchError(err, null, {event: "TickerMessages"});
  }
});

connection.on("RefreshTickerMessages", () => {
  try
  {
    console.log("RefreshTickerMessages");
    getTickerMessages();
  }
  catch (err) {
    logRocketCatchError(err, null, {event: "RefreshTickerMessages"});
  }
  
});

connection.on("NewFeedThreadMessage", (message) => {

  let threadId = 0;
  try
  {
    message = JSON.parse(message);
    console.log("NewFeedThreadMessage", message);
    threadId = message.ThreadId;
    updateFeedThread(message);
  }
  catch (err) {
    logRocketCatchError(err, null, {event: "NewThreadMessage", threadId: threadId});
  }
});

connection.on("NewDmThreadMessage", (message) => {

  let threadId = 0;
  try
  {
    message = JSON.parse(message);
    console.log("NewDmThreadMessage", message);
    updateMessageThread(message);
  }
  catch (err) {
    logRocketCatchError(err, null, {event: "NewDmThreadMessage", threadId: threadId});
  }
});

connection.on("NewTaggedThreadMessage", (message) => {

  let threadId = 0;
  try
  {
    message = JSON.parse(message);
    console.log("NewTaggedThreadMessage", message);
    threadId = message.ThreadId;
    
    notificationService.createNotificationForFeedThread(
      message,
      loggedInUserId(),
      () => openFeedThread(message.ThreadId)
    );
  }
  catch (err) {
    logRocketCatchError(err, null, {event: "NewTaggedThreadMessage", threadId: threadId});
  }
  
});

connection.on("RequestHelpCancel", (message) => {

  try
  {
    message = JSON.parse(message);
    console.log("RequestHelpCancel", message);
  
    addSnackbarIssue(
      "Help request cancelled or accepted by another user",
      "info"
    );
  
    // stop notification sound sos-alert.wav
    const notification = new NotificationHelper();
    notification.stopSOSNotificationSound();
    removeRequestHelpAlert(message);
  }
  catch (err) {
    logRocketCatchError(err, null, {event: "RequestHelpCancel"});
  }
});

connection.on("RequestHelpError", (message) => {

  try
  {
    message = JSON.parse(message);
    console.log("RequestHelpError", message);
  
    // add snackbar issue
    addSnackbarIssue(message.Message, "info");
  
    // reset requesting help state
    updateRequestingHelp(false);
    updateRequestingHelpGuid(null);
  }
  catch (err) {
    logRocketCatchError(err, null, {event: "RequestHelpError"});
  }
});

connection.on("RequestHelpAlert", (message) => {
  try
  {
    message = JSON.parse(message);
    console.log("RequestHelpAlert", message);
  
    // alert the user that a help request has been made
    sendNotification(
      "SOS Received!",
      `${getUserNameFromId(message.UserId)}: ${message.Message}`
    );
  
    // set the tab title to show that a new sos was received
    document.title = "SOS Received!";
  
    // on browser focus change back to normal
    window.onfocus = () => {
      document.title = "BuzzHubs";
    };
  
    addRequestHelpAlert(message);
  }
  catch (err) {
    logRocketCatchError(err, null, {event: "RequestHelpAlert"});
  }
});

connection.on("UserAddedToDmThread", (res) => {

  try
  {
    res = JSON.parse(res);
    console.log("UserAddedToDmThread", res);

    addUserToThreadInStore(res.UserId, res.ThreadId);
  
    if (res.AddedByUserId === loggedInUserId())
      addSnackbarIssue("Successfully added user to thread", "success");
  }
  catch (err) {
    logRocketCatchError(err, null, {event: "UserAddedToDmThread"});
  }
});

connection.on("ShowBrowserNotification", (message) => {

  try
  {
    console.log("ShowBrowserNotification", message);
    const split = message.split("|");
    notificationService.showBrowserNotification(split[0], split[1]);
  }
  catch (err) {
    logRocketCatchError(err, null, {event: "ShowBrowserNotification"});
  }
});

connection.on("ShowSnackbarIssue", (message) => {

  try
  {
    console.log("ShowSnackbarIssue", message);
    const split = message.split("|");
    let duration = 10000;
    if (split.length > 2) {
      duration = split[2];
    }
    notificationService.showSnackbarIssueIfDoesNotExist(split[0], split[1], duration);
  }
  catch (err) {
    logRocketCatchError(err, null, {event: "ShowSnackbarIssue"});
  }
});

connection.on("CheckStatusReminder", (message) => {

  try
  {
    console.log("CheckStatusReminder", message);
    notificationService.showUpdateStatusReminder();
  }
  catch (err) {
    logRocketCatchError(err, null, {event: "CheckStatusReminder"});
  }
});


connection.on("BreakReminderMessage", (message) => {

  try
  {
    console.log("BreakReminderMessage", message);
  }
  catch (err) {
    logRocketCatchError(err, null, {event: "BreakReminderMessage"});
  }
});

connection.on("TestMessage", (message) => {
  try
  {
    console.log("TestMessage", message);
  }
  catch (err) {
    logRocketCatchError(err, null, {event: "TestMessage"});
  }
});

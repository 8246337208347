import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import memoize from "lodash.memoize";

const slice = createSlice({
  name: "birdseyeView",
  initialState: { visibility: false },
  reducers: {
    // actions => action handlers

    birdseyeViewVisibilityUpdated: (birdseyeView, action) => {
      // update the birdseye view visibility
      return { ...birdseyeView, ...action.payload };
    },
  },
});

export const { birdseyeViewVisibilityUpdated } = slice.actions;
export default slice.reducer;

export const getBirdseyeViewVisibility = createSelector(
  (state) => state.ui.birdseyeView,
  (birdseyeView) =>
    memoize(() => {
      return birdseyeView.visibility;
    })
);

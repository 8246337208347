import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import memoize from "lodash.memoize";

const slice = createSlice({
  name: "users",
  initialState: [],
  reducers: {
    // actions => action handlers
    userAdded: (users, action) => {
      // adds a user to the existing user object.
      const index = users.findIndex(
        (user) => user.Id === action.payload.user.Id
      );
      if (index >= 0) {
        users.splice(index, 1, { ...users[index], ...action.payload.user });
      } else {
        users.push(action.payload.user);
      }
    },
    usersAdded: (users, action) => {
      const userArray = action.payload.users;
      userArray.map((user) => {
        const index = users.findIndex(
          (storedUser) => storedUser.Id === user.Id
        );
        if (index >= 0) {
          users.splice(index, 1, { ...users[index], ...user });
        } else {
          users.push(user);
        }
        return null;
      });
    },
    userUpdated: (users, action) => {
      // updates a user object by comparing the Id of the payload user
      // only updates the data that is different between stored user and payload.
      let index;
      if (action.payload.UserId)
        index = users.findIndex((user) => user.Id === action.payload.UserId);
      else index = users.findIndex((user) => user.Id === action.payload.Id);
      users.splice(index, 1, { ...users[index], ...action.payload });
    },
  },
});

export const { userAdded, usersAdded, userUpdated } = slice.actions;
export default slice.reducer;

// Selector functions
// Memoization
// Store calls in cache to save resources when calling store.
export const getUserById = createSelector(
  (state) => state.entities.users,
  (users) =>
    memoize((id) => {
      return users.filter((user) => user.Id === id)[0];
    })
);

export const getUserNameById = createSelector(
  (state) => state.entities.users,
  (users) =>
    memoize((id) => {
      if (!id) return "BuzzHubs";
      let user = users.filter((user) => user.Id === id)[0];
      if (user) return user["FullName"];
      else return "Unknown User";
    })
);

export const getUsers = createSelector(
  (state) => state.entities.users,
  (users) =>
    memoize(() => {
      return users;
    })
);

export const getUserImageById = createSelector(
  // returns an import for the users image
  (state) => state.entities.users,
  (users) =>
    memoize((id) => {
      if (!id) return "BuzzHubs";
      const user = users.filter((user) => user.Id === id);
      if (user.length >= 1) return user[0]["ImageSrc"];
      return null;
    })
);

export const getUsername = createSelector(
  (state) => state.entities.users,
  (users) =>
    memoize(() => {
      return users.filter((user) => user.LoggedInUser === true)[0]["FullName"];
    })
);

export const getUsersNamesByIds = createSelector(
  (state) => state.entities.users,
  (users) =>
    memoize((ids) => {
      let userList = "";
      ids.forEach((id) => {
        let user = users.filter((user) => user.Id === id)[0];
        if (user) userList = userList + user["FullName"];
      });

      return userList;
    })
);

export const getUsersForMentions = createSelector(
  (state) => state.entities.users,
  (users) =>
    memoize(() => {
      let userArray = [];
      users.forEach((user) => {
        userArray.push({
          Id: user.Id,
          name: user.FullName,
          avatar: user.ImageSrc,
        });
      });
      return userArray;
    })
);

import { getStore } from "../components/common/StoreCommon";
import {
  getUserImageById,
  getUserNameById,
  getUserById,
  getUsers,
  userAdded,
  usersAdded,
} from "../store/hub/users";

export default class UserService {

  getUserName(id) {
   return getUserNameById(getStore().getState())(id);
  }

}

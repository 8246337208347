import React from "react";
import { Box, Divider, IconButton, Tooltip, Fade, Popover } from "@material-ui/core";
import { Email, Call, BarChart } from "@mui/icons-material";
import { messageNewUser, openMessageThread } from "../../../components/common/Common";
import { getMessageThreadsList, updateSidebar, showReport } from "../../../components/common/StoreCommon";
import UserContacts from "./UserContacts";

export default function UserOverlay ({ user, standardSize, classes, updateOverlayState }) {
  
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    updateOverlayState(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Fade
      children={
        <Box className={standardSize ? classes.userHover : classes.userHoverSmall}>
          <Box className={standardSize ? classes.column : classes.columnSmall}>
            <Box className={classes.row}>
              <Tooltip title="Message User">
                <IconButton onClick={() => messageUser(user)}>
                  <Email
                    className={classes.icon}
                    style={{ color: "white", fontSize: standardSize ? "normal" : 20 }}
                  />
                </IconButton>
              </Tooltip> 
              <Tooltip title="User Stats">
                <IconButton
                  onClick={() => {
                    showReport(user.Id);
                  }}
                >
                  <BarChart
                    className={classes.icon}
                    style={{ color: "white", fontSize: standardSize ? "normal" : 20 }}
                  />
                </IconButton>
              </Tooltip>
            </Box>
            <Divider style={{ background: "white" }} />
            <Box className={classes.row}>
              <Tooltip title="Contact">
                <IconButton onClick={handleClick}>
                  <Call
                    className={classes.icon}
                    style={{ color: "white", fontSize: standardSize ? "normal" : 20 }}
                  />
                </IconButton>
              </Tooltip>    
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <UserContacts userId={user.Id} handleClose={handleClose}></UserContacts>
              </Popover>         
            </Box>
          </Box>
        </Box>
      }
      in={true}
    />
  );
};

const messageUser = (user) => {
  // check the list of message threads if DisplayName === user['FullName']
  // set this as the selected message and set sidebar view to messagecentre
  // if there is no DisplayName that matches the user["FullName"] then
  // set sidebar view to messagecentre and newMessage to true;
  const messageThreads = getMessageThreadsList();
  let threadExists = false;
  let existingThreadId = null;
  messageThreads.forEach((thread) => {
    if (thread.DisplayName === user["FullName"]) {
      threadExists = true;
      existingThreadId = thread.Id;
    }
  });
  if (threadExists) {
    openMessageThread(existingThreadId);
  } else {
    messageNewUser(user);
  }

  // update sidebar view to open message centre.
  updateSidebar({ view: "Inbox", open: true });
};

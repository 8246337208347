import React from "react";
import { useSelector } from "react-redux";
import {
  Avatar,
  Divider,
  Fade,
  FormControl,
  List,
  ListItem,
  makeStyles,
  Modal,
  Paper,
  Tab,
} from "@material-ui/core";
import { Close, Visibility, VisibilityOff } from "@material-ui/icons";
import {
  Button,
  Checkbox,
  Select,
  MenuItem,
  FormControlLabel,
  InputLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Stack,
  Grid
} from "@mui/material";
import { Box } from "@mui/system";

import {
  updateUserSettingsVisibilityInStore,
  updateUserSettingsUserIdInStore
}
from "../../../components/common/StoreSettingsUi";
import {
  addSnackbarIssue,
} from "../../../components/common/StoreCommon";
import {
  getShowUserSettings,
  getUserSettingsUserId,
}
from "../../../store/ui/settings";
import UserAutoComplete from "./components/UserAutoComplete";
import {
  getUserProfile,
  updateSimpleUserProfile,
} from "../../../components/common/ApiMiddleware";

export default function UserManagement() {
  const classes = useStyles();
  //const [userLoaded, setUserLoaded] = React.useState(false);
  const showUserSettings = useSelector(getShowUserSettings)();
  const open = Boolean(showUserSettings);
  
  const selectedUserId = useSelector(getUserSettingsUserId)();
  const [localUserId, setLocalUserId] = React.useState(-1);
  const [firstName, setFirstName] = React.useState(null);
  const [lastName, setLastName] = React.useState(null);
  const [disabled, setDisabled] = React.useState(false);
  
  //const tokenHelper = new TokenHelper();
  //const isAdmin = tokenHelper.isAdminRoleSet();

  const saveUserProfile = () => {
    if (selectedUserId) {
      updateSimpleUserProfile(
        JSON.stringify({
          Id: selectedUserId,
          FirstName: firstName,
          LastName: lastName,
          DisabledFl: disabled,
        })
      ).then(() => {
        addSnackbarIssue("User updated", "success");
      }).catch(() => {
        addSnackbarIssue("An error occurred updating the user please try again.","warning");
      }
      );
    }
  };

  const close = () => {
    updateUserSettingsUserIdInStore(null);
    updateUserSettingsVisibilityInStore(false);
  }

  React.useEffect(() => {
    if (selectedUserId) {
      if (selectedUserId != localUserId)
      {
        getUserProfile(selectedUserId).then(
          (user) => {
            if (user["error"]) updateUserSettingsVisibilityInStore(false);
            else {
              setFirstName(user.FirstName);
              setLastName(user.LastName);
              setDisabled(user.DisabledFl);
            }
            setLocalUserId(selectedUserId);
          }
        );
      }
    }
    else {
      setFirstName('');
      setLastName('');
      setDisabled(false);
    }
  });

  const inputProps = {
    style: {
      height: 40,
    },
  };

  return (
    <Modal
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
      }}
      anchorEl={showUserSettings}
      open={open}
      BackdropProps={{
        timeout: 500,
        style: { background: "rgba(50,50,50,0.5)" },
      }}
      aria-labelledby="User Management"
      aria-describedby="Update users here"
    >
      <Fade in={open}>
        <Paper className={classes.paper}>
          <Box className={classes.row}>
            <Typography variant="body1">User Management</Typography>
            <IconButton
              style={{ width: 30, height: 30 }}
              onClick={() => close()}
            >
              <Close />
            </IconButton>
          </Box>

          <Box style={{ margin: "10px 0" }}>
            <UserAutoComplete />
          </Box>
    
          <Divider />
        
          <Box style={{ margin: "10px 0", width: "100%" }}>
            <Typography>
                User Details
              </Typography>
          </Box>

          <Box 
            component="form"
            sx={{
              '& > :not(style)': { m: 0.75 },
            }}
            noValidate
            autoComplete="off">

            <TextField
              fullWidth 
              InputProps={inputProps}
              type={"text"}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              label="First Name"
              InputLabelProps={{ shrink: true }}
            />
      
            <TextField
              fullWidth 
              InputProps={inputProps}
              type={"text"}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              label="Last Name"
              InputLabelProps={{ shrink: true }}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={disabled}
                  onChange={(e) => setDisabled(!disabled)}
                  name="cbDisabled"
                />
              }
              label="Disabled"
            />

            <Box textAlign='center'>

              <Button 
                sx={{
                  width: 100,
                  
                }}
                onClick={() => saveUserProfile()}
                variant="contained"
                >
                Save
              </Button>
            </Box>
          </Box>

          
        </Paper>
      </Fade>
    </Modal>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    background: "white",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    [theme.breakpoints.only("xs")]: {
      width: "100vw",
      height: "100vh",
    },
    [theme.breakpoints.up("sm")]: {
      borderRadius: 10,
      width: "500px",
      height: "auto",
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));

import React from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import { ChevronLeft, Email } from "@material-ui/icons";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { Tooltip, Badge, Divider } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import PeopleIcon from "@material-ui/icons/People";
import HomeIcon from "@material-ui/icons/Home";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Drawer from "@material-ui/core/Drawer";

import TokenHelper from "../../../helpers/TokenHelper";
import { LogoutQuestion } from "./LogoutQuestion";
import background from "../../../assets/images/sidebar_background.jpg";

import {
  updateSidebar,
  updateMainView,
  updateUserProfileVisibility,
} from "../../../components/common/StoreCommon";
import {
  updateUserSettingsVisibilityInStore,
} from "../../../components/common/StoreSettingsUi";

import { useMenu } from "../../../hooks/useMenu";
import { getUserById } from "../../../store/hub/users";
import { getLoggedInUserId } from "../../../store/hub/loggedInUser";
import { getSidebarView, getSidebarViewOpen } from "../../../store/ui/sidebar";
import { getUnreadMessageCount } from "../../../store/hub/message";
import { getUnseenFeedThreadCount } from "../../../store/hub/boomFeed";
import { getShowUserProfile } from "../../../store/ui/userProfile";
import { getShowUserSettings } from "../../../store/ui/settings";

export default function PrimaryDrawer () {
  const classes = useStyles();
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const loggedInUserId = useSelector(getLoggedInUserId)();
  const loggedInUser = useSelector(getUserById)(loggedInUserId);
  const view = useSelector(getSidebarView)();
  const unreadMessageCentreCount = useSelector(getUnreadMessageCount)();
  const unseenFeedThreadCount = useSelector(getUnseenFeedThreadCount)();
  const showUserProfile = useSelector(getShowUserProfile)();
  const showUserSettings = useSelector(getShowUserSettings)();
  const sidebarViewOpen = useSelector(getSidebarViewOpen)();
  const { anchor, handleClick, handleClose } = useMenu();

  const tokenHelper = new TokenHelper();
  const isAdmin = tokenHelper.isAdminRoleSet();

  const sidebarEntries = [
    {
      text: "Feed",
      icon: <LibraryBooksIcon />,
      count: unseenFeedThreadCount,
    },
    {
      text: "Inbox",
      icon: <Email />,
      count: unreadMessageCentreCount,
    },
  ];

  const toggleView = (newView) => {
    if (newView === null) updateSidebar({ view: newView, open: false });
    else if (view !== newView) {
      updateSidebar({ view: newView, open: true });
    } else updateSidebar({ open: !sidebarViewOpen });
  };

  return (
    <>
      
      <Drawer
        anchor="left"
        variant="permanent"
        className={clsx(classes.drawerClose)}
        classes={{paper: clsx(classes.drawerClose)}}
      >
        {/* List containing Hub Overview Button */} 
        <List>
          <OverrideListItem
            button
            className={classes.nested}
            style={{ borderRadius: "5px", overflowX: "hidden" }}
            onClick={() => {
              updateMainView("Hub Overview");
              setSidebarOpen(false);
            }}
          >
            <Tooltip title="Hub Overview">
              <ListItemIcon>
                <HomeIcon style={{ color: "rgb(208 215 216)" }} />
              </ListItemIcon>
            </Tooltip>
          </OverrideListItem>
        </List>

        <Divider style={{ background: "rgb(208 215 216)" }} />

        {/* User avatar */} 
        <List>
          <OverrideListItem
            button
            key="User"
            //className={classes.nested}
            style={{ borderRadius: "5px", overflowX: "hidden" }}
            onClick={() => {
              updateUserProfileVisibility(!showUserProfile);
              setSidebarOpen(false);
            }}
          >
            <Tooltip title="My Profile">
              <ListItemIcon>
                <Badge
                  className={classes.userImg}
                  badgeContent={0}
                  color="secondary"
                >
                  <Avatar src={loggedInUser?.ImageSrc} alt="user" />
                </Badge>
              </ListItemIcon>
            </Tooltip>
          </OverrideListItem>
        </List>
        <Divider style={{ background: "rgb(208 215 216)" }} />
        
        {/* Sidebar entries (Feed and Message Centre) */} 
        <List>
          {sidebarEntries.map((entry) => (
            <OverrideListItem
              selected={sidebarViewOpen && view === entry.text ? true : false}
              style={{
                margin: "10px 0",
                borderRadius: "5px",
                overflowX: "hidden",
              }}
              button
              key={entry.text}
              onClick={() => {
                toggleView(entry.text);
                setSidebarOpen(false);
              }}
            >
              <Tooltip title={entry.text}>
                <ListItemIcon
                  className={classes.icon}
                  style={{
                    color: view === entry.text ? "white" : "rgb(208 215 216)",
                  }}
                >
                  <Badge badgeContent={entry.count} color="secondary">
                    {sidebarViewOpen && view === entry.text ? (
                      <ChevronLeft />
                    ) : (
                      entry.icon
                    )}
                  </Badge>
                </ListItemIcon>
              </Tooltip>
            </OverrideListItem>
          ))}
        </List>
                  
        {isAdmin && isAdmin === true ? (
          <Divider style={{ background: "rgb(208 215 216)", marginBottom: 10 }} />
          ) 
          : null }
        {isAdmin && isAdmin === true ? (
          <OverrideListItem
            button
            onClick={() => {
              updateUserSettingsVisibilityInStore(!showUserSettings);
              setSidebarOpen(false);
            }}
            className={classes.nested}
            style={{ borderRadius: "5px", overflowX: "hidden" }}
          >
          <Tooltip title="User Management">
            <ListItemIcon>
              <PeopleIcon style={{ color: "rgb(208 215 216)" }} />
            </ListItemIcon>
          </Tooltip>
        </OverrideListItem>
          ) 
          : null }
         
        {/* Log Out (anchored to bottom) */} 
        
        <List className={classes.bottom}>
          <Divider
            style={{ background: "rgb(208 215 216)", marginBottom: "10px" }}
          />
          <OverrideListItem
            button
            key="Logout"
            style={{ borderRadius: "5px", overflowX: "hidden" }}
            onClick={(e) => {
              handleClick(e);
            }}
          >
            <ListItemIcon className={classes.icon}>
              <ExitToAppIcon style={{ color: "rgb(208 215 216)" }} />
            </ListItemIcon>
          </OverrideListItem>
        </List>
       
      </Drawer>

      <LogoutQuestion anchor={anchor} handleClose={handleClose} />
      
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    [theme.breakpoints.only("xs")]: {
      width: 0,
      border: "none",
    },
    [theme.breakpoints.up("sm")]: {
      width: 65,
      padding: "0 5px",
    },
    backgroundColor: "rgb(63 68 66)",
    color: "rgb(208 215 216)",
    backgroundImage: `url(${background})`,
    backgroundBlendMode: "multiply",
    backgroundPosition: "right",
  },
  bottom: {
    marginTop: "auto",
  },
  userImg: {
    marginTop: "5px",
    marginBottom: "5px",
    marginLeft: "-3px",
    marginRight: "15px",
  },
  icon: {
    marginTop: "5px",
    paddingLeft: "5px",
  },
  nested: {
    color: "rgb(208 215 216)",
    paddingLeft: "20px",
  },
}));

const OverrideListItem = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "#666767",
    },
    "&$selected": {
      backgroundColor: "#3f90e2",
      color: "white",
    },
    "&$selected:hover": {
      backgroundColor: "#3378bd",
    },
  },
  selected: {},
})(ListItem);

// returns a list of all users to display in a select element
// on select of a user it stores the selected user id and also returns that
// returns a function to select the user.

import React from "react";
import { useSelector } from "react-redux";
import { getUsers } from "../store/hub/users";
import { loggedInUserId } from "../components/common/StoreCommon";

export const useSelectUser = () => {
  const storedUsers = useSelector(getUsers)();
  const [users, setUsers] = React.useState(null);
  const [selectedUser, setSelectedUser] = React.useState();

  const handleSelectUser = (user) => {
    setSelectedUser(user);
  };

  React.useEffect(() => {
    // remove the logged in user from the list.
    let userList = storedUsers.filter((user) => user.Id !== loggedInUserId());
    userList.sort((a, b) =>
      a.FirstName > b.FirstName ? 1 : b.FirstName > a.FirstName ? -1 : 0
    );
    setUsers(userList);
  }, [storedUsers]);

  return { users, handleSelectUser, selectedUser };
};

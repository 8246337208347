import { combineReducers } from "redux";
import selectedTeamReducer from "./selectedTeam";
import reportReducer from "./report";
import sidebarReducer from "./sidebar";
import messageCentreReducer from "./messageCentre";
import notificationsReducer from "./notifications";
import birdseyeViewReducer from "./birdseyeView";
import boomFeedReducer from "./boomFeed";
import mainReducer from "./main";
import snackbarReducer from "./snackbar";
import userProfileReducer from "./userProfile";
import requestHelpReducer from "./requestHelp";
import conversionReducer from "./conversion";
import settingsReducer from "./settings";

export default combineReducers({
  selectedTeam: selectedTeamReducer,
  report: reportReducer,
  sidebar: sidebarReducer,
  messageCentre: messageCentreReducer,
  notifications: notificationsReducer,
  birdseyeView: birdseyeViewReducer,
  boomFeed: boomFeedReducer,
  main: mainReducer,
  snackbar: snackbarReducer,
  userProfile: userProfileReducer,
  requestHelp: requestHelpReducer,
  conversion: conversionReducer,
  settings: settingsReducer,
});

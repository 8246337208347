import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "conversion",
  initialState: { periods: [], selectedPeriod: null },
  reducers: {
    
    timePeriodsInitialised: (state, action) => {
      action.payload.data.forEach(metric => {

        if (metric.DailyFl === true) {
          addPeriod(state, "Daily", metric.DailyIsDefaultFl)
        }
        if (metric.WeeklyFl === true) {
          addPeriod(state, "Weekly", metric.WeeklyIsDefaultFl)
        }
        if (metric.MonthlyFl === true) {
          addPeriod(state, "Monthly", metric.MonthlyIsDefaultFl)
        }
      });
    },

    selectedTimePeriodUpdated: (state, action) => {
      if (action.payload.period === "Today") {
        state.selectedPeriod = "Daily";
      }
      else if (action.payload.period === "This Week") {
        state.selectedPeriod = "Weekly";
      }   
      else if (action.payload.period === "This Month") {
        state.selectedPeriod = "Monthly";
      }      
    },

  },
});

function addPeriod(state, period, isDefault) {
  if (state.periods.includes(period) === false)
  {
    state.periods.push(period);
    if (!state.selectedPeriod && isDefault) {
      state.selectedPeriod = period;
    }
  } 
}

export const { timePeriodsInitialised, selectedTimePeriodUpdated } = slice.actions;
export default slice.reducer;

export const getSelectedTimePeriod = (state) => 
{
  return state.selectedPeriod;
}

export const getSelectedTimePeriodText = (state) => 
{
  if (!state.selectedPeriod) return null;
  if (state.selectedPeriod === "Daily") {
    return "Today";
  }
  if (state.selectedPeriod === "Weekly") {
    return "This Week";
  }
  if (state.selectedPeriod === "Monthly") {
    return "This Month";
  }
}

export const getTimePeriods = (state) => 
{
  let periods = [];
  state.periods.forEach(period => {
    if (period === "Daily") {
      periods.push("Today");
    }
    else if (period === "Weekly") {
      periods.push("This Week");
    }
    else if (period === "Monthly") {
      periods.push("This Month");
    }
  });
  return periods;
}

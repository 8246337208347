import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import memoize from "lodash.memoize";

const slice = createSlice({
  name: "canMessageUsers",
  initialState: [{ Name: "All", Id: [], Type: "Other" }],
  reducers: {
    // actions => action handlers

    canMessageUsersAdded: (users, action) => {
      // adds a user to the existing canCallUsers object.
      let incomingUsers = action.payload.users;

      incomingUsers.forEach((user) => {
        if (user.Id !== action.payload.loggedInUserId) {
          let canMessageUser = {
            Id: user.Id,
            Name: user.FirstName + " " + user.LastName,
            Type: "Users",
          };

          const index = users.findIndex(
            (storedUser) => storedUser.Id === user.Id
          );
          if (index >= 0) {
            users.splice(index, 1, { ...users[index], ...canMessageUser });
          } else {
            users[0]["Id"].push(canMessageUser.Id);
            users.push(canMessageUser);
          }
        }
      });

      users.sort((a, b) => {
        if (a.Name === b.Name) return 0;
        if (a.Name === "All") return -1;
        if (b.Name === "All") return 1;
        return a.Name > b.Name ? 1 : -1;
      });
    },
  },
});

export const { canMessageUsersAdded } = slice.actions;
export default slice.reducer;

export const getCanMessageUser = createSelector(
  (state) => state.entities.canMessageUsers,
  (canMessageUsers) =>
    memoize((id) => {
      // takes the users Id and checks if the logged in user can message them
      return canMessageUsers.some(
        (canMessageUser) => parseInt(canMessageUser.Id) === id
      );
    })
);

export const getCanMessageUsers = createSelector(
  (state) => state.entities.canMessageUsers,
  (canMessageUsers) =>
    memoize(() => {
      // returns all users that the logged in user can message
      return canMessageUsers;
    })
);

import axios from "axios";
import TokenHelper from "../../helpers/TokenHelper";

const apiUrl = process.env.REACT_APP_API;
const tokenHelper = new TokenHelper();

export default axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-type": "application/json",
    Authorization: "Bearer " + tokenHelper.getAccessToken(),
  },
});

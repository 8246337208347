import logoSmall from "../assets/images/logo_small.png";
import { getStorageUrlFromStore } from "../components/common/StoreSettingsUi";

let sosAlertSound = new Audio();
let notificationSound = new Audio();

const getStorageUrl = () => {
  return getStorageUrlFromStore();
}

// Have to set muted for the sounds to play in newer browsers
// https://stackoverflow.com/questions/49930680/how-to-handle-uncaught-in-promise-domexception-play-failed-because-the-use

export default class NotificationHelper {

  playSOSNotificationSound() {

    // if the notification is not playing then play it
    if (!this._isSOSNotificationPlaying()) {

      sosAlertSound = new Audio(getStorageUrl() + "/buzzhubs/sounds/sos-alert.wav");
      sosAlertSound.muted = false;

      // reset sound time
      this._resetSOSNotificationSoundTime();

      sosAlertSound.play();
    }
  }

  stopSOSNotificationSound() {
    sosAlertSound.pause();

    // reset the sound to the beginning
    this._resetSOSNotificationSoundTime();
  }

  playNotificationSound(fileName) {
    if (!fileName) return;
    notificationSound = new Audio(getStorageUrl() + "/buzzhubs/sounds/" + fileName);
    notificationSound.muted = false;
    notificationSound.play();
  }

  showBrowserNotification(title, message) {
    // first check if the user has notifications active
    let options = {
      body: message,
      icon: logoSmall,
    };
    const notification = new Notification(title, options);
    setTimeout(() => {
      notification.close();
    }, 45000);
  }

  _resetSOSNotificationSoundTime() {
    sosAlertSound.currentTime = 0;
  }

  _isSOSNotificationPlaying() {
    return !sosAlertSound.paused;
  }

}

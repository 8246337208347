const apiUrl = process.env.REACT_APP_API;

export default class RequestHelper {
  getRequestResponse(route, token, method, body) {
    console.log("RequestHelper.getRequestResponse: " + route + " " + method);

    let request = this.createRequest(token, method);
    if (body) request["body"] = body;
    return fetch(`${apiUrl}${route}`, request);
  }

  getExternalRequestResponse(url, token, method, body) {
    console.log(
      "RequestHelper.getExternalRequestResponse: " + url + " " + method
    );

    let request = this.createRequest(token, method);
    if (body) request["body"] = body;
    return fetch(url, request);
  }

  createRequest(token, method) {
    return {
      method: method,
      headers: new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }),
    };
  }
}

import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import memoize from "lodash.memoize";

const slice = createSlice({
  name: "notifications",
  initialState: {
    list: [],
  },
  reducers: {

    updateNotifications: (notifications, action) => {
      // adds a notification to the existing notifications object.
      const index = notifications.list.findIndex(
        (notification) => notification.Id === action.payload.Id
      );
      if (index >= 0) {
        const newNotification = {
          ...notifications.list[index],
          ...action.payload,
        };
        if (action.payload.Read) {
          notifications.list.splice(index, 1, newNotification);
        } else {
          notifications.list.splice(index, 1);
          notifications.list.unshift(newNotification);
        }
      } else {
        notifications.list.unshift(action.payload);
      }
    },
    allNotificationsSetToRead: (notifications, action) => {
      // updates time last notification was sent.
      for (let index = 0; index < notifications.list.length; index++) {
        const newNotification = {
          ...notifications.list[index],
          ...{ Read: true },
        };
        notifications.list.splice(index, 1, newNotification);
      }
    },
    clearNotifications: (notifications, action) => {
      // clears all notifications
      return { ...notifications, ...{ list: [] } };
    },
    
  },
});

export const {
  updateNotifications,
  allNotificationsSetToRead,
  clearNotifications,
} = slice.actions;
export default slice.reducer;

export const getNotifications = createSelector(
  (state) => state.ui.notifications,
  (notifications) =>
    memoize(() => {
      return notifications.list;
    })
);

export const getUnreadNotifications = createSelector(
  (state) => state.ui.notifications,
  (notifications) =>
    memoize(() => {
      return notifications.list.filter((n) => n.Read === false);
    })
);

export const notificationExists = createSelector(
  (state) => state.ui.notifications,
  (notifications) =>
    memoize((id) => {
      if (notifications.list.some((notification) => notification.Id === id)) {
        return true;
      }
      return false;
    })
);

export const getUnreadNotificationsCount = createSelector(
  (state) => state.ui.notifications,
  (notifications) =>
    memoize(() => {
      return notifications.list.filter((n) => n.Read === false).length;
    })
);

import { Button } from "@mui/material";
import { useTimeContainer } from "./useTimeContainer";

export const TimeContainer = ({ setDate, date, handleClose }) => {
  const { addTimeToDate, timeOptions } = useTimeContainer(date);

  return (
    <div
      style={{
        height: "inherit",
        maxHeight: 300,
        width: 200,
        textAlign: "center",
        overflow: "hidden",
      }}
    >
      <p>Select time</p>
      <div
        style={{
          overflow: "hidden scroll",
          maxHeight: 300,
        }}
      >
        {timeOptions.map((time, index) => (
          <Button
            key={time + index}
            style={{
              width: "100%",
              background: "white",
              border: "unset",
              fontSize: 15,
            }}
            onClick={() => {
              setDate(addTimeToDate(time));
              handleClose();
            }}
          >
            {time}
          </Button>
        ))}
      </div>
    </div>
  );
};

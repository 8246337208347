import React from "react";
import { useSelector } from "react-redux";
import { Divider, Skeleton, Typography, List, ListItem, ListItemText } from "@mui/material";
import { Box } from "@mui/system";

import { getUserContacts } from "../../../store/hub/userContacts";

const UserContacts = ({ userId, handleClose }) => {
  
  const contacts = useSelector((state) => getUserContacts(state.entities.userContacts, userId));
  
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
      onMouseLeave={handleClose}
    >
      <List>
        {contacts.map((item, index) => (
          <ListItem key={index} dense>
            <ListItemText
              primary={item.text}
            />
          </ListItem>
        ))}
      </List>

    </Box>
  );
};

export default UserContacts;

import React from "react";
import { callHub } from "../../../components/common/Connection";
import { addSnackbarIssue } from "../../../components/common/StoreCommon";

export const useAddUserService = () => {
  const methodName = "AddUserToDirectMessageThread";
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const handleAddUser = (threadId, userId) => {
    setLoading(true);

    callHub(methodName, threadId, userId)
      .then(() => setSuccess(true))
      .catch(() =>
        addSnackbarIssue("Unable to add user, try again.", "warning")
      )
      .finally(() => setLoading(false));
  };

  return [success, loading, handleAddUser];
};

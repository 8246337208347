import { Avatar, Box, Fade, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { getLoggedInUserId } from "../../../store/hub/loggedInUser";
import { getUserImageById, getUserNameById } from "../../../store/hub/users";
import {
  checkForMessageContent,
  convertDateTime,
  parseJsonMessageForDisplay,
} from "../../../components/common/Common";
import Attachments from "../../text-editor/components/Attachments";

export default function MessageContent({
  message,
  index,
  allMessages,
  thread,
}) {
  const classes = useStyles();
  const loggedInUserId = useSelector(getLoggedInUserId)();
  const userName = useSelector(getUserNameById);
  const getUserImageByIdSelector = useSelector(getUserImageById);
  const sentByLoggedInUser = message.UserId === loggedInUserId;
  const [sentDateTimeVisible, setSentDateTimeVisible] = React.useState(false);
  // check if the previous and next message was sent by the same user
  // as the current message. If so update the border styles and don't
  // display the avatar.
  const samePrevUser = message.UserId === allMessages[index - 1]?.UserId;
  const sameNextUser = message.UserId === allMessages[index + 1]?.UserId;

  const attachments = message.Attachments;

  let leftUser = null;
  let nextMessage = allMessages[index + 1];

  thread.LeftUsers.forEach((user) => {
    if (
      (user.UtcLeftDateTime >= message.UtcCreatedDateTime && !nextMessage) ||
      (user.UtcLeftDateTime >= message.UtcCreatedDateTime &&
        user.UtcLeftDateTime <= allMessages[index + 1]?.UtcCreatedDateTime)
    ) {
      leftUser = {
        Name: userName(user.UserId),
        UtcLeftDateTime: message.UtcCreatedDateTime,
      };
    }
  });

  // check if the message has content - if it doesn't - hide it.
  const messageHasContent = checkForMessageContent(message.JsonContent);

  return (
    <Box
      onMouseEnter={() => setSentDateTimeVisible(true)}
      onMouseLeave={() => setSentDateTimeVisible(false)}
    >
      {(!sentByLoggedInUser && !samePrevUser) ||
      (!sentByLoggedInUser && leftUser && !samePrevUser) ? (
        <Typography
          style={{ marginLeft: 40, color: "rgb(70,70,70)" }}
          variant="caption"
        >
          {userName(message.UserId)}
        </Typography>
      ) : null}
      <Box
        className={classes.messageContainer}
        style={{ marginBottom: sameNextUser ? 3 : 10 }}
      >
        {(!sentByLoggedInUser && !sameNextUser) ||
        (sameNextUser && leftUser && !sentByLoggedInUser) ? (
          <Avatar
            src={getUserImageByIdSelector(message.UserId)}
            className={classes.messageAvatar}
          />
        ) : !sentByLoggedInUser ? (
          <Box className={classes.messageAvatar}></Box>
        ) : (
          <Fade in={sentDateTimeVisible}>
            <Box>
              <Typography
                variant="caption"
                style={{ color: "rgb(110,110,110)" }}
              >
                {convertDateTime(message.UtcCreatedDateTime)}
              </Typography>
            </Box>
          </Fade>
        )}

        <Box
          style={{ marginLeft: sentByLoggedInUser ? "auto" : "0" }}
          className={classes.messageAttachmentContainer}
        >
          {messageHasContent && (
            <Box
              className={classes.message}
              style={{
                background: sentByLoggedInUser ? "#1e3653" : "#efefef",
                color: sentByLoggedInUser ? "white" : "rgb(63 68 66)",
                borderTopLeftRadius:
                  samePrevUser && !sentByLoggedInUser ? 3 : 12,
                borderBottomLeftRadius:
                  sameNextUser && !sentByLoggedInUser ? 3 : 12,
                borderTopRightRadius:
                  samePrevUser && sentByLoggedInUser ? 3 : 12,
                borderBottomRightRadius:
                  sameNextUser && sentByLoggedInUser ? 3 : 12,
              }}
            >
              {parseJsonMessageForDisplay(message.JsonContent)}
            </Box>
          )}
          {attachments && attachments.length >= 1 ? (
            <Box
              style={{
                color: "rgb(155, 155, 155)",
              }}
            >
              <Attachments
                files={attachments}
                align={sentByLoggedInUser ? "right" : "left"}
              />
            </Box>
          ) : null}
        </Box>
        {!sentByLoggedInUser && (
          <Fade in={sentDateTimeVisible}>
            <Box className={classes.sentDateTime}>
              <Typography
                variant="caption"
                style={{ color: "rgb(110,110,110)" }}
              >
                {convertDateTime(message.UtcCreatedDateTime)}
              </Typography>
            </Box>
          </Fade>
        )}
      </Box>

      {leftUser ? (
        <Box style={{ textAlign: "center" }}>
          <Typography variant="caption">
            {leftUser.Name} left at: {convertDateTime(leftUser.UtcLeftDateTime)}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  message: {
    width: "fit-content",
    borderRadius: 12,
    padding: 5,
    overflow: "hidden",
    height: "auto",
    whiteSpace: "pre-line",
    lineBreak: "normal",
  },
  messageAttachmentContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "75%",
    marginTop: "auto",
    marginBottom: "auto",
    height: "auto",
    width: "auto",
  },
  messageContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  messageAvatar: {
    width: 30,
    height: 30,
    marginTop: "auto",
    marginRight: theme.spacing(1),
  },
  messageThreadImage: {
    marginTop: "auto",
    marginBottom: "auto",
    width: 50,
    height: 50,
  },
  smallMessageThreadImage: {
    width: 35,
    height: 35,
  },
  sentDateTime: {
    marginLeft: 5,
  },
}));

import React from "react";
import Main from "./pages/app";
import Login from "./pages/login";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { getStore } from "./components/common/StoreCommon";
import { Provider } from "react-redux";
import "./App.css";
import CustomizedSnackbar from "./utils/snackbar";

const store = getStore();
export default function App() {
  return (
    <>
      <Router>
        <Provider store={store}>
          <CustomizedSnackbar />
          <Switch>
            <Route path="/" exact component={Main} />
            <Route path="/login" component={Login} />
          </Switch>
        </Provider>
      </Router>
    </>
  );
}

import React from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import Drawer from "@material-ui/core/Drawer";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import { Email } from "@material-ui/icons";

import MessageCentre from "../message-centre";
import Feed from "../feed";
import SpeedDialSidebar from "./components/SpeedDialSidebar";
import PrimaryDrawer from "./components/PrimaryDrawer";
import background from "../../assets/images/sidebar_background.jpg";

import {
  getSidebarView,
  getSidebarViewOpen,
} from "../../store/ui/sidebar";
import {
  updateSidebar
} from "../../components/common/StoreCommon";
import { getUnreadMessageCount } from "../../store/hub/message";
import { getUnseenFeedThreadCount } from "../../store/hub/boomFeed";

const drawerWidth = 250;

export default function Sidebar() {
  const classes = useStyles();
  const view = useSelector(getSidebarView)();
  const unreadMessageCentreCount = useSelector(getUnreadMessageCount)();
  const unseenFeedThreadCount = useSelector(getUnseenFeedThreadCount)();
  const sidebarViewOpen = useSelector(getSidebarViewOpen)();
  
  const sidebarEntries = [
    {
      text: "Feed",
      icon: <LibraryBooksIcon />,
      count: unseenFeedThreadCount,
    },
    {
      text: "Inbox",
      icon: <Email />,
      count: unreadMessageCentreCount,
    },
  ];

  const toggleView = (newView) => {
    if (newView === null) updateSidebar({ view: newView, open: false });
    else if (view !== newView) {
      updateSidebar({ view: newView, open: true });
    } else updateSidebar({ open: !sidebarViewOpen });
  };

  return (
    <>
      
      {/* We have two drawers:
        Sidebar icons
        The Message Centre or Feed which can be open or closed
       */}

       {/* MC/Feed which can is displayed on not using sidebarViewOpen */}
      <Drawer
        anchor="left"
        variant="permanent"
        className={clsx(
          classes.drawer,
          {
            [classes.sideComponentOpenLeft]: sidebarViewOpen,
            [classes.sideComponentCloseLeft]: !sidebarViewOpen,
          }
        )}
        classes={{
          paper: clsx(
            {
              [classes.sideComponentOpenLeft]: sidebarViewOpen,
              [classes.sideComponentCloseLeft]: !sidebarViewOpen,
            }
          ),
        }}
      >
        {view === "Inbox" ? <MessageCentre /> : null}
        {view === "Feed" ? <Feed /> : null}
      </Drawer>

      <PrimaryDrawer />

      {/* 
        The SpeedDialSidebar is shown on mobile
       */}
      <SpeedDialSidebar
        sidebarEntries={sidebarEntries}
        toggleView={toggleView}
        view={view}
      />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.only("xs")]: {
      width: 0,
    },
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
    },
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    padding: "0 5px",
    backgroundColor: "rgb(63 68 66)",
    color: "rgb(208 215 216)",
    backgroundImage: `url(${background})`,
    backgroundBlendMode: "multiply",
    backgroundPosition: "center",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    [theme.breakpoints.only("xs")]: {
      width: 0,
      border: "none",
    },
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(8) + 1,
      padding: "0 5px",
    },
    backgroundColor: "rgb(63 68 66)",
    color: "rgb(208 215 216)",
    backgroundImage: `url(${background})`,
    backgroundBlendMode: "multiply",
    backgroundPosition: "right",
  },
  sideComponentOpenLeft: {
    [theme.breakpoints.only("xs")]: {
      width: "100vw",
      height: "calc(100% - 60px)",
    },
    [theme.breakpoints.up("sm")]: {
      width: 340,
      marginLeft: 76,
      boxShadow:
        "0 10px 30px -12px rgb(0 0 0 / 42%), 0 4px 25px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    [theme.breakpoints.only("xl")]: {
      width: 380,
    },

    overflowX: "hidden",
    padding: "0",
    background: "#fff",
    color: "rgb(208 215 216)",
  },
  sideComponentCloseLeft: {
    overflowX: "hidden",
    width: 0,
    [theme.breakpoints.up("sm")]: {
      marginLeft: 65,
      padding: "0 5px",
      boxShadow:
        "0 10px 30px -12px rgb(0 0 0 / 42%), 0 4px 25px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)",
      background: "#fff",
      color: "rgb(208 215 216)",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
}));

import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import memoize from "lodash.memoize";

const slice = createSlice({
  name: "messageCentre",
  initialState: {
    selectedThreadId: null,
    newMessage: false,
    newMessageRecipients: [],
    canSeeLatestMessage: null,
    newUnreadMessageInCurrentSelectedThread: false,
  },
  reducers: {
    // actions => action handlers

    messageCentreUiUpdated: (messageCentre, action) => {
      // update existing selected team Id
      return { ...messageCentre, ...action.payload };
    },
  },
});

export const { messageCentreUiUpdated } = slice.actions;
export default slice.reducer;

export const getSelectedThreadId = createSelector(
  (state) => state.ui.messageCentre,
  (messageCentre) =>
    memoize(() => {
      return messageCentre.selectedThreadId;
    })
);

export const getNewMessageBool = createSelector(
  (state) => state.ui.messageCentre,
  (messageCentre) =>
    memoize(() => {
      return messageCentre.newMessage;
    })
);

export const getNewMessageRecipients = createSelector(
  (state) => state.ui.messageCentre,
  (messageCentre) =>
    memoize(() => {
      return messageCentre.newMessageRecipients;
    })
);

export const getCanSeeLatestMessageBool = createSelector(
  (state) => state.ui.messageCentre,
  (messageCentre) =>
    memoize(() => {
      return messageCentre.canSeeLatestMessage;
    })
);

export const getNewUnreadMessageInCurrentSelectedThread = createSelector(
  (state) => state.ui.messageCentre,
  (messageCentre) =>
    memoize(() => {
      return messageCentre.newUnreadMessageInCurrentSelectedThread;
    })
);

import {
  feedThreadAdded,
  feedThreadLiked,
  feedThreadsAdded,
  feedThreadsVisible,
  feedThreadUpdated,
  feedThreadMessageAdded,
  feedThreadMessagesAdded,
  getFeedThreadById,
  getMessageCountByThreadId,
  feedThreadRemoved
} from "../../store/hub/boomFeed";
import {
  getMessageThreadById,
  getMessageThreads,
  messageThreadAdded,
  messageThreadRemoved,
  messageThreadsAdded,
  messageThreadUpdated,
  threadMessageAdded,
  threadMessagesAdded,
  threadMessagesCleared,
  threadMessagesRead,
  unreadMessageCountUpdated,
  userAddedToThread,
  userLeftThreadAdded,
} from "../../store/hub/message";
import { availableStatusesAdded } from "../../store/hub/statuses";
import { getTeams, teamsAdded } from "../../store/hub/teams";
import {
  getUserImageById,
  getUserNameById,
  getUserById,
  getUsers,
  userAdded,
  usersAdded,
} from "../../store/hub/users";
import {
  getUserStatusById,
  usersStatusAdded,
  userStatusAdded,
  userStatusUpdated,
} from "../../store/hub/userStatus";
import {
  getNewMessageBool,
  getSelectedThreadId,
  messageCentreUiUpdated,
} from "../../store/ui/messageCentre";
import { selectedTeamUpdated } from "../../store/ui/selectedTeam";
import configureReducerStore from "./../../store/configureStore";
import {
  convertStatusArrayForStore,
  convertUserArrayForStore,
} from "./Common";
import { tickerEntriesAdded } from "../../store/hub/ticker";
import { sidebarViewUpdated } from "../../store/ui/sidebar";

import {
  getLoggedInUserId,
  loggedInUserIdUpdated,
} from "../../store/hub/loggedInUser";
import { canMessageTeamsAdded } from "../../store/hub/canMessageTeams";
import { canMessageUsersAdded } from "../../store/hub/canMessageUsers";
import { reportUpdated } from "../../store/ui/report";
import {
  activityReportReset,
  activityReportStatusesAdded,
  activityReportsUserAdded,
} from "../../store/hub/activityReports";
import { mainViewUpdated } from "../../store/ui/main";
import {
  snackbarIssueActioned,
  snackbarIssueAdded,
} from "../../store/ui/snackbar";
import {
  userIdUpdated,
  userProfileVisibilityUpdated,
} from "../../store/ui/userProfile";
import {
  getHelpRequestThreadId,
  helpRequestThreadIdUpdated,
  requestActioned,
  requestAdded,
  requestHelpModalVisiblityUpdated,
  requestingHelpGuidUpdated,
  requestingHelpTextUpdated,
  requestingHelpUpdated,
} from "../../store/ui/requestHelp";
import {
  userAvailabilitiesAdded,
  userAvailabilityUpdated,
} from "../../store/hub/userAvailability";
import { 
  userConversionDataInitialised,
  userMetricValuesUpdated
} from "../../store/hub/userConversionData";
import { 
  hubConversionDataInitialised,
  hubMetricValuesUpdated
 } from "../../store/hub/hubConversionData";
import {
  metricsInitialised
} from "../../store/hub/conversionMetrics";
import {
  userContactsInitialised
} from "../../store/hub/userContacts";
import {
  timePeriodsInitialised,
  selectedTimePeriodUpdated
} from "../../store/ui/conversion";
import {
  storageUrlUpdated
} from "../../store/ui/settings";

const store = configureReducerStore();

export function getStore() {
  return store;
}

export function addLoggedInUserIdToStore(id) {
  store.dispatch(loggedInUserIdUpdated({ id }));
}

export function addUserToStore(userArray) {
  convertUserArrayForStore(userArray).then((user) => {
    store.dispatch(userAdded({ user }));
  });
}

export function addUsersToStore(usersArray) {
  const users = usersArray.map((user) => {
    return convertUserArrayForStore(user);
  });

  Promise.all(users).then((i) => {
    store.dispatch(usersAdded({ users: i }));
  });
}

export function addUsersAvailabilityToStore(usersArray) {
  let usersAvailability = usersArray.map((user) => {
    return {
      Id: user.Id,
      ReturnDateTimeUtc: user.ReturnDateTimeUtc,
      PluggedIn: user.PluggedIn,
    };
  });

  Promise.all(usersAvailability).then((i) => {
    store.dispatch(userAvailabilitiesAdded(i));
  });
}

export function addUsersStatusToStore(usersStatus) {
  usersStatus = usersStatus.map((userStatus) => {
    return convertStatusArrayForStore(userStatus);
  });

  store.dispatch(usersStatusAdded({ usersStatus }));
}

export function addUserToThreadInStore(UserId, ThreadId) {
  store.dispatch(userAddedToThread({ UserId, Id: ThreadId }));
}

export function addUserStatusToStore(user) {
  // break down user array and send status to store.
  store.dispatch(
    userStatusAdded({ userStatus: convertStatusArrayForStore(user) })
  );
}

export function updateUserStatus(status) {
  store.dispatch(
    userStatusUpdated({ status: convertStatusArrayForStore(status) })
  );
}

export function getUserStatusByIdFromStore(userId) {
  return getUserStatusById(store.getState())(userId);
}

export function storeLogout() {
  const action = {
    type: "USER_LOGGED_OUT",
  };
  store.dispatch(action);
}

export function addTeamsToStore(teams) {
  store.dispatch(teamsAdded({ teams }));
}

export function addUserContactsToStore(contacts) {
  store.dispatch(userContactsInitialised({ contacts }));
}

export function addAvailableStatusToStore(statuses) {
  // send available status to store.
  store.dispatch(availableStatusesAdded({ statuses }));
}

export function addSelectedTeamToStore(id) {
  store.dispatch(selectedTeamUpdated({ id }));
}

export function addMessageThreadsToStore(Threads) {
  store.dispatch(messageThreadsAdded({ Threads }));
  updateUnreadMessageCount();
}

export function updateUnreadMessageCount() {
  let unreadMessageCount = 0;
  const messageThreadsSelector = getMessageThreads(store.getState());
  const messageThreads = messageThreadsSelector();
  messageThreads.forEach((thread) => {
    if (thread.HasUnreadMessages) unreadMessageCount++;
  });
  store.dispatch(unreadMessageCountUpdated({ unreadMessageCount }));
}

export function updateUserState(message) {
  store.dispatch(userAvailabilityUpdated(message));
}

export function actionSnackbarIssue(id) {
  store.dispatch(snackbarIssueActioned({ id }));
}

export function addSnackbarIssue(text, severity, large) {
  store.dispatch(snackbarIssueAdded({ text, severity, large }));
}

export function updateUserPluggedInStatus(message) {
  const user = {
    Id: message.UserId,
    PluggedIn: message.PluggedIn,
  };
  store.dispatch(userAvailabilityUpdated(user));
}

export function addFeedThreads(threads) {
  store.dispatch(feedThreadsAdded({ threads }));
}

export function updateExpiredThreads(threadIds) {
  threadIds.forEach((threadId) => {
    store.dispatch(feedThreadRemoved({ threadId }));
  });

}

export function addFeedThread(thread, makeVisible) {
  let newThread = { ...thread };
  let visibleInFeed = false; // we add a new property to incoming threads to hide them until the user loads more OR the logged in user sends it.

  if (newThread.CreatedUserId === getLoggedInUserId(store.getState())())
    visibleInFeed = true; // if logged in user sends thread then show automatically to them

  if (makeVisible && makeVisible === true)
    visibleInFeed = true;
  
  store.dispatch(
    feedThreadAdded({ ...newThread, ...{ VisibleInFeed: visibleInFeed } })
  );
}

export function updateFeedThreadsToVisible() {
  store.dispatch(feedThreadsVisible());
}

export function updateMessageThreadInStore(thread) {
  store.dispatch(messageThreadUpdated(thread));
  updateUnreadMessageCount();
}

export function updateMessageThreadRead(threadId, unread) {
  store.dispatch(
    messageThreadUpdated({ HasUnreadMessages: unread, Id: threadId })
  );
  updateUnreadMessageCount();
}

export function addUserLeftThreadToStore(thread) {
  store.dispatch(userLeftThreadAdded(thread));
}

export function addMessagesToThreadInStore(messages) {
  store.dispatch(threadMessagesAdded({ messages }));
}

export function addMessagesToFeedThreadInStore(messages) {
  store.dispatch(feedThreadMessagesAdded({ messages }));
}

export function setMessagesInStoreToRead(messages) {
  store.dispatch(threadMessagesRead(messages));
}

export function addMessageToFeedThreadInStore(message) {
  store.dispatch(feedThreadMessageAdded(message));
}

export function addMessageToThreadInStore(message) {
  store.dispatch(threadMessageAdded(message));
}

export function addMessageThreadToStore(thread) {
  store.dispatch(messageThreadAdded(thread));
  updateUnreadMessageCount();
}

export function updateInThreadNewMessage(thread) {
  const selectedThreadId = getSelectedThreadId(store.getState());
  if (selectedThreadId() === thread.Id) {
    store.dispatch(
      messageCentreUiUpdated({ newUnreadMessageInCurrentSelectedThread: true })
    );
  }
}

export function getFeedThreadByIdFromStore(threadId) {
  return getFeedThreadById(store.getState())(threadId);
}

export function getSelectedThreadIdFromStore() {
  return getSelectedThreadId(store.getState())();
}

export function getHelpRequestThreadIdFromStore() {
  return getHelpRequestThreadId(store.getState())();
}

export function removeMessageThread(threadId) {
  store.dispatch(messageThreadRemoved({ threadId }));
}

export function loggedInUserId() {
  const userId = getLoggedInUserId(store.getState());
  return userId();
}

export function loggedInUserFullName() {
  const name = getUserNameById(store.getState());
  return name(loggedInUserId());
}

export function loggedInUserIsTeamLeader() {
  const teams = getTeams(store.getState())();
  const userId = loggedInUserId();
  teams.some((team) => {
    return team.TeamLeaderUserIds.includes(userId) === true;
  });
}

export function loggedInUserAvatar() {
  const userImage = getUserImageById(store.getState());
  return userImage(loggedInUserId());
}

export function updateMainView(view) {
  // updates main view
  store.dispatch(mainViewUpdated(view));
}

export function getUsersFromStore() {
  const users = getUsers(store.getState());
  return users();
}

export function getUserByIdFromStore(userId) {
  return getUserById(store.getState())(userId);
}

export function updateFeedThreadInStore(message) {
  let currentMessageCount = getMessageCountByThreadId(store.getState());

  store.dispatch(
    feedThreadUpdated({
      ThreadId: message.ThreadId,
      LatestMessage: message,
      MessageCount: currentMessageCount(message.ThreadId) + 1,
    })
  );
}

export function showReport(userId) {
  if (!userId) store.dispatch(activityReportReset());
  else {
    store.dispatch(reportUpdated({ reportUserId: userId }));
    updateMainView("Stats");
  }
}

export function updateUserProfileVisibility(bool, id) {
  if (id) store.dispatch(userIdUpdated(id));
  store.dispatch(userProfileVisibilityUpdated(bool));

  if (!bool) store.dispatch(userIdUpdated(null));
}

export function getUserImage(id) {
  if (!id) return;
  const userImage = getUserImageById(store.getState());
  return userImage(id);
}

export function getUserNameFromId(id, firstNameOnly) {
  const userName = getUserNameById(store.getState())(id);

  if (firstNameOnly) return userName.split(" ")[0];

  return userName;
}

export function setSelectedThreadId(id) {
  // called when a user opens a thread or closes a thread.
  store.dispatch(messageCentreUiUpdated({ selectedThreadId: id ? id : null }));
}

export function clearMessageThreadMessages() {
  store.dispatch(threadMessagesCleared());
}

export function updateNewMessageUi(bool) {
  const newMessage = bool ? bool : getNewMessageBool(store.getState())();
  store.dispatch(
    messageCentreUiUpdated({ newMessage: bool ? bool : !newMessage })
  );
}

export function updateNewMessageRecipients(newMessageRecipients) {
  store.dispatch(messageCentreUiUpdated({ newMessageRecipients }));
}

export function addTickerMessagesToStore(messages) {
  store.dispatch(tickerEntriesAdded(messages));
}
export function addConversionMetricsToStore(metricsData) {
  store.dispatch(metricsInitialised( { data: metricsData} ));
  store.dispatch(timePeriodsInitialised( { data: metricsData} ));
}

export function updateSelectedTimePeriod(period) {
  store.dispatch(selectedTimePeriodUpdated({ period }));
}

export function initialiseUserConversionsInStore(data) {
  store.dispatch(userConversionDataInitialised({ data: data }));
}

export function initialiseHubConversionsInStore(data) {
  store.dispatch(hubConversionDataInitialised({ data: data }));
}

export function processUserConversionData(data) {
  store.dispatch(userMetricValuesUpdated({ data }));
}

export function processHubConversionData(data) {
  store.dispatch(hubMetricValuesUpdated({ data }));
}

export function updateSelectedTeamState(id, name) {
  store.dispatch(selectedTeamUpdated({ id, name }));
}

export function addCanMessageTeams(teamArray) {
  store.dispatch(canMessageTeamsAdded({ teams: teamArray }));
}

export function addCanMessageUsers(userArray, loggedInUserId) {
  store.dispatch(canMessageUsersAdded({ users: userArray, loggedInUserId }));
}

export function closeNewMessageBox() {
  store.dispatch(
    messageCentreUiUpdated({ newMessage: false, newMessageRecipients: [] })
  );
}

export function addUserToActivityReports(userReports, userId) {
  let user = {
    ConversionAmount: userReports.ConversionAmount,
    ConversionCount: userReports.ConversionCount,
    LineItems: userReports.LineItems,
    ShowDateInLineItems: userReports.ShowDateInLineItems,
    SummaryItems: userReports.SummaryItems,
    userId: parseInt(userId),
  };
  store.dispatch(activityReportsUserAdded(user));
}

export function addStatusesToActivityReports(res) {
  let statuses = [];
  res.SummaryItems.forEach((status) => {
    statuses.push({
      Task: status.Task,
      TaskIcon: status.TaskIcon,
      AllowExpand: status.AllowExpand,
      AutoExpand: status.AutoExpand,
    });
  });
  store.dispatch(activityReportStatusesAdded({ statuses }));
}

export function getMessageThreadsList() {
  const messageThreads = getMessageThreads(store.getState())();
  return messageThreads;
}

export function checkForUserViewArray() {
  const loggedInUserInStore = loggedInUserId();
  return loggedInUserInStore !== null;
}

export function updateRequestHelpVisiblity(bool) {
  store.dispatch(requestHelpModalVisiblityUpdated(bool));
}

export function addRequestHelpAlert(requestMessage) {
  store.dispatch(
    requestAdded({
      GUID: requestMessage.GUID,
      UserId: requestMessage.UserId,
      Message: requestMessage.Message,
      Actioned: false,
    })
  );
}

export function removeRequestHelpAlert(requestMessage) {
  store.dispatch(requestActioned(requestMessage.GUID));
}

export function updateRequestingHelp(bool) {
  store.dispatch(requestingHelpUpdated(bool));
}

export function actionRequest(requestId) {
  store.dispatch(requestActioned(requestId));
}

export function updateRequestingHelpGuid(guid) {
  store.dispatch(requestingHelpGuidUpdated(guid));
}

export function updateHelpRequestThreadId(id) {
  // only show the snackbar message if the user is closing the thread.
  if (!id)
    addSnackbarIssue(
      "Help request closed. You can still view and use the message thread in the message centre."
    );

  store.dispatch(helpRequestThreadIdUpdated(id));
}

export function updateRequestingHelpText(text) {
  store.dispatch(requestingHelpTextUpdated(text));
}

export function updateSidebar(data) {
  store.dispatch(sidebarViewUpdated(data));
}

export function updateStorageUrl(url) {
  store.dispatch(storageUrlUpdated(url));
}
import { Collapse, IconButton, TextField, Tooltip } from "@mui/material";
import { ImageSearch, InsertPhoto } from "@material-ui/icons";
import React from "react";
import UploadService from "../../file-upload/services/upload-files.service";
import { Box } from "@mui/system";
import MediaHelper from "../../../helpers/MediaHelper";

export default function InsertCoverMedia({ setCoverMediaUrl }) {
  const [mediaUrl, setMediaUrl] = React.useState("");
  const [usingMediaUrl, setUsingMediaUrl] = React.useState(false);
  const [urlMediaError, setUrlMediaError] = React.useState(null);
  const mediaHelper = new MediaHelper();

  const onUpdateCoverMedia = (e) => {
    const chosenFiles = e.target.files;
    if (chosenFiles && chosenFiles[0] && chosenFiles[0].name) {
      UploadService.upload(chosenFiles[0], (event) => {})
        .then((response) => {
          // if successful update stored cover image with new data.
          setCoverMediaUrl(response.data.Uri);
        })
        .catch((e) => {
          // do error handling
        });
    }
  };

  const onUpdateCoverMediaUrl = (e) => {
    const url = e.target.value;
    setMediaUrl(url);

    if (mediaHelper.isValidMediaUrl(url)) {
      setCoverMediaUrl(url);
      setUrlMediaError("");
      setUsingMediaUrl(false);
    } else {
      setUrlMediaError("The Url provided is not a valid Url");
      setUsingMediaUrl(true);
    }
  };

  return (
    <Box style={{ display: "flex", flexDirection: "row" }}>
      <label htmlFor="coverMediaUpload">
        <input
          id="coverMediaUpload"
          name="coverMediaUpload"
          style={{ display: "none" }}
          type="file"
          onChange={onUpdateCoverMedia}
          accept={mediaHelper.inputAcceptedMedia()}
        />
        <Tooltip title="Upload Cover Media">
          <IconButton
            style={{ width: 36, height: 34, verticalAlign: "unset" }}
            component="span"
          >
            <InsertPhoto style={{ color: "grey", fontSize: 22 }} />
          </IconButton>
        </Tooltip>
      </label>

      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Tooltip title="Add Cover Media from Url">
          <IconButton
            style={{ width: 36, height: 34, verticalAlign: "unset" }}
            onClick={() => setUsingMediaUrl(!usingMediaUrl)}
          >
            <ImageSearch style={{ color: "grey", fontSize: 22 }} />
          </IconButton>
        </Tooltip>
        <Collapse in={usingMediaUrl} orientation="horizontal">
          <TextField
            type="text"
            placeholder="Media Url"
            variant="standard"
            error={urlMediaError ? true : false}
            helperText={urlMediaError}
            onChange={onUpdateCoverMediaUrl}
            value={mediaUrl}
          />
        </Collapse>
      </Box>
    </Box>
  );
}

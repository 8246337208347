import React from "react";
import { useSelector } from "react-redux";
import { Avatar, Grid, Paper, Typography, Box, Divider, Button, Tooltip, makeStyles} from "@material-ui/core";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Skeleton } from "@material-ui/lab";

import { messageHub } from "../../../../components/common/Common";
import { updateSelectedTeamState } from "../../../../components/common/StoreCommon";
import { getPrevAndNextTeamIds } from "../../../../store/hub/teams";

export default function HubWidget({ selectedTeam}) {

  const classes = useStyles();

  const prevAndNextTeamSelector = useSelector(getPrevAndNextTeamIds);
  const prevAndNextTeam = prevAndNextTeamSelector(selectedTeam.Name);

  return ( 
    <Grid key="team" item className={classes.topPaper}>
      <Paper className={classes.userPaper}>
        <Box className={classes.paperHeader}>
          <Avatar className={classes.avatarLarge}
            style={{
              background: selectedTeam
                ? `#${selectedTeam.ColourHex}`
                : "#1e3653",
            }}
          >
            <GroupWorkIcon fontSize="large" />
          </Avatar>

          <Box className={classes.headerPaperRight}>
            <Box className={classes.headerPaperMainText}>
              <Typography
                className={classes.teamName}
                variant="h5"
                gutterBottom
              >
                {selectedTeam ? (
                  selectedTeam.Name
                ) : (
                  <Skeleton variant="rect" width={200} height={25} />
                )}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Divider />

        <Box className={classes.paperFooter}>
          <Tooltip title="Message team">
            <Button onClick={() => messageHub(selectedTeam)}>
              <MailOutlineIcon />
            </Button>
          </Tooltip>

          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            {prevAndNextTeam?.prevTeam.id ? (
              <Tooltip title="Previous team">
                <Button
                  onClick={() =>
                    updateSelectedTeamState(
                      prevAndNextTeam.prevTeam.id,
                      prevAndNextTeam.prevTeam.name
                    )
                  }
                >
                  <ChevronLeftIcon />
                </Button>
              </Tooltip>
            ) : null}

            {prevAndNextTeam?.nextTeam.id ? (
              <Tooltip title="Next team">
                <Button
                  style={{ marginLeft: "auto" }}
                  onClick={() =>
                    updateSelectedTeamState(
                      prevAndNextTeam.nextTeam.id,
                      prevAndNextTeam.nextTeam.name
                    )
                  }
                >
                  <ChevronRightIcon />
                </Button>
              </Tooltip>
            ) : null}
          </Box>
        </Box>
      </Paper>
    </Grid>
  );

}

const useStyles = makeStyles((theme) => ({
  userPaper: {
    [theme.breakpoints.only("xs")]: {
      height: 90,
      width: "auto",
      minWidth: "75vw",
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up("sm")]: {
      height: 80,
      maxWidth: 320,
      minWidth: 248,
      padding: theme.spacing(2),
    },
    [theme.breakpoints.only("xl")]: {
      height: 100,
      maxWidth: 400,
      minWidth: 310,
      padding: theme.spacing(2),
    },
    width: "auto",
    color: "rgb(135, 131, 131)",
  },
  teamName: {
    [theme.breakpoints.only("xs")]: {
      fontSize: 17,
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: 19,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 21,
    },
    [theme.breakpoints.only("xl")]: {
      fontSize: 25,
    },
    color: "rgb(100, 100, 100)",
    marginTop: "auto",
    marginBottom: "auto",
  },
  avatarLarge: {
    [theme.breakpoints.only("xs")]: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
      height: theme.spacing(9),
      top: -28,
    },
    [theme.breakpoints.up("lg")]: {
      width: theme.spacing(10),
      height: theme.spacing(10),
      top: -32,
    },
    [theme.breakpoints.only("xl")]: {
      width: theme.spacing(12),
      height: theme.spacing(12),
      top: -40,
    },
    display: "absolute",
    boxShadow:
      "0 10px 30px -12px rgb(0 0 0 / 22%), 0 4px 25px 0px rgb(0 0 0 / 5%), 0 8px 10px -5px rgb(0 0 0 / 10%)",
  },
  paperHeader: {
    [theme.breakpoints.only("xs")]: {
      height: theme.spacing(8),
    },
    [theme.breakpoints.up("sm")]: {
      height: theme.spacing(7),
    },
    [theme.breakpoints.only("xl")]: {
      height: theme.spacing(9),
    },
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  paperFooter: {
    [theme.breakpoints.only("xs")]: {
      height: theme.spacing(2),
    },
    [theme.breakpoints.up("sm")]: {
      height: theme.spacing(3),
    },
    [theme.breakpoints.only("xl")]: {
      height: theme.spacing(4),
    },
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    paddingTop: theme.spacing(1),
    color: "rgb(135, 131, 131)",
  },
  topPaper: {
    [theme.breakpoints.only("xs")]: {
      marginBottom: theme.spacing(1),
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(3),
    },
  },
  headerPaperRight: {
    textAlign: "right",
    marginLeft: theme.spacing(1),
  },
  headerPaperMainText: {
    display: "flex",
    flexDirection: "row",
  },
}));

import { getStore } from "../components/common/StoreCommon";
import {
  getLoggedInUserId,
  getBrowserPermissionsChecked,
  browserPermissionsCheckedUpdated
} from "../store/hub/loggedInUser";
import {
  getSidebarView
} from "../store/ui/sidebar";
import {
  getSelectedThreadIdFromStore,
  getHelpRequestThreadIdFromStore,
  setSelectedThreadId,
  clearMessageThreadMessages
} from "../components/common/StoreCommon";
import FeedService from "./FeedService";
import NotificationService from "./NotificationService";
import PermissionService from "./PermissionService";
import ApiService from "./ApiService";
import DateTimeHelper from "../core/DateTimeHelper";

export default class UiService {
  
  #feedService;
  #permissionService;
  #notificationService;
  #apiService;
  #dateTimeHelper;

  constructor() {
    this.#feedService = new FeedService();
    this.#notificationService = new NotificationService();
    this.#permissionService = new PermissionService();
    this.#apiService = new ApiService();
    this.#dateTimeHelper = new DateTimeHelper();
  }

  loggedInUserId() {
    return getLoggedInUserId(getStore().getState())();
  }

  isLoggedInUserId(id) {
    return id === this.loggedInUserId();
  }

  browserPermissionsChecked() {
    return getBrowserPermissionsChecked(getStore().getState())();
  }

  updateBrowserPermissionsChecked(checked) {
    getStore().dispatch(browserPermissionsCheckedUpdated( {browserPermissionsChecked: checked} ));
  }

  isMessageCentreOpen() {
    return getSidebarView(getStore().getState())() === "Inbox";
  }

  selectedDmThreadId() {
    return getSelectedThreadIdFromStore();
  }

  currentHelpRequestId() {
    return getHelpRequestThreadIdFromStore();
  }

  isThreadIdCurrentHelpRequest(threadId) {
    return this.currentHelpRequestId() === threadId;
  }

  isThreadIdOpenInMessageCentre(threadId) {
    return this.selectedDmThreadId() === threadId;
  }

  isThreadIdOpenInMessageCentreAndVisisble(threadId) {
    return this.isMessageCentreOpen() && this.isThreadIdOpenInMessageCentre(threadId);
  }

  closeDmThread() {
    setSelectedThreadId();
    clearMessageThreadMessages();
  }

  // A FeedThread object is passed in
  addFeedThread(feedThread, openFeedThread) {
    
    this.updateExpiredThreads();

    let visibleInFeed = false; 
    const autoRefreshFeed = this.#permissionService.autoRefreshFeed();

    // show the thread if created by the logged in user and/or the feed is being auto refeshed
    if (feedThread.CreatedUserId === this.loggedInUserId() || autoRefreshFeed === true) {
      visibleInFeed = true; 
    }

    // add thread to the store
    this.#feedService.addThread(feedThread, visibleInFeed);

    // create a notification
    this.#notificationService.createNotificationForNewFeedThread(
      feedThread,
      this.loggedInUserId(),
      () => openFeedThread(feedThread.Id)
    )

  }

  updateExpiredThreads() {

    const threads = this.#feedService.getThreads();
    threads.map((thread) => {
      if (thread.Expired === false && thread.UtcExpirationDateTime)
      {
        const utcExpiration = this.#dateTimeHelper.convertUtcStringToUtcDateTime(thread.UtcExpirationDateTime);
        if (this.#dateTimeHelper.isUtcDateTimeLessThanNow(utcExpiration))
        {
          this.#feedService.threadHasExpired(thread.Id);  
        }
      }
    });
  }

  likeFeedThread(hubMessage, openFeedThread) {

    this.#feedService.likeThread(hubMessage);

    const feedThread = this.#feedService.getThreadById(hubMessage.ThreadId);
    if (!feedThread) return;
    
     // if the user user is tagged in the thread 
     // or thread was created by logged in user - display notification
    if (hubMessage.Tagged || feedThread.PostMessage.UserId === this.loggedInUserId()) {
      this.#notificationService.createNotificationForLikedFeedThread(
        feedThread,
        this.loggedInUserId(),
        hubMessage.UserId,
        hubMessage.Tagged,
        () => openFeedThread(feedThread.Id)
      )
    }

    // if the user liking the thread is the logged in user and the thread can be closed then automatically close
    // Temporarily disable
    /*if (hubMessage.UserId === this.loggedInUserId() &&
      feedThread.CanCloseFl === true &&
      feedThread.ClosedFl === false) {
        this.closeFeedThread(feedThread.Id);
      }
      */
  }

  async closeFeedThread(threadId) {
    return this.#apiService.closeFeedThread(threadId)
      .then(() => {
        return this.#feedService.closeThread(threadId)
      });
  }
}
import React, { useState } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  Box,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import {
  setSelectedThreadId,
  addSnackbarIssue,
} from "../../../components/common/StoreCommon";
import {
  hubArchiveDmThread,
  hubLeaveDmThread,
} from "../../../components/common/ConnectionMiddleware";
import { UserDropdown } from "../../../utils/user-dropdown";
import AddIcon from "@mui/icons-material/Add";
import { useAddUserService } from "../services/addUserToThread";

export default function ExpandedThreadOptions({
  threadId,
  isUserThread,
  createdByLoggedInUser,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [addUserOpen, setAddUserOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [success, loading, handleAddUser] = useAddUserService();

  React.useEffect(() => {
    if (success) handleClose();
  }, [success]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAddUserOpen(false);
    setSelectedUser(null);
  };

  const leaveThread = (threadId) => {
    hubLeaveDmThread(threadId)
      .then(() => {
        setSelectedThreadId();
        addSnackbarIssue("Left thread successfully!", "success");
      })
      .catch(() => {
        addSnackbarIssue(
          "Unable to leave thread, please try again!",
          "warning"
        );
      });
  };

  const archiveThread = (threadId) => {
    hubArchiveDmThread(threadId)
      .then(() => {
        setSelectedThreadId();
        addSnackbarIssue("Thread archived successfully!", "success");
      })
      .catch(() => {
        addSnackbarIssue(
          "Unable to archive thread, please try again!",
          "warning"
        );
      });
  };

  return (
    <>
      <IconButton
        aria-controls="Thread Options"
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
      >
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.menu}
      >
        <Box className={classes.menuHeader}>
          <Typography variant="body1" style={{ fontWeight: "bold" }}>
            Message Options
          </Typography>
        </Box>
        <Box className={classes.menuItemsContainer}>
          {isUserThread ? (
            <>
              <MenuItem
                key="leave-thread"
                onClick={() => leaveThread(threadId)}
              >
                <Typography variant="caption" className={classes.menuItemText}>
                  Leave Thread
                </Typography>
              </MenuItem>
              {createdByLoggedInUser && (
                <MenuItem
                  key="archive-thread"
                  onClick={() => archiveThread(threadId)}
                >
                  <Typography
                    variant="caption"
                    className={classes.menuItemText}
                  >
                    Archive Thread
                  </Typography>
                </MenuItem>
              )}
              <MenuItem
                key="add-user-to-thread"
                onClick={() => setAddUserOpen(!addUserOpen)}
              >
                <Typography variant="caption" className={classes.menuItemText}>
                  Add User to Thread
                </Typography>
              </MenuItem>

              {addUserOpen && (
                <MenuItem key="addUserDropdown">
                  <UserDropdown setSelectedUser={setSelectedUser} />
                  <IconButton
                    onClick={() => handleAddUser(threadId, selectedUser.Id)}
                    disabled={loading}
                    style={{ marginLeft: 5 }}
                    size="small"
                  >
                    <AddIcon />
                  </IconButton>
                </MenuItem>
              )}
            </>
          ) : (
            <Box style={{ textAlign: "center" }}>
              <Typography variant="body1">No options available</Typography>
            </Box>
          )}
        </Box>
      </Menu>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  menu: {
    whiteSpace: "normal",
    marginTop: theme.spacing(4),
    marginLeft: -40,
  },
  menuHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0 15px",
    width: "auto",
    marginBottom: theme.spacing(2),
  },
  menuItemsContainer: {
    maxHeight: "80vh",
    overflowY: "scroll",
  },
  menuItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    width: "100%",
    maxWidth: "350px",
    maxHeight: "100px",
  },
  menuItemText: {
    whiteSpace: "normal",
    width: "250px",
    marginRight: theme.spacing(2),
    marginTop: "auto",
    marginBottom: "auto",
    display: "block",
    textOverflow: "ellipsis",
    wordWrap: "break-word",
    overflow: "hidden",
    maxHeight: "3em",
    lineHeight: "1.5em",
  },
}));

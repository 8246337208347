import CookieHelper from "../core/CookieHelper";
import DateTimeHelper from "../core/DateTimeHelper";
import * as jose from "jose";

export default class TokenHelper
{

    static OrganisationCodeCookieName = "organisationCode";
    static AccessTokenName = "accessToken";
    static RefreshTokenCookieName = "refreshToken";

    #_cookieHelper;
    #_dateTimeHelper;

    constructor()
    {
        this._cookieHelper = new CookieHelper();
        this._dateTimeHelper = new DateTimeHelper();
    }

    decodeAccessToken()
    {
        const accessToken = this.getAccessToken();
        if (!accessToken) throw new Error("Cannot decode Access Token as no token exists");

        return jose.decodeJwt(accessToken, process.env.API_KEY)
    }

    decodeRefreshToken()
    {
        const refreshToken = this.getRefreshToken();
        if (!refreshToken) throw new Error("Cannot decode Refresh Token as no token exists");

        return jose.decodeJwt(refreshToken, process.env.API_KEY)
    }

    hasAccessTokenExpired() {
        return (this._dateTimeHelper.nowAsDateTime() > this.getAccessTokenExpiration());
    }

    getAccessToken()
    {
        return this._cookieHelper.getCookie(TokenHelper.AccessTokenName);
    }

    getAccessTokenExpiration()
    {
        const decodedAccessToken = this.decodeAccessToken();
        if (!decodedAccessToken) throw new Error("Unable to decode Access Token");

        const unix_timestamp = decodedAccessToken['exp'];
        if (!unix_timestamp) throw new Error("Decoded Access Token does not contain exp value");

        const dateTimeHelper = new DateTimeHelper();
        return dateTimeHelper.convertUnixTimeStampToDateTime(unix_timestamp);
    }

    getRefreshToken()
    {
        return this._cookieHelper.getCookie(TokenHelper.RefreshTokenCookieName);
    }

    getRefreshTokenExpiration()
    {
        const decodedRefreshToken = this.decodeRefreshToken();
        if (!decodedRefreshToken) throw new Error("Unable to decode Refresh Token");

        const unix_timestamp = decodedRefreshToken['exp'];
        if (!unix_timestamp) throw new Error("Decoded Refresh Token does not contain exp value");

        const dateTimeHelper = new DateTimeHelper();
        return dateTimeHelper.convertUnixTimeStampToDateTime(unix_timestamp);
    }

    hasRefreshTokenExpired() {
        return (this._dateTimeHelper.nowAsDateTime() > this.getRefreshTokenExpiration());
    }

    getOrganisationToken()
    {
        return this._cookieHelper.getCookie(TokenHelper.OrganisationCodeCookieName);
    }

    setAuthTokens(tokenResponse)
    {
        this._cookieHelper.setCookie(TokenHelper.AccessTokenName, tokenResponse["Tokens"]["AccessToken"]);
        this._cookieHelper.setCookie(TokenHelper.RefreshTokenCookieName, tokenResponse["Tokens"]["RefreshToken"]);
        this._cookieHelper.setCookie(TokenHelper.OrganisationCodeCookieName, tokenResponse["OrganisationCode"]);
    }
 
    doAuthTokensExist() {
        return this._cookieHelper.doesCookieExist(TokenHelper.OrganisationCodeCookieName);
    }

    removeAuthTokens() {
        this._cookieHelper.removeCookie(TokenHelper.OrganisationCodeCookieName);
        this._cookieHelper.removeCookie(TokenHelper.AccessTokenName);
        this._cookieHelper.removeCookie(TokenHelper.RefreshTokenCookieName);
        
        // TODO: Move this to the calling code
        window.location.href = "/login";
    }

    isAdminRoleSet() {
        
        const accessToken = this.getAccessToken();
        if (!accessToken) return false;

        const decodedToken = jose.decodeJwt(accessToken, process.env.API_KEY);
        return decodedToken[
          "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
        ].includes("admin");

    }


} 
import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import memoize from "lodash.memoize";

const slice = createSlice({
  name: "ticker",
  initialState: [],
  reducers: {
    // actions => action handlers
    tickerEntryAdded: (ticker, action) => {
      // adds a ticker entry.
      ticker.unshift(action.payload);
    },
    tickerEntriesAdded: (ticker, action) => {
      // adds a ticker entry.
      action.payload.forEach((message) => {
        const index = ticker.findIndex(
          (tickerMessage) => tickerMessage.Id === message.Id
        );
        if (index >= 0) {
          ticker.splice(index, 1, { ...ticker[index], message });
        } else {
          ticker.push(message);
        }
      });
    },
  },
});

export const { tickerEntryAdded, tickerEntriesAdded } = slice.actions;
export default slice.reducer;

export const getTickerEntries = createSelector(
  (state) => state.entities.ticker,
  (ticker) =>
    memoize(() => {
      // gets all ticker entries
      return ticker;
    })
);

import React from "react";
import { Box } from "@mui/system";
import {
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";

export default function Hubs({ hubs, setHubs }) {
  function updateHubs(propertyName, bool, index) {
    let newHubs = hubs;
    newHubs[index][propertyName] = bool;
    setHubs(newHubs);
  }

  return (
    <TableContainer component={Paper} style={{ maxHeight: 400 }}>
      <Table aria-label="Status Report">
        <TableHead>
          <TableRow>
            <TableCell width={30} />
            <TableCell>Hub Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {hubs &&
            hubs.map((hub, index) => (
              <Row
                key={hub["UserTeam"]["Id"]}
                hub={hub}
                index={index}
                updateHubs={updateHubs}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function Row({ hub, index, updateHubs }) {
  const [open, setOpen] = React.useState(false);
  const [isPrimary, setIsPrimary] = React.useState(hub.IsPrimary);
  const [isTeamLeader, setIsTeamLeader] = React.useState(hub.IsTeamLeader);
  const [isMember, setIsMember] = React.useState(hub.IsMember);

  function updateIsPrimary(e) {
    setIsPrimary(e.target.checked);
    updateHubs("IsPrimary", e.target.checked, index);
  }
  function updateIsTeamLeader(e) {
    setIsTeamLeader(e.target.checked);
    updateHubs("IsTeamLeader", e.target.checked, index);
  }
  function updateIsMember(e) {
    setIsMember(e.target.checked);
    updateHubs("IsMember", e.target.checked, index);
  }

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {hub["UserTeam"]["Name"]}
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="expanded data">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Permissions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={"isPrimary" + hub["UserTeam"]["Id"]}>
                    <TableCell>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => updateIsPrimary(e)}
                              checked={isPrimary}
                            />
                          }
                          label="Users Primary Hub"
                        />
                      </FormGroup>
                    </TableCell>
                  </TableRow>
                  <TableRow key={"isMember" + hub["UserTeam"]["Id"]}>
                    <TableCell>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => updateIsMember(e)}
                              checked={isMember}
                            />
                          }
                          label="Is a Member"
                        />
                      </FormGroup>
                    </TableCell>
                  </TableRow>
                  <TableRow key={"isTeamLeader" + hub["UserTeam"]["Id"]}>
                    <TableCell>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => updateIsTeamLeader(e)}
                              checked={isTeamLeader}
                            />
                          }
                          label="Is the Team Manager"
                        />
                      </FormGroup>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

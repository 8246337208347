import React from "react";
import { useSelector } from "react-redux";
import { Grid, Paper, Box, Divider, makeStyles } from "@material-ui/core";

import { getTeams } from "../../../store/hub/teams";
import Team from "./Team";
import TeamHeader from "./birdseye/TeamHeader";

export default function Birdseye({ updateTeamView }) {
  const classes = useStyles();
  const teams = useSelector(getTeams)();
  
  return (
    <Grid container justifyContent={"flex-start"} spacing={2}>
      {teams
        ? teams.map((team) => {

            return (
              <Grid item key={`team-${team.Id}`} >
                <Paper className={classes.teamPaper}>
                  <TeamHeader selectedTeam={team} updateTeamView={updateTeamView}>
                  </TeamHeader>
                  <Divider />
                  <Box className={classes.teamBox}>
                    <Team selectedTeam={team} size={70} />
                  </Box>
                </Paper>
              </Grid>
            );
          })
        : null}
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  teamPaper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  teamBox: {
    padding: theme.spacing(1),
    marginBottom: -24,
  },
}));

import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import memoize from "lodash.memoize";

const slice = createSlice({
  name: "boomFeed",
  initialState: { threads: [], messages: null },
  reducers: {
    // actions => action handlers

    feedThreadsAdded: (boomFeed, action) => {
      // adds threads to the existing array
      let threadArray = action.payload.threads;
      for (let i = 0; i < threadArray.length; i++) {
        let thread = threadArray[i];

        // check if the new thread is already present in the threads array
        let duplicate = false;
        for (let x = 0; x < boomFeed["threads"].length; x++) {
          const currentThread = boomFeed["threads"][x];
          // if current thread array includes new thread id
          if (currentThread.Id === thread.Id) {
            // set duplicate to true
            duplicate = true;

            // break the loop to save memory
            break;
          }
        }

        // only if duplicate === false do we want to push the new thread into
        // the existing thread array.
        if (!duplicate)
          boomFeed["threads"].push({
            Id: thread.Id,
            CreatedUserId: thread.CreatedUserId,
            TargetUserId: thread.TargetUserId,
            PostMessage: thread.PostMessage,
            LatestMessage: thread.LatestMessage,
            LikedUserIds: thread.LikedUserIds,
            VisibleInFeed: true,
            MessageCount: thread.MessageCount,
            CanCloseFl: thread.CanCloseFl,
            ClosedFl: thread.ClosedFl,
            UtcExpirationDateTime: thread.UtcExpirationDateTime,
            Expired: false
          });
      }
    },
    feedThreadAdded: (boomFeed, action) => {
      // adds 1 boomfeed thread to the existing threads array
      let thread = {
        Id: action.payload.Id,
        CreatedUserId: action.payload.CreatedUserId,
        TargetUserId: action.payload.TargetUserId,
        PostMessage: action.payload.PostMessage,
        LatestMessage: action.payload.LatestMessage,
        LikedUserIds: action.payload.LikedUserIds,
        VisibleInFeed: action.payload.VisibleInFeed,
        MessageCount: action.payload.MessageCount,
        CanCloseFl: action.payload.CanCloseFl,
        ClosedFl: action.payload.ClosedFl,
        UtcExpirationDateTime: action.payload.UtcExpirationDateTime,
        Expired: false
      };
      boomFeed["threads"].push(thread);
    },
    feedThreadsVisible: (boomFeed) => {
      let storedThreads = [...boomFeed.threads];
      for (let i = 0; i < storedThreads.length; i++) {
        if (storedThreads[i]["VisibleInFeed"] === false && storedThreads[i]["Expired"] === false)
          storedThreads[i]["VisibleInFeed"] = true;
      }
    },
    feedThreadLiked: (boomFeed, action) => {
      let storedThreads = [...boomFeed.threads];
      for (let i = 0; i < storedThreads.length; i++) {
        if (storedThreads[i]["Id"] === action.payload.ThreadId)
          if (!storedThreads[i]["LikedUserIds"].includes(action.payload.UserId))
            storedThreads[i]["LikedUserIds"].push(action.payload.UserId);
      }
    },
    feedThreadUpdated: (boomFeed, action) => {
      const index = boomFeed.threads.findIndex(
        (thread) => thread.Id === action.payload.ThreadId
      );
      if (index >= 0) {
        const thread = { ...boomFeed.threads[index], ...action.payload };
        boomFeed.threads.splice(index, 1);
        boomFeed.threads.unshift(thread);
      }
    },
    feedThreadMessagesAdded: (boomFeed, action) => {
      if (action.payload.messages) {
        let messages = {
          messages: action.payload.messages
            .slice()
            .sort(
              (a, b) =>
                new Date(a.UtcCreatedDateTime) - new Date(b.UtcCreatedDateTime)
            ),
        };
        return { ...boomFeed, ...messages };
      }

      return { ...boomFeed, ...action.payload };
    },
    feedThreadMessageAdded: (boomFeed, action) => {
      boomFeed.messages.push(action.payload);
    },
    feedThreadRemoved: (boomFeed, action) => {
      const index = boomFeed.threads.findIndex(
        (thread) => thread.Id === action.payload.threadId
      );
      if (index >= 0) {
        boomFeed.threads.splice(index, 1);
      }
    },
    feedThreadClosed: (boomFeed, action) => {
      let storedThreads = [...boomFeed.threads];
      for (let i = 0; i < storedThreads.length; i++) {
        if (storedThreads[i]["Id"] === action.payload.threadId)
        {
          storedThreads[i]["ClosedFl"] = true;
          break;
        }
      }
    },
    feedThreadHidden: (boomFeed, action) => {
      let storedThreads = [...boomFeed.threads];
      for (let i = 0; i < storedThreads.length; i++) {
        if (storedThreads[i]["Id"] === action.payload.threadId)
        {
          storedThreads[i]["VisibleInFeed"] = false;
          break;
        }
      }
    },
    feedThreadExpired: (boomFeed, action) => {
      let storedThreads = [...boomFeed.threads];
      for (let i = 0; i < storedThreads.length; i++) {
        if (storedThreads[i]["Id"] === action.payload.threadId)
        {
          storedThreads[i]["Expired"] = true;
          break;
        }
      }
    },
  },
});

export const {
  feedThreadsAdded,
  feedThreadAdded,
  feedThreadsVisible,
  feedThreadLiked,
  feedThreadUpdated,
  feedThreadMessagesAdded,
  feedThreadMessageAdded,
  feedThreadRemoved,
  feedThreadClosed,
  feedThreadHidden,
  feedThreadExpired,
} = slice.actions;
export default slice.reducer;

// Selector functions
// Memoization
// Store calls in cache to save resources when calling store.
export const getBoomFeedState = createSelector(
  (state) => state.entities.boomFeed,
  (boomFeed) =>
    memoize(() => {
      return boomFeed;
    })
);

export const getFeedThreads = createSelector(
  (state) => state.entities.boomFeed,
  (boomFeed) =>
    memoize(() => {
      return boomFeed.threads;
    })
);

export const getFeedThreadById = createSelector(
  (state) => state.entities.boomFeed.threads,
  (threads) =>
    memoize((id) => {
      const thread = threads.filter((thread) => thread.Id === id);
      if (thread) return thread[0];
      return false;
    })
);

export const getFeedThreadMessages = createSelector(
  (state) => state.entities.boomFeed.messages,
  (messages) =>
    memoize(() => {
      return messages;
    })
);

export const getUnseenFeedThreadCount = createSelector(
  (state) => state.entities.boomFeed.threads,
  (threads) =>
    memoize(() => {
      return _getUnseenThreadCount(threads);
    })
);

export const newFeedThreadsExist = createSelector(
  (state) => state.entities.boomFeed.threads,
  (threads) =>
    memoize(() => {
      return _getUnseenThreadCount(threads) > 0;
    })
);

export const getOldestFeedThreadUtcDateTime = createSelector(
  (state) => state.entities.boomFeed.threads,
  (threads) =>
    memoize(() => {
      return new Date(
        Math.min.apply(
          null,
          threads.map(function (e) {
            return new Date(e.PostMessage.UtcCreatedDateTime);
          })
        )
      );
    })
);

export const getMessageCountByThreadId = createSelector(
  (state) => state.entities.boomFeed.threads,
  (threads) =>
    memoize((id) => {
      const thread = threads.filter((thread) => thread.Id === id);
      if (thread) return thread[0]["MessageCount"];
      else return 0;
    })
);

function _getUnseenThreadCount(threads) {
  let x = 0;
  if (threads && threads.length >= 1)
    threads.forEach((thread) => {
      if (thread.VisibleInFeed === false && thread.Expired === false) x++;
    });
  return x;
}
import React, { useState } from "react";
import { makeStyles, Grid, Box, Typography } from "@material-ui/core";
import Header from "../header";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import ViewStreamIcon from "@material-ui/icons/ViewStream";
import HeaderWidgets from "./components/team-header/HeaderWidgets";
import { useSelector } from "react-redux";
import { getSelectedTeamId } from "../../store/ui/selectedTeam";
import Team from "./components/Team";
import Birdseye from "./components/Birdseye";
import { getTeamById } from "../../store/hub/teams";
import TeamRoomTicker from "./components/Ticker";
import { getTickerEntries } from "../../store/hub/ticker";
import TimePeriodSelector from "./components/birdseye/TimePeriodSelector";

export default function TeamOverview() {

  const classes = useStyles();
  const [view, setView] = useState("Hub Overview");
  const selectedTeamId = useSelector(getSelectedTeamId)();
  const selectedTeam = useSelector(getTeamById)(selectedTeamId);
  const tickerEntries = useSelector(getTickerEntries)();

  const updateTeamView = (newView) => {
    if (view !== newView) setView(newView);
  };

  const actions = [
    {
      icon: <ViewModuleIcon style={{ color: "rgb(185 181 181)" }} />,
      name: "Hub Overview",
      selected: view === "Hub Overview",
      onClick: () => updateTeamView("Hub Overview"),
    },
    {
      icon: <ViewStreamIcon style={{ color: "rgb(185 181 181)" }} />,
      name: "Hubs",
      selected: view === "Hubs",
      onClick: () => updateTeamView("Hubs"),
    },
  ];

  const additionalHeaderHtml = (view) => {
    return (
      <>
      {view === "Hubs" || view === "Hub Overview" ? (
        <TimePeriodSelector></TimePeriodSelector>
      ) : null}
      </>
    );
  };

  return (
    <>
      <Header actions={actions} additional={additionalHeaderHtml(view)}/>
      <Grid
        container
        className={classes.root}
        spacing={2}
        style={{ marginBottom: tickerEntries.length >= 1 ? 150 : 0 }}
      >
        {view === "Hub Overview" && selectedTeam ? (
          <>
            <HeaderWidgets selectedTeam={selectedTeam} />
            <Team selectedTeam={selectedTeam} />
          </>
        ) : null}
        {view === "Hubs" || selectedTeamId === -1 ? ( 
          <>
          <Birdseye updateTeamView={updateTeamView} /> 
          </>
        ): null}
      </Grid>
      <TeamRoomTicker />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

import { Avatar, Box, makeStyles } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { loggedInUserId } from "../../../components/common/StoreCommon";
import { getUserImageById } from "../../../store/hub/users";

export default function GroupUserImages({ userIds }) {
  // takes an array of userIds, selects the two at the end of the array to
  // display avatars for the most recently actioned users
  const classes = useStyles();
  const userImage = useSelector(getUserImageById);

  // check if the userIds array has one or multiple ids
  const numberOfUsers = userIds.length;
  let mostRecentUserIds = [];

  if (numberOfUsers === 1) {
    mostRecentUserIds = [userIds[0]];
  } else {
    const loggedInUserLiked = mostRecentUserIds.includes(loggedInUserId());

    mostRecentUserIds = [
      loggedInUserLiked ? loggedInUserId() : userIds[numberOfUsers - 1],
      userIds[numberOfUsers - 2],
    ];
  }

  return (
    <Box className={classes.container}>
      {mostRecentUserIds.length >= 1 &&
        mostRecentUserIds.map((userId, index) => {
          if (userId)
            return (
              <Avatar
                key={`group-user-image-${userId}`}
                className={index === 0 ? classes.avatar : classes.rightAvatar}
                src={userImage(userId)}
              />
            );
          return null;
        })}
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
    width: "auto",
    marginRight: 10,
  },
  avatar: {
    width: 25,
    height: 25,
    zIndex: 1,
    outline: "#fff 2px solid",
  },
  rightAvatar: {
    width: 25,
    height: 25,
    marginLeft: -10,
  },
}));

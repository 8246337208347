import { LinearProgress, makeStyles } from "@material-ui/core";
import { Chip } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

export default function UploadedFiles({
  progress,
  name,
  removeAttachment,
  id,
  errorUploading,
}) {
  const classes = useStyles();

  // use this to hide any unwanted text from the LinearProgress Loader.
  // This only happens when an attachment fails to upload.
  if (progress === 0) progress = null;

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ m: 1, position: "relative" }}>
        <Chip
          label={errorUploading ? `Error uploading: ${name}` : name}
          onDelete={() => removeAttachment(id, name)}
          style={{
            background: errorUploading
              ? "#D10000"
              : progress === 100
              ? "#66bb6a"
              : "#90caf9",
            color: errorUploading ? "white" : "black",
          }}
          className={classes.chip}
          size="small"
        />

        {progress && progress !== 100 && (
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{
              position: "absolute",
              top: -6,
              left: -6,
              zIndex: 1,
            }}
          />
        )}
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  chip: {
    borderRadius: 10,
    color: "white",
  },
}));

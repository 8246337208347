import React, { useState } from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import QueueIcon from "@material-ui/icons/Queue";
import Header from "../sidebar/components/Header";
import { useSelector } from "react-redux";
import {
  getFeedThreads,
  getOldestFeedThreadUtcDateTime,
} from "../../store/hub/boomFeed";
import NewFeedThread from "./components/NewFeedThread";
import {
  updateFeedThreadsToVisible
} from "../../components/common/StoreCommon";
import {
  setNewFeedPostVisibilityInStore
} from "../../components/common/StoreFeedUi";
import FeedThread from "./components/FeedThread";
import ExpandedFeedThread from "./components/ExpandedFeedThread";
import { getSelectedFeedThreadId, getNewFeedPostOpen } from "../../store/ui/boomFeed";
import { newFeedThreadsExist } from "../../store/hub/boomFeed";
import { IsJsonString, logRocketCatchError } from "../../components/common/Common";
import { apiGetEarlierFeedThreads } from "../../components/common/ApiMiddleware";

export default function Feed() {
  
  const classes = useStyles();
  const threads = useSelector(getFeedThreads)();
  const feedOpen = useSelector(getNewFeedPostOpen)();
  const loadNewThread = useSelector(newFeedThreadsExist)();
  const expandedFeedThreadId = useSelector(getSelectedFeedThreadId)();
  const [searchParam, setSearchParam] = useState(null);
  const utcOldestThreadDateTime = useSelector(getOldestFeedThreadUtcDateTime)();

  const setFeedVisibility = (open) => {
    setNewFeedPostVisibilityInStore(open);
  };

  const toggleFeed = () => {
    setNewFeedPostVisibilityInStore(!feedOpen);
  };

  const showNewFeedThreads = () => {
    updateFeedThreadsToVisible();
  };

  const searchThreads = (queryText) => {
    setSearchParam(queryText);
  };

  const selectSortedThreads = (threads) => {
    let arrayToSort = [...threads]
    return arrayToSort.sort(function(a, b) {          
      if (a.ClosedFl === b.ClosedFl)
        {
          return new Date(b.PostMessage.UtcCreatedDateTime) -
              new Date(a.PostMessage.UtcCreatedDateTime)
        }
        return a.ClosedFl ? 1 : -1;
      }
    );
  }

  const sortedThreads = selectSortedThreads(threads);

  return (
    <>
      {expandedFeedThreadId ? (
        <ExpandedFeedThread threadId={expandedFeedThreadId} />
      ) : (
        <>
          <NewFeedThread feedOpen={feedOpen} setFeedVisibility={setFeedVisibility} />
          <Header
            headerText="The Hub"
            includeIcon={true}
            iconTooltip="New Post"
            icon={<QueueIcon />}
            includeSearchBox={false}
            iconOnClick={() => toggleFeed()}
            searchBoxFunction={(queryText) => searchThreads(queryText)}
          />
          <Grid container spacing={0} className={classes.feedsContainer}>
            <Grid item xs={12}>
              {loadNewThread ? (
                <Box
                  className={classes.newThreadBtn}
                  onClick={() => showNewFeedThreads()}
                >
                  <Typography variant="body1">Load New Posts</Typography>
                </Box>
              ) : null}
              {sortedThreads && 
                sortedThreads.map(thread => {
                    if (thread.VisibleInFeed === true) {
                      return <FeedThread thread={thread} key={thread.Id}></FeedThread>;
                    } else {
                      return null;
                    }
                  }
                )
              }
              <Box style={{ textAlign: "center" }}>
                <Button
                  onClick={() => apiGetEarlierFeedThreads(utcOldestThreadDateTime)}
                  variant="text"
                  style={{ textTransform: "none", color: "rgb(150,150,150)" }}
                >
                  <Typography variant="subtitle2">Load More Posts</Typography>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

const searchThreadText = (thread, searchParam) => {
  const threadMessageIsJson = IsJsonString(
    thread["PostMessage"]["JsonContent"]
  );
  if (threadMessageIsJson) {
    const threadMessages = JSON.parse(thread["PostMessage"]["JsonContent"])[
      "Blocks"
    ];
    threadMessages.forEach((block) => {
      return block.BlockValue.toLowerCase().includes(searchParam.toLowerCase());
    });
  }
};

const useStyles = makeStyles((theme) => ({
  newThreadBtn: {
    position: "absolute",
    top: 120,
    left: "50%",
    marginLeft: "-70px",
    width: 140,
    textAlign: "center",
    background: "#3f90e2",
    color: "white",
    cursor: "pointer",
    borderRadius: 5,
    zIndex: 1,
  },
  feedsContainer: {
    overflowY: "scroll",
    height: "-webkit-fill-available",
    background: "rgb(237 237 237)",
    paddingTop: 10,
    paddingBottom: 10,
    minHeight: "60vh",
  },
}));

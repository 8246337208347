import { combineReducers } from "redux";
import usersReducer from "./hub/users";
import teamsReducer from "./hub/teams";
import loggedInUserReducer from "./hub/loggedInUser";
import canMessageUsersReducer from "./hub/canMessageUsers";
import canMessageTeamsReducer from "./hub/canMessageTeams";
import userStatusReducer from "./hub/userStatus";
import statusesReducer from "./hub/statuses";
import boomFeedReducer from "./hub/boomFeed";
import activityReportsReducer from "./hub/activityReports";
import messageReducer from "./hub/message";
import tickerReducer from "./hub/ticker";
import permissionsReducer from "./hub/permissions";
import userAvailabilityReducer from "./hub/userAvailability";
import userConversionDataReducer from "./hub/userConversionData";
import hubConversionDataReducer from "./hub/hubConversionData";
import conversionMetricsReducer from "./hub/conversionMetrics";
import userContactsReducer from "./hub/userContacts";

export default combineReducers({
  users: usersReducer,
  teams: teamsReducer,
  loggedInUser: loggedInUserReducer,
  canMessageUsers: canMessageUsersReducer,
  canMessageTeams: canMessageTeamsReducer,
  userStatus: userStatusReducer,
  statuses: statusesReducer,
  boomFeed: boomFeedReducer,
  activityReports: activityReportsReducer,
  message: messageReducer,
  ticker: tickerReducer,
  permissions: permissionsReducer,
  userAvailability: userAvailabilityReducer,
  conversionMetrics: conversionMetricsReducer,
  userConversionData: userConversionDataReducer,
  hubConversionData: hubConversionDataReducer,
  userContacts: userContactsReducer,
});

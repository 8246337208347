import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import memoize from "lodash.memoize";

const slice = createSlice({
  name: "message",
  initialState: {
    threads: [],
    unreadMessageCount: null,
    messages: [],
  },
  reducers: {
    // actions => action handlers
    messageThreadsAdded: (message, action) => {
      // take threads payload and sort by last message sent datetime.
      let threads = {
        threads: action.payload.Threads.slice().sort(
          (a, b) =>
            new Date(b.LatestMessage.UtcCreatedDateTime) -
            new Date(a.LatestMessage.UtcCreatedDateTime)
        ),
      };
      threads["threads"].forEach((threadObj) => {
        const index = message.threads.findIndex(
          (thread) => thread.Id === threadObj.Id
        );
        let newThread = {
          Id: parseInt(threadObj.Id),
          DisplayName: threadObj.DisplayName,
          LatestMessage: threadObj.LatestMessage,
          UserIds: threadObj.UserIds,
          TeamIds: threadObj.TeamIds,
          HasUnreadMessages: threadObj.HasUnreadMessages,
          LeftUsers: threadObj.LeftUsers,
          CreatedUserId: threadObj.CreatedUserId,
          AchievementId: threadObj.AchievementId,
          Display: true,
        };
        if (index >= 0) {
          message.threads.splice(index, 1, {
            ...message.threads[index],
            ...newThread,
          });
        } else {
          message.threads.push(newThread);
        }
      });
    },
    messageThreadAdded: (message, action) => {
      message.threads.unshift({
        Id: parseInt(action.payload.Id),
        DisplayName: action.payload.DisplayName,
        LatestMessage: action.payload.LatestMessage,
        UserIds: action.payload.UserIds,
        TeamIds: action.payload.TeamIds,
        HasUnreadMessages: action.payload.HasUnreadMessages,
        LeftUsers: action.payload.LeftUsers,
        CreatedUserId: action.payload.CreatedUserId,
        AchievementId: action.payload.AchievementId,
        Display: true,
      });
    },
    messageThreadUpdated: (message, action) => {
      const index = message.threads.findIndex(
        (thread) => thread.Id === action.payload.Id
      );
      if (index >= 0) {
        const thread = { ...message.threads[index], ...action.payload };
        message.threads.splice(index, 1);
        message.threads.unshift(thread);
      }
    },
    unreadMessageCountUpdated: (message, action) => {
      return { ...message, ...action.payload };
    },
    messageThreadRemoved: (message, action) => {
      const index = message.threads.findIndex(
        (thread) => thread.Id === action.payload.threadId
      );
      message.threads.splice(index, 1);
    },
    userLeftThreadAdded: (message, action) => {
      const index = message.threads.findIndex(
        (thread) => thread.Id === action.payload.Id
      );
      if (index >= 0) {
        let thread = { ...message.threads[index] };

        // Remove the left user from UserIds
        const userIdIndex = thread["UserIds"].findIndex(
          (userId) => userId === action.payload.LeftUsers.UserId
        );
        if (userIdIndex >= 0) thread.UserIds.splice(userIdIndex, 1);
        
        // Add the left user to LeftUsers
        thread.LeftUsers.push(action.payload.LeftUsers);
        
        // Update thread
        message.threads.splice(index, 1);
        message.threads.unshift(thread);
      }
    },
    userAddedToThread: (message, action) => {
      const index = message.threads.findIndex(
        (thread) => thread.Id === action.payload.Id
      );
      if (index >= 0) {
        let thread = { ...message.threads[index] };
        thread.UserIds.push(action.payload.UserId);
        message.threads.splice(index, 1);
        message.threads.unshift(thread);
      }
    },
    threadMessagesAdded: (message, action) => {
      if (action.payload.messages) {
        let newArr = [...message.messages, ...action.payload.messages];
        newArr = newArr
          .slice()
          .sort(
            (a, b) =>
              new Date(a.UtcCreatedDateTime) - new Date(b.UtcCreatedDateTime)
          );
        return { ...message, ...{ messages: newArr } };
      }
    },
    threadMessageAdded: (message, action) => {
      message.messages.push(action.payload);
    },
    threadMessagesRead: (message, action) => {
      action.payload.forEach((m) => {
        const index = message.messages.findIndex(
          (message) => message.Id === m.Id
        );
        if (index >= 0) {
          message.messages[index].Read = true;
        }
      });
    },

    threadMessagesCleared: (message, action) => {
      return { ...message, ...{ messages: [] } };
    },
  },
});

export const {
  messageThreadsAdded,
  messageThreadUpdated,
  messageThreadAdded,
  unreadMessageCountUpdated,
  messageThreadRemoved,
  threadMessagesAdded,
  threadMessageAdded,
  userLeftThreadAdded,
  threadMessagesRead,
  threadMessagesCleared,
  userAddedToThread,
} = slice.actions;
export default slice.reducer;

// Selector functions
// Memoization
// Store calls in cache to save resources when calling store.
export const getMessageThreads = createSelector(
  (state) => state.entities.message.threads,
  (threads) =>
    memoize(() => {
      return threads;
    })
);

export const getMessageThreadById = createSelector(
  (state) => state.entities.message.threads,
  (threads) =>
    memoize((id) => {
      const thread = threads.filter((thread) => thread.Id === id);
      if (thread) return thread[0];
      return false;
    })
);

export const getMessageThreadMessages = createSelector(
  (state) => state.entities.message.messages,
  (messages) =>
    memoize((threadId) => {
      return messages.filter((message) => message.ThreadId === threadId);
    })
);

export const getUnreadMessageCount = createSelector(
  (state) => state.entities.message,
  (messages) =>
    memoize(() => {
      return messages.unreadMessageCount;
    })
);

export const getHelpThreadByGUID = createSelector(
  (state) => state.entities.message.threads,
  (threads) =>
    memoize((GUID) => {
      return threads.find((thread) => thread.Id === GUID);
    })
);

import React, { useState } from "react";
import {
  Box,
  makeStyles,
  Paper,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
import Logo from "../../assets/images/logo_transparent.png";
import {
  signInWithEmailAndPassword,
  signInWithGoogle,
} from "./components/Login-Common";
import TokenHelper from "../../helpers/TokenHelper";

export default function Login() {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const tokenHelper = new TokenHelper();

  if (
    tokenHelper.doAuthTokensExist() &&
    window.location.href.includes("/login")
  ) {
    window.location.href = "/";
  }

  const signIn = (type) => {
    if (type === "Email") signInWithEmailAndPassword(username, password);
    else if (type === "Google") signInWithGoogle();
  };

  window.addEventListener("keydown", (event) => {
    if (event.key === "Enter") {
      document.getElementById("signInWithEmailAndPasswordBtn").click();
    }
  });

  return (
    <Box className={classes.root}>
      <Paper className={classes.paper}>
        <Box className={classes.logoContainer}>
          <img
            src={Logo}
            alt="logo"
            style={{ width: "100%", height: "auto" }}
          />
        </Box>
        <Box className={classes.inputControlContainer}>
          <TextField
            variant="filled"
            className={classes.inputControl}
            label="Username"
            onChange={(e) => {
              setUsername(e.target.value);
            }}
            value={username}
          />
          <TextField
            variant="filled"
            className={classes.inputControl}
            label="Password"
            type="password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            value={password}
          />
        </Box>
        <Box className={classes.buttonContainer}>
          <Button
            id="signInWithEmailAndPasswordBtn"
            variant="contained"
            color="primary"
            className={classes.buttonControl}
            onClick={() => signIn("Email")}
          >
            Sign in with email
          </Button>
          {/*
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonControl}
            onClick={() => signIn("Google")}
          >
            Sign in with Google
          </Button>
          */}
        </Box>
      </Paper>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    width: "100vw",
    background: "#141A46",
    display: "flex",
    alignContent: "middle",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: 450,
    textAlign: "center",
    justifyContent: "space-evenly",
    margin: "auto",
    borderRadius: 15,
    [theme.breakpoints.down("lg")]: {
      minHeight: "75vh",
    },
    [theme.breakpoints.only("xl")]: {
      minHeight: "60vh",
    },
  },
  logoContainer: {
    maxWidth: 300,
    marginLeft: "auto",
    marginRight: "auto",
  },
  inputControlContainer: {
    width: "75%",
    height: 200,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    marginLeft: "auto",
    marginRight: "auto",
  },
  buttonContainer: {
    width: "80%",
    height: 110,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    marginLeft: "auto",
    marginRight: "auto",
  },
  inputControl: {},
  buttonControl: {
    height: 50,
    borderRadius: 10,
    letterSpacing: 1,
    fontSize: 12,
    fontWeight: "bold",
  },
}));

import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import memoize from "lodash.memoize";

const slice = createSlice({
  name: "teams",
  initialState: [],
  reducers: {
    // actions => action handlers
    teamAdded: (teams, action) => {
      // adds a team to the existing team object.
      const index = teams.findIndex(
        (team) => team.Id === action.payload.team.Id
      );
      if (index >= 0) {
        teams.splice(index, 1, { ...teams[index], ...action.payload.team });
      } else {
        teams.push(action.payload.team);
      }
    },
    teamsAdded: (storedTeams, action) => {
      const teams = action.payload.teams;
      teams.map((team) => {
        const index = storedTeams.findIndex(
          (storedTeam) => storedTeam.Id === team.Id
        );
        if (index >= 0) {
          storedTeams.splice(index, 1, { ...storedTeams[index], ...team });
        } else {
          storedTeams.push(team);
        }
        return null;
      });
    },

    teamUpdated: (storedTeams, action) => {
      // updates a team object by comparing the Id of the payload team
      // only updates the data that is different between stored team and payload.
      let index;
      if (action.payload.TeamId)
        index = storedTeams.findIndex(
          (team) => team.Id === action.payload.TeamId
        );
      else
        index = storedTeams.findIndex(
          (team) => team.Id === action.payload.TeamId
        );
      storedTeams.splice(index, 1, {
        ...storedTeams[index],
        ...action.payload,
      });
    },
  },
});

export const { teamAdded, teamsAdded, teamUpdated } = slice.actions;
export default slice.reducer;

export const getTeams = createSelector(
  // returns a list of teams
  (state) => state.entities.teams,
  (teams) =>
    memoize(() => {
      return teams;
    })
);

export const getTeamById = createSelector(
  // returns team object with the same id given
  (state) => state.entities.teams,
  (teams) =>
    memoize((id) => {
      if (!id) return;
      return teams.filter((team) => team.Id === id)[0];
    })
);

export const getTeamNameByTeamId = createSelector(
  // returns team object with the same id given
  (state) => state.entities.teams,
  (teams) =>
    memoize((id) => {
      if (!id) return;
      let team = teams.filter((team) => team.Id === id)[0];
      if (team) return team["Name"];
      else return "Unknown Team";
    })
);

export const getPrevAndNextTeamIds = createSelector(
  // this is used in the main team room view to make the
  // change teams left and right chevrons work correctly.
  (state) => state.entities.teams,
  (teams) =>
    memoize((name) => {
      if (!name) return;
      let prevTeam = null;
      let nextTeam = null;
      for (let x = 0; x < teams.length; x++) {
        if (teams[x].Name === name) {
          prevTeam = { id: teams[x - 1]?.Id, name: teams[x - 1]?.Name };
          nextTeam = { id: teams[x + 1]?.Id, name: teams[x + 1]?.Name };
        }
      }
      return { prevTeam, nextTeam };
    })
);

export const getTeamColorById = createSelector(
  (state) => state.entities.teams,
  (teams) =>
    memoize((id) => {
      if (!id) return;
      let team = teams.filter((team) => team.Id === id)[0];
      if (team) return team["ColourHex"];
      else return "bfbfbf";
    })
);

export const getTeamsNamesByIds = createSelector(
  (state) => state.entities.teams,
  (teams) =>
    memoize((ids) => {
      let teamList = "";
      ids.forEach((id) => {
        let team = teams.filter((team) => team.Id === id)[0];
        if (team) teamList = teamList + team["Name"];
      });

      return teamList;
    })
);

import {
  Avatar,
  Box,
  Divider,
  FormControl,
  InputLabel,
  makeStyles,
  Modal,
  Paper,
  Typography,
  Fade,
  Input,
  IconButton,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Checkbox, InputAdornment } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { getPermission } from "../../../store/hub/permissions";
import { checkForMessageContent } from "../../../components/common/Common";
import { hubArchiveDmThread, hubCreateFeedThread } from "../../../components/common/ConnectionMiddleware";
import {
  loggedInUserAvatar,
  loggedInUserFullName,
  addSnackbarIssue,
} from "../../../components/common/StoreCommon";
import {
  getNewFeedPostDefaultTextFromStore,
  resetNewFeedPostDefaultTextInStore,
  getNewFeedPostAchievementIdFromStore,
  resetNewFeedPostAchievementIdInStore,
  getNewFeedPostOriginatingThreadIdFromStore,
} from "../../../components/common/StoreFeedUi";
import MyEditor from "../../text-editor";

export default function Feed({ feedOpen, setFeedVisibility }) {

  const classes = useStyles();
  const breakingNewsRef = React.useRef();

  const [error, setError] = React.useState("");
  const [addToTicker, setAddToTicker] = React.useState(false);
  const [tickerMessage, setTickerMessage] = React.useState("");
  
  const tickerMessageMaxLength = 200;

  let defaultText = null;
  if (feedOpen === true) {
    defaultText = getNewFeedPostDefaultTextFromStore();
  }
  
  const canAddFeedTickerMessage = useSelector(getPermission)(
    "CanAddFeedTickerMessage"
  );

  const messageIsValid = async (message) => {
    const messageHasContent = checkForMessageContent(message);
    if (!messageHasContent) {
      setError(
        "You have forgotten to add text to your post! Add text and try again"
      );
      throw new Error("No message content");
    }

    if (
      JSON.parse(message).Blocks.length <= 1 &&
      JSON.parse(message).Blocks[0].BlockValue === ""
    ) {
      // if there is no message content or type don't allow the user to send the message
      // and display an error.
      setError("Error posting - please check you have entered a message.");
      throw new Error("Invalid message content");
    }

    if ((!tickerMessage || tickerMessage === "") && addToTicker) {
      // if there is no tickerMessage but addToTicker is true / dont allow user to send message.
      setError(
        "You haven't entered your Breaking News text! Please add this text or un-click this option and try again"
      );
      throw new Error("Invalid ticker settings");
    }
  };

  // onSubmit must return a promise
  // If an error occurs we show the error and throw an Error so the promise is rejected
  const onSubmit = async (message, mentionedUsers, attachments) => {
    return messageIsValid(message).then(() => {
  
      return hubCreateFeedThread(
        message,
        mentionedUsers,
        attachments,
        tickerMessage,
        getNewFeedPostAchievementIdFromStore()
      )
        .then(() => {

          const threadIdToArchive = getNewFeedPostOriginatingThreadIdFromStore();
          if (threadIdToArchive) {
            hubArchiveDmThread (
              threadIdToArchive
            )
          };

          onClose();
        })
        .catch(() => {
          addSnackbarIssue(
            "Unable to add the post, please try again",
            "warning"
          );
          throw new Error("Unable to add post");
        });
    });
  };

  const onClose = () => {
    reset();    
    resetNewFeedPostDefaultTextInStore();
    resetNewFeedPostAchievementIdInStore();
    setFeedVisibility(false);
  }

  const reset = () => {
    setError("");
    setAddToTicker(false);
    setTickerMessage("");
  };

  const handleTickerCheckbox = (e) => {
    setAddToTicker(e.target.checked);
    breakingNewsRef.current.focus();
    setTickerMessage("");
    setError("");
  };

  const handleTickerMessageChange = (e) => {
    setTickerMessage(e.target.value);
    setError("");
  };

  return (
    <>
      <Modal
        open={feedOpen}
        style={{
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
        BackdropProps={{
          timeout: 500,
          style: { background: "rgba(50,50,50,0.5)" },
        }}
      >
        <Fade in={feedOpen}>
          <Paper className={classes.container}>
            <Box className={classes.row}>
              <Box className={classes.headerText}>
                <Typography variant="h6">Create a post</Typography>
              </Box>
              <IconButton
                style={{
                  width: 30,
                  height: 30,
                  position: "absolute",
                  right: 5,
                  top: 12,
                }}
                onClick={() => onClose()}
              >
                <Close />
              </IconButton>
            </Box>

            <Divider />
            <Box className={classes.userOptions}>
              <Avatar
                style={{ marginTop: "auto", marginBottom: "auto" }}
                src={loggedInUserAvatar()}
              ></Avatar>
              <Box className={classes.userText}>
                <Typography variant="body1">
                  {loggedInUserFullName()}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.mainBody}>
              <MyEditor
                onSubmit={(message, mentionedUsers, attachments) =>
                  onSubmit(message, mentionedUsers, attachments)
                }
                toolbarPosition={"bottom"}
                placeholder="What would you like your post to say?"
                resetError={setError}
                sendButtonPosition={"bottom"}
                showInsertImage={true}
                editorHeight={350}
                defaultText={defaultText}
              />
            </Box>
            {canAddFeedTickerMessage && (
              <Box className={classes.footer}>
                <Box className={classes.messageOptions}>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="caption">
                      Add to Breaking News?
                    </Typography>
                    <Checkbox
                      size="small"
                      value={addToTicker}
                      onChange={handleTickerCheckbox}
                    />
                  </Box>
                </Box>

                <FormControl
                  fullWidth
                  style={{
                    display:
                      canAddFeedTickerMessage && addToTicker ? "block" : "none",
                  }}
                >
                  <InputLabel htmlFor="tickerMessageInput">
                    Breaking News Text
                  </InputLabel>

                  <Input
                    inputRef={breakingNewsRef}
                    inputProps={{
                      maxLength: tickerMessageMaxLength,
                    }}
                    id="tickerMessageInput"
                    endAdornment={
                      <InputAdornment position="end">
                        {tickerMessageMaxLength - tickerMessage.length}
                      </InputAdornment>
                    }
                    onChange={handleTickerMessageChange}
                    label="Breaking News Text"
                    value={tickerMessage}
                    style={{ width: "100%" }}
                  />
                </FormControl>
              </Box>
            )}
            <Typography
              variant="body2"
              style={{
                color: "white",
                background: "red",
                textAlign: "center",
                margin: 5,
                borderRadius: 5,
              }}
            >
              {error}
            </Typography>
          </Paper>
        </Fade>
      </Modal>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.only("xs")]: {
      width: "100vw",
      height: "100vh",
    },
    [theme.breakpoints.up("sm")]: {
      borderRadius: 10,
      width: 500,
    },
  },
  headerText: {
    textAlign: "center",
    margin: 10,
  },
  userOptions: {
    margin: 10,
    display: "inherit",
    flexDirection: "row",
  },
  userText: {
    marginLeft: 10,
    display: "inherit",
    flexDirection: "column",
    marginTop: "auto",
    marginBottom: "auto",
  },
  mainBody: {
    margin: "0px 10px",
    display: "block",
  },
  footer: {
    display: "inherit",
    flexDirection: "column",
    marginLeft: 10,
    marginRight: 10,
  },
  postButtonDisabled: {
    width: "100%",
  },
  postButton: {
    width: "100%",
    background: "#3f90e2",
    color: "white",
    "&:hover, &:focus": {
      background: "#2d6cad",
    },
  },
  messageOptions: {
    flexDirection: "row",
    display: "inherit",
    justifyContent: "flex-end",
    margin: "5px 0px",
  },
  imageContainer: {
    height: 200,
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  image: {
    width: "auto",
    height: "auto",
    maxHeight: 200,
    maxWidth: "100%",
    display: "block",
  },
  row: {
    position: "relative",
  },
}));

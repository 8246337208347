import firebase from "firebase/app";
import "firebase/auth";
import { addSnackbarIssue } from "../../../components/common/StoreCommon";
import firebaseConfig from "../../../firebaseConfig";
import TokenService from "../../../services/TokenService";

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // if already initialized, use that one
}
const firebaseAppAuth = firebase.auth();

export const signInWithEmailAndPassword = (username, password) => {
  firebaseAppAuth
    .signInWithEmailAndPassword(username, password)
    .then((result) => {
      completeSignIn(result.user.email);
    })
    .catch((error) => {
      handleFirebaseLoginError(error);
    });
};

export const signInWithGoogle = () => {
  var provider = new firebase.auth.GoogleAuthProvider()
    .addScope("https://www.googleapis.com/auth/userinfo.email")
    .addScope("https://www.googleapis.com/auth/userinfo.profile");

  firebaseAppAuth
    .signInWithPopup(provider)
    .then((result) => {
      completeSignIn(result.user.email);
    })
    .catch((error) => {
      handleFirebaseLoginError(error);
    });
};

async function completeSignIn(userEmail) {
  const tokenService = new TokenService();

  await tokenService
    .initialiseAuthTokens(userEmail)
    .then(() => {
      window.location.href = "/";
    })
    .catch(() => {
      displayErrorMessage(
        "Login failed - we have been notified and will investigate. Please try again"
      );
    });
}

function handleFirebaseLoginError(error) {
  displayErrorMessage(parseFirebaseErrorForDisplay(error));
}

function parseFirebaseErrorForDisplay(error) {
  if (error.code.includes("password")) return "Incorrect password";
  else if (error.code.includes("user-not-found")) return "Incorrect username";
  else if (error.code.includes("too-many-requests"))
    return "Account disabled for too many failed login attempts. Please try again in 2 minutes";
  else if (error.code.includes("quota-exceeded"))
    return "Too many requests. Please try again in 2 minutes";
  else
    return "Login failed - we have been notified and will investigate. Please try again";
}

function displayErrorMessage(errorMessage) {
  addSnackbarIssue(errorMessage, "warning");
}

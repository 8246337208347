import {
  Avatar,
  Typography,
  Button,
  Box,
  Menu,
  Grid,
  TextField,
  IconButton,
} from "@mui/material";
import { useSelector } from "react-redux";
import {
  getDateInFutureInUtc,
  getStatusIcon,
} from "../../../components/common/Common";
import { getStatuses } from "../../../store/hub/statuses";
import React from "react";
import { makeStyles } from "@material-ui/core";
import { hubUpdateStatus } from "../../../components/common/ConnectionMiddleware";
import { LocalizationProvider, DateTimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { addSnackbarIssue } from "../../../components/common/StoreCommon";
import { Close } from "@mui/icons-material";
import { DatePicker } from "../../../components/date_picker/DatePicker";

export default function StatusSelectModal({ handleClose, anchor }) {
  const availableStatuses = useSelector(getStatuses)();
  const [showAvailabilityMenu, setShowAvailabilityMenu] = React.useState(false);
  const [selectedStatus, setSelectedStatus] = React.useState(null);
  const classes = useStyles();

  const updateCurrentStatus = (status) => {
    if (status.AllowReturnDateTimeFl || !status.PluggedInFl) {
      setShowAvailabilityMenu(true);
      setSelectedStatus(status);
    } else
      hubUpdateStatus(status.Code, null).then((res) => {
        addSnackbarIssue("Status changed successfully", "success");
        handleMenuClose(true);
      });
  };

  const handleMenuClose = () => {
    setShowAvailabilityMenu(false);
    setSelectedStatus(null);
    handleClose();
  };

  return (
    <Menu
      anchorEl={anchor}
      keepMounted
      open={Boolean(anchor)}
      onClose={handleMenuClose}
      style={{ marginLeft: "7%" }}
    >
      <Box style={{ maxWidth: 300, padding: "0px 10px", position: "relative" }}>
        <IconButton
          style={{ position: "absolute", top: -5, right: 5 }}
          onClick={handleClose}
        >
          <Close />
        </IconButton>

        {!showAvailabilityMenu &&
          statusSelectMenu(availableStatuses, updateCurrentStatus, classes)}

        {showAvailabilityMenu && (
          <AvailabilityMenu
            status={selectedStatus}
            classes={classes}
            handleMenuClose={handleMenuClose}
          />
        )}
      </Box>
    </Menu>
  );
}

const AvailabilityMenu = ({ status, classes, handleMenuClose }) => {
  const [value, setValue] = React.useState(new Date());
  console.log(value);
  return (
    <Box style={{ width: "100%" }}>
      <Box className={classes.menuHeader}>
        <Avatar
          style={{
            background: "transparent",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          {getStatusIcon(status.Icon)}
        </Avatar>
        <Typography variant="h6">{status.DisplayName}</Typography>
      </Box>
      <Box style={{ textAlign: "center", width: "100%" }}>
        <Typography variant="body1">I will return in:</Typography>
        <Button
          onClick={() => {
            hubUpdateStatus(status.Code, null).then((res) => {
              addSnackbarIssue("Status changed successfully", "success");
            });
            handleMenuClose(true);
          }}
          style={{ width: "100%", background: "whitesmoke", margin: "5px 0px" }}
        >
          Unknown
        </Button>
        <Button
          onClick={() => {
            hubUpdateStatus(status.Code, getDateInFutureInUtc(30)).then(
              (res) => {
                addSnackbarIssue("Status changed successfully", "success");
              }
            );
            handleMenuClose(true);
          }}
          style={{ width: "100%", background: "whitesmoke", margin: "5px 0px" }}
        >
          30 minutes
        </Button>
        <Button
          onClick={() => {
            hubUpdateStatus(status.Code, getDateInFutureInUtc(60)).then(
              (res) => {
                addSnackbarIssue("Status changed successfully", "success");
              }
            );
            handleMenuClose(true);
          }}
          style={{ width: "100%", background: "whitesmoke", margin: "5px 0px" }}
        >
          1 hour
        </Button>

        <Box style={{ margin: "10px 5px" }}>
          <Typography variant="body1">I will return at:</Typography>
          <DatePicker date={value} setDate={setValue} />
        </Box>
        <Button
          onClick={() => {
            console.log(value);
            hubUpdateStatus(status.Code, value).then((res) => {
              addSnackbarIssue("Status changed successfully", "success");
            });
            handleMenuClose(true);
          }}
          style={{ width: "100%", background: "whitesmoke", margin: "5px 0px" }}
        >
          OK
        </Button>
      </Box>
    </Box>
  );
};

const statusSelectMenu = (availableStatuses, updateCurrentStatus, classes) => {
  return (
    <>
      <Box className={classes.menuHeader}>
        <Typography variant="h6">Update Status</Typography>
      </Box>
      <Grid container spacing={1}>
        {availableStatuses.map((status) => {
          return (
            <Grid
              item
              xs={4}
              key={"statusSelectModal-" + status.Code}
              style={{ display: "inline-flex" }}
            >
              {statusButton(status, updateCurrentStatus)}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

const statusButton = (status, updateCurrentStatus) => {
  return (
    <Button
      style={{
        display: "flex",
        flexDirection: "column",
        marginLeft: "auto",
        marginRight: "auto",
      }}
      onClick={() => updateCurrentStatus(status)}
    >
      <Avatar style={{ background: "transparent" }}>
        {getStatusIcon(status.Icon)}
      </Avatar>
      <Typography variant="caption" style={{ color: "grey" }}>
        {status.DisplayName}
      </Typography>
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  menuHeader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "0 15px",
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  dateTimeInput: {
    padding: 5,
  },
}));

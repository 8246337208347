import React from "react";

export const useDatePicker = () => {
  const [showTime, setShowTime] = React.useState(false);
  const [showDate, setShowDate] = React.useState(true);

  return {
    showTime,
    setShowTime,
    showDate,
    setShowDate,
  };
};

export default class DateTimeHelper 
{    

    convertUnixTimeStampToDateTime(unix_timestamp) {
        
        // Create a new JavaScript Date object based on the timestamp
        // multiplied by 1000 so that the argument is in milliseconds, not seconds.
        return new Date(unix_timestamp * 1000);

    }

    nowAsDateTime() {
        return new Date(Date.now());
    }

    nowAsUtcDateTime() {
        const utcString = new Date().toISOString();
        return this.convertUtcStringToUtcDateTime(utcString);
    }

    isUtcDateTimeLessThanNow(utcDateTime) {
        return utcDateTime < this.nowAsUtcDateTime();
    }

    convertUtcStringToUtcDateTime(utcString) {
        // https://stackoverflow.com/questions/40768606/i-have-a-utc-string-and-i-want-to-convert-it-to-utc-date-object-in-javascript
        if (utcString.endsWith("Z")) return new Date(utcString);
        return new Date(utcString + "Z");
    }
}